import React from 'react';
import { Tooltip, Button } from 'antd';
import { A } from 'hookrouter';

/**
 * Returns an integer in (-1, 0, 1) indicating the lexicographically
 * smaller string of a and b.
 * @param {string} a the first string
 * @param {string} b the second string
 * @returns {boolean}
 */
const compareString = (a, b) => {
  return a > b ? 1 : a < b ? -1 : 0;
};

/**
 * Gets column config for the shared config table
 * @param {object} props
 * @param {string} props.projectID the project id
 * @returns {object}
 */
export const getTableColumns = ({ projectID }) => [
  {
    title: 'Alias',
    dataIndex: 'alias',
    render: (value, record) => (
      <A href={'/' + projectID + '/sharedconfig/' + record.uuid}>{value}</A>
    ),
    sorter: (a, b) => compareString(a.alias, b.alias),
  },
  {
    title: 'Shared Config Type',
    dataIndex: 'uuid',
    render: (uuid, { shared_config_type }) => {
      let typeAlias = uuid;
      let toolTipMessage = 'No Description Available';

      if (shared_config_type) {
        typeAlias = shared_config_type.alias;
        toolTipMessage = shared_config_type.description;
      }

      return (
        <Tooltip placement="right" title={toolTipMessage}>
          <Button htmlType="button" type="dashed">
            {typeAlias}
          </Button>
        </Tooltip>
      );
    },
    sorter: (a, b) => {
      if (a.shared_config_type && b.shared_config_type) {
        return `${a.shared_config_type.alias}`.localeCompare(
          b.shared_config_type.alias,
        );
      }
      return `${a.uuid}`.localeCompare(b.uuid);
    },
  },
  {
    title: 'Description',
    dataIndex: 'description',
    render: (uuid, record) => {
      return <div>{record.shared_config_type.description}</div>;
    },
    responsive: ['lg'],
  },
  {
    title: 'Last Updated',
    dataIndex: 'updated',
    render: (value) => new Date(value).toLocaleString(),
    sorter: (a, b) => new Date(a.updated) - new Date(b.updated),
  },
];
