import { CardActions, CardContent, CardMedia, Typography, Button, Divider, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import store from '../../../store.js'

import { setUsername, setPassword, toggleForgotPassword, authenticate } from './loginSlice'

import loginStyles from './loginStyles';

import textFieldController from '../../controllers/textFieldController.js'
import { useState } from 'react';
import toast from '../../util/toasts.js';

export default function LoginComponent() {
  const useStyles = makeStyles(loginStyles);
  const classes = useStyles();
	const [processing, setProcessing] = useState(false);

  const toggle = () => {
  	store.dispatch(toggleForgotPassword())
  }

  const submit = async () => {
		if (processing) return;
		
		setProcessing(true);
  	
		const { error, payload } = await store.dispatch(authenticate());
		if (error) {
			toast.error(payload.error.message);
		}

		setProcessing(false);
  }

	const handleSubmit = (event) => {
		event.preventDefault();
		submit();
	};

  const [usernameInput, handleUsernameChange] = textFieldController(setUsername)
  const [passwordInput, handlePasswordChange] = textFieldController(setPassword)

  return (
		<CardContent>
			<form action="" method="POST" onSubmit={handleSubmit}>
				<TextField id="username" className={classes.fieldStyle} label="Username" variant="outlined" onChange={handleUsernameChange} required />
				<TextField id="password" className={classes.fieldStyle} type='password' label="Password" variant="outlined" onChange={handlePasswordChange} required />
				<Button type="submit" className={classes.loginButtonStyle} size="large" variant="contained" color="primary" disabled={processing}>
				  Login
				</Button>
				<Divider className={classes.dividerStyle} variant="middle" />
				<Button type="button" className={classes.forgotButtonStyle} size="large" variant="outlined" onClick={() => {window.location='https://auth.nomnomdata.com/reset-password'}}>
			  	Forgot Password
				</Button>
				<Button type="button" className={classes.signupButtonStyle} size="large" variant="outlined" color="secondary" onClick={() => {window.location='https://auth.nomnomdata.com/sign-up'}}>
					Sign Up
				</Button>
			</form>
		</CardContent>
  );
}