import { A } from 'hookrouter';
import { Table, Tag, Tooltip, Button } from 'antd';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { formatDistance } from 'date-fns';
import {
  selectSequenceExecutions,
  killSequence,
  fetchSequenceTaskExecutions,
  selectSequenceTaskExecutions,
} from './sequencesSlice';
import { SettingOutlined, StopOutlined } from '@ant-design/icons';
import store from '../../../store';
import { useEffect, useState } from 'react';
import { selectProjectUuid } from '../Project/projectSlice';

export default function SequenceExecutionsTable() {
  const projectID = useSelector(selectProjectUuid);
  const {
    loading,
    killing,
    data: executions,
  } = useSelector(selectSequenceExecutions);
  const sequenceTaskExecutions = useSelector(selectSequenceTaskExecutions);
  const [expandedExecutions, setExpandedExecutions] = useState([]);

  const columns = getColumns({ killing });

  const renderExpandedRow = (record) => {
    const columns = [
      {
        title: 'Status',
        dataIndex: ['status', 'execution_state'],
        key: 'status',
        render: (status) => {
          switch (status) {
            case 'Executing':
              return <Tag color="gold">{status}</Tag>;
            case 'Completed':
              return <Tag color="cyan">{status}</Tag>;
            case 'Killed':
            case 'Failure':
              return <Tag color="red">{status}</Tag>;
            default:
              return <Tag>{status}</Tag>;
          }
        },
      },
      {
        title: 'Task',
        dataIndex: ['task', 'alias'],
        key: 'alias',
        render: (taskName, innerRecord) => (
          <A href={`/${projectID}/executions/${innerRecord.uuid}`}>{taskName}</A>
        ),
      },
      {
        key: 'created',
        dataIndex: 'created',
        title: 'Started',
        render: (created) =>
          created
            ? `${moment.utc(created).toDate().toUTCString()} (${moment
                .utc(created)
                .fromNow()})`
            : '',
      },
      {
        title: 'Last Status Update',
        dataIndex: 'updated',
        render: (updated) => (updated ? moment.utc(updated).fromNow() : ''),
      },
      {
        key: 'timetook',
        dataIndex: 'created',
        title: 'Total Execution Time',
        render: (created, innerRecord) => {
          const updatedDate = new Date(innerRecord.updated);
          const createdDate = new Date(created);
          return formatDistance(updatedDate, createdDate);
        },
      },
    ];

    return (
      <Table
        bordered
        size="small"
        rowKey="uuid"
        columns={columns}
        pagination={false}
        loading={sequenceTaskExecutions[record.uuid]?.loading}
        dataSource={sequenceTaskExecutions[record.uuid]?.data || []}
      />
    );
  };

  useEffect(() => {
    const newExpanded = expandedExecutions.filter((uuid) =>
      executions.find((ex) => ex.uuid === uuid),
    );
    setExpandedExecutions(newExpanded);
    newExpanded.forEach((uuid) =>
      store.dispatch(
        fetchSequenceTaskExecutions({
          sequenceExecutionID: uuid,
          filters: { created_start: 0 },
        }),
      ),
    );
  }, [executions]);

  return (
    <Table
      rowKey="uuid"
      columns={columns}
      dataSource={executions}
      expandedRowRender={renderExpandedRow}
      onExpand={(expanded, record) => {
        if (expanded) {
          setExpandedExecutions([...expandedExecutions, record.uuid]);
          store.dispatch(
            fetchSequenceTaskExecutions({
              sequenceExecutionID: record.uuid,
              filters: {
                created_start: 0,
              },
            }),
          );
        } else {
          setExpandedExecutions(
            expandedExecutions.filter((uuid) => uuid !== record.uuid),
          );
        }
      }}
      pagination={{
        showSizeChanger: true,
        position: 'both',
        size: 'middle',
      }}
      loading={loading}
    />
  );
}

function getColumns({ killing }) {
  return [
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => {
        switch (status) {
          case 'running':
            return (
              <Tag color="cyan">
                <SettingOutlined spin />
                &nbsp;Running
              </Tag>
            );
          case 'complete':
            return <Tag color="green">Complete</Tag>;
          case 'Killed':
          case 'Error':
            return <Tag color="red">{status}</Tag>;
          default:
            return <Tag>{status}</Tag>;
        }
      },
    },
    {
      key: 'created',
      dataIndex: 'created',
      title: 'Started',
      render: (created) =>
        created
          ? `${moment.utc(created).toDate().toUTCString()} (${moment
              .utc(created)
              .fromNow()})`
          : '',
    },
    {
      title: 'Last Status Update',
      dataIndex: 'updated',
      render: (updated) => (updated ? moment.utc(updated).fromNow() : ''),
    },
    {
      key: 'timetook',
      dataIndex: 'created',
      title: 'Total Execution Time',
      render: (created, innerRecord) => {
        const updatedDate = new Date(innerRecord.updated);
        const createdDate = new Date(created);
        return formatDistance(updatedDate, createdDate);
      },
    },
    {
      key: 'haltSequence',
      align: 'center',
      dataIndex: 'status',
      title: 'Halt Sequence',
      render: (status, record) => {
        if (status === 'running') {
          return (
            <Tooltip title="Stop any further task executions">
              <Button
                htmlType="button"
                type="danger"
                shape="circle"
                icon={<StopOutlined />}
                loading={killing}
                onClick={() => {
                  store.dispatch(killSequence(record.uuid));
                }}
              />
            </Tooltip>
          );
        }
        return '';
      },
    },
  ];
}
