import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Form, Select, Card, Row, Col, Space, Button } from 'antd';
import { red } from '@ant-design/colors';

import store from '../../../../../store';
import FieldFactory from '../../../SharedField/FieldFactory';
import {
  createNewManyRow,
  deleteManyRow,
  changeParameter,
} from '../../sharedConfigSlice';
import { generateFieldRules } from "../../../../util/forms"

export default function ManySection(props) {
  const { locked, section } = props;

  const parameterFields = [];
  const manyParameters = useSelector(
    (state) => state.sharedConfig.manyParameters[section.name],
  );
  let stateParametersArray = useSelector(
    (state) => state.sharedConfig.newParameters[section.name],
  );

  if (!stateParametersArray) {
    stateParametersArray = [];
  }

  const addNewRow = () => {
    store.dispatch(createNewManyRow(section.name));
  };

  const deleteRow = (index) => {
    store.dispatch(deleteManyRow({ name: section.name, index }));
  };

  const rows = useMemo(() => {
    let index = 0;
    stateParametersArray.forEach((stateParameters) => {
      Object.entries(stateParameters).forEach(([key, value]) => {
        const definition = manyParameters.find((manyParameter) => {
          return manyParameter.name == key;
        });

        if (!definition) {
          parameterFields.push(null);
          return;
        }

        const { type, choices, name, display_name, required, description } =
          definition;

        let _index = index;

        const onChangeParameter = (value) => {
          store.dispatch(
            changeParameter({
              many: true,
              section: section.name,
              index: _index,
              parameter: name,
              value: value,
            }),
          );
        };

        const onChangeOption = () => {};

        const rules = generateFieldRules(definition);

        const wrapElement = (element) => (
          <Form.Item
            label={<strong>{display_name}</strong>}
            rules={rules}
            initialValue={value}
            required={required}
            {...(description && { help: description })}
          >
            {element}
          </Form.Item>
        );

        if (type === 'enum' || type === 'load_pattern') {
          const options = choices.map((choice) => (
            <Select.Option key={choice} value={choice}>
              {choice}
            </Select.Option>
          ));
          parameterFields.push(
            wrapElement(
              <Select
                value={value}
                allowClear
                disabled={locked}
                onChange={onChangeParameter}
              >
                {options}
              </Select>,
            ),
          );
        } else if (type === 'int' || type === 'string') {
          parameterFields.push(
            wrapElement(
              FieldFactory.create(type, {
                value,
                options: {},
                onChange: onChangeParameter,
                onOptionsChange: onChangeOption,
                disabled: locked,
              }),
            ),
          );
        }
      });

      index += 1;
    });

    const rows = [];
    const numRows = index;
    if (numRows > 0) {
      const numCols = Object.keys(stateParametersArray[0]).length;
      const fieldSpan = (24 / numCols) | 0;
      let row, idx;

      for (let i = 0; i < numRows; i++) {
        row = [];
        for (let j = 0; j < numCols; j++) {
          idx = j + i * numCols;
          row.push(
            <Col key={idx} xs={24} md={(fieldSpan * 1.8) | 0} xl={fieldSpan}>
              {parameterFields[idx]}
            </Col>,
          );
        }
        rows.push(<Row gutter={[30, 20]}>{row}</Row>);
      }
    }

    return rows;
  }, [stateParametersArray, locked]);

  return (
    <Card title={section.display_name} type="inner">
      <Space size="large" direction="vertical">
        {rows.map((row, idx) => (
          <Row key={idx} gutter={10}>
            <Col xs={21}>{row}</Col>
            <Col xs={3}>
              <Button
                style={{
                  backgroundColor: locked ? undefined : red.primary,
                  color: locked ? undefined : '#fff',
                }}
                disabled={locked}
                onClick={() => deleteRow(idx)}
              >
                Delete
              </Button>
            </Col>
          </Row>
        ))}
        <div>
          <Button type="primary" onClick={addNewRow} disabled={locked}>
            Add
          </Button>
        </div>
      </Space>
    </Card>
  );
}
