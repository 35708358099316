import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, Input, Space, Table, Tag } from 'antd';

import { A } from 'hookrouter';
import { capitalize } from 'lodash';

import { compareStrings } from '../../util/strings';
import { selectProjectUuid } from '../Project/projectSlice';

const { Search } = Input;

export default function SharedFieldList() {
  const sharedFields = useSelector((state) => state.sharedField.sharedFields);
  const sharedFieldsLoaded = useSelector((state) => state.sharedField.loaded);
  const [filteredData, setFilteredData] = useState(sharedFields);
  const [query, setQuery] = useState('');
  const projectUuid = useSelector(selectProjectUuid);

  const columns = [
    {
      title: 'Alias',
      dataIndex: 'alias',
      filtered: true,
      filteredValue: 'i',
      render: (value, record) => (
        <A href={'/' + projectUuid + '/sharedfield/' + record.uuid}>
          {value || '---'}
        </A>
      ),
      sorter: (a, b) => compareStrings(a.alias, b.alias),
    },
    {
      title: 'Field Type',
      dataIndex: 'field_type',
      width: 130,
      render(value) {
        return (
          <Tag
            style={{
              borderStyle: 'dashed',
              padding: '0.4rem 0.6rem',
              backgroundColor: 'transparent',
            }}
          >
            {capitalize(value)}
          </Tag>
        );
      },
      sorter: (a, b) => compareStrings(a.field_type, b.field_type),
    },
    {
      title: 'Last Updated',
      dataIndex: 'updated',
      render: (value) => new Date(value).toLocaleString(),
      sorter: (a, b) => new Date(a.updated) - new Date(b.updated),
    },
  ];

  const searchSharedFields = (query) => {
    query = query.toLowerCase();
    if (!query) setFilteredData(sharedFields);
    else
      setFilteredData(
        sharedFields.filter((data) => {
          return data.alias.toLowerCase().includes(query);
        }),
      );
  };

  useEffect(() => {
    setFilteredData(sharedFields);
  }, [sharedFields]);

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Card size="small">
        <Search
          allowClear
          style={{ maxWidth: 300 }}
          placeholder="Search by shared field name"
          onChange={({ target }) => searchSharedFields(target.value)}
          onSearch={(value) => searchSharedFields(value)}
        />
      </Card>

      <Card size="small">
        <Table
          expandable
          rowKey="uuid"
          columns={columns}
          dataSource={filteredData}
          pagination={{
            pageSize: 50,
            position: 'both',
            size: 'middle',
          }}
          loading={!sharedFieldsLoaded}
        />
      </Card>
    </Space>
  );
}
