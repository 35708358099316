import { CardActions, CardContent, CardMedia, Typography, Button, Divider, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { useState } from 'react';

import store from '../../../store.js'

import { toggleForgotPassword } from './loginSlice'


import loginStyles from './loginStyles';

export default function ForgotPasswordComponent() {
	const useStyles = makeStyles(loginStyles);
	const classes = useStyles();

	const toggle = () => {
  		store.dispatch(toggleForgotPassword())
  	}

		const handleSubmit = (event) => {
			event.preventDefault();
			toggle();
		};

	return (
		<CardContent>
			<form action="" method="POST" onSubmit={handleSubmit}>
				<Typography className={classes.title} color="textPrimary" gutterBottom>
					Enter Your Email to Recover Password
				</Typography>
				<TextField className={classes.fieldStyle} type='email' label="Email" variant="outlined" value="" />
				<Button type="submit" className={classes.loginButtonStyle} size="large" variant="contained" color="primary">
			  	Send Password Request
				</Button>
				<Button className={classes.backButtonStyle} size="large" variant="contained" onClick={toggle}>
				  Back To Login
				</Button>
			</form>
		</CardContent>
  );
}