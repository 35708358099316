import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { navigate } from 'hookrouter';
import { pick } from 'lodash';
import { makeStyles } from '@material-ui/core';

// Components
import {
  Card,
  Form,
  Input,
  Space,
  Row,
  Col,
  Select,
  Button,
  Dropdown,
  Menu,
} from 'antd';
import { green } from '@ant-design/colors';
import {
  LockTwoTone,
  SaveOutlined,
  UnlockTwoTone,
  MenuOutlined,
  EditOutlined,
} from '@ant-design/icons';

// internal components
import DescriptionItem from '../../Description/DescriptionItem';
import JSONParamEditor from '../../../components/JSONParamEditor';
import Loading from '../../../components/Loading';
import TaskHelp from '../TaskHelp';
import ParametersTab from './tabs/ParametersTab';
import AdvancedSettingsTab from './tabs/AdvancedSettingsTab';

import store from '../../../../store';
import { loadEngines, selectEngines } from '../../Engines/engineSlice';
import {
  selectProjectUuid,
  selectProjectAlias,
} from '../../Project/projectSlice';
import {
  configureNewTaskParameters,
  resetNewTaskParameters,
  selectNewTaskParameters,
  hardSetNewParameters,
  setTaskHelpActionKey,
  selectNewTaskParametersSet,
} from '../shared/tasksSlice';
import {
  loadSharedConfigs,
  selectSharedConfigs,
} from '../../SharedConfig/sharedConfigSlice';
import {
  loadConnections,
  loadConnectionTypes,
} from '../../Connections/connectionsSlice';
import { loadSharedFields } from '../../SharedField/sharedFieldSlice';

import { api } from '../../../api/api';
import toast from '../../../util/toasts';
import logger from '../../../util/logger';
import styles from './styles';

const { useForm } = Form;
const { Group: BtnGroup } = Button;

const useStyles = makeStyles(styles);

const tabList = [
  { key: 'parameters', tab: 'Parameters' },
  { key: 'settings', tab: 'Advanced Settings' },
];

export default function CreateTask(props) {
  const { engineID } = props;
  const classes = useStyles();
  const projectAlias = useSelector(selectProjectAlias);
  const projectUuid = useSelector(selectProjectUuid);
  const engines = useSelector(selectEngines);
  const taskParameters = useSelector(selectNewTaskParameters);
  const taskParametersSet = useSelector(selectNewTaskParametersSet);
  const sharedConfigs = useSelector(selectSharedConfigs);
  const [alias, setAlias] = useState('');
  const [channel, setChannel] = useState('stable');
  const [logLevel, setLogLevel] = useState(null);
  // string
  const [action, setAction] = useState(null);
  const [selectedAction, setSelectedAction] = useState(null);
  const [activeTabKey, setActiveTabKey] = useState('parameters');
  const [locked, setLocked] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [paramEditorOpen, setParamEditorOpen] = useState(false);

  const [form] = useForm();
  const fullParameters = {
    ...taskParameters,
    alias,
    action_name: action,
    ...(logLevel && { log_level: logLevel }),
  };

  const selectedEngine = (engines || []).find(
    (engine) => engine.uuid === engineID,
  );

  let selectedModel, selectedModelActions;
  if (selectedEngine) {
    // We want the latest version, not the first
    selectedModel = selectedEngine.models[selectedEngine.models.length - 1];
    selectedModelActions = Object.entries(selectedModel.actions);
  }

  const toggleTaskFieldsLock = () => {
    setLocked(!locked);
  };

  const onChangeAlias = (event) => {
    const value = event.target.value;
    setAlias(value);
  };

  const onChangeChannel = (value) => {
    setChannel(value);
  };

  const onChangeLogLevel = (value) => {
    setLogLevel(value);
  };

  /**
   * Called when the user selects a new task action
   * @param {string} newAction the new action
   * @returns {void}
   */
  const onChangeAction = (newAction) => {
    if (newAction === action) return;

    const pair = selectedModelActions.find(([key]) => key === newAction);
    if (!pair) return;

    // Grab the object that describes this action
    const act = pair[1];

    // Capture "general parameters": parameters which are not independent panels
    const generalGroup = [];
    const otherParameters = [];
    for (let parameter of act.parameters) {
      if (!parameter.parameters) {
        generalGroup.push(parameter);
      } else {
        otherParameters.push(parameter);
      }
    }

    const newParameters = [...otherParameters];
    if (generalGroup.length) {
      // Create a new paramater for the general group. Hence, a new panel.
      newParameters.unshift({
        display_name: 'General Parameters',
        name: 'general_parameters',
        parameters: generalGroup,
        type: 'group',
      });
    }

    if (act.help) {
      // Set the help key
      store.dispatch(setTaskHelpActionKey(act.help.key));
    }

    setAction(newAction);
    setSelectedAction({
      ...act,
      parameters: newParameters,
    });
  };

  /**
   * Called when the user switches tabs
   * @param {string} key the tab key
   */
  const handleTabChange = (key) => {
    console.log('tab changed');
    setActiveTabKey(key);
  };

  const saveTask = () => {
    setProcessing(true);

    // Pick the parameters for the selected action
    const fields = [];
    selectedAction.parameters.forEach((param) => {
      param.parameters.forEach((p) => {
        fields.push(p.name);
      });
    });

    const payload = {
      alias,
      engine_uuid: selectedModel.uuid,
      options: { channel },
      parameters: {
        ...pick(taskParameters, fields),
        alias,
        action_name: action,
      },
      project_uuid: projectUuid,
    };

    if (logLevel) {
      payload.options['log_level'] = logLevel;
    }

    api.tasks
      .create(projectUuid, payload)
      .then(() => {
        navigate('/' + projectUuid + '/tasks');
      })
      .catch(() => {
        toast.error('Failed to create new task.');
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  const openJSONParamEditor = () => {
    setParamEditorOpen(true);
  };

  const handleParamEditorClose = (data, wasCancelled) => {
    setParamEditorOpen(false);

    if (wasCancelled) return;

    store.dispatch(hardSetNewParameters(data));

    // Update the form data inside of antd. This updates the inputs
    // controlled by the form
    for (let name in data) {
      form.setFieldsValue({ [name]: data[name] });
    }

    setAlias(data.alias);
    // setChannel(data.channel);

    // Call this in case the action was changed in th json editor
    onChangeAction(data.action_name);

    if ('log_level' in fullParameters) {
      setLogLevel(data.log_level);
    }
  };

  useEffect(() => {
    // Load all required data
    store.dispatch(loadEngines());
    store.dispatch(loadSharedConfigs());
    store.dispatch(loadSharedFields());
    store.dispatch(loadConnectionTypes());
    store.dispatch(loadConnections());

    return function cleanup() {
      logger.debug('cleanup');
      store.dispatch(resetNewTaskParameters());
    }
  }, []);

  useEffect(() => {
    // This runs if the user hasn't explicitly chosen an action
    // but the models config has been loaded
    if (!selectedAction && !!selectedModelActions) {
      // set default channel and action
      onChangeChannel(selectedEngine.default_channel);
      onChangeAction(selectedModelActions[0][0]);
    }
  }, [selectedModelActions]);

  useEffect(() => {
    if (selectedAction) {
      // Reconfigure the state whenever the action changes
      store.dispatch(configureNewTaskParameters(selectedAction.parameters));
    }
  }, [selectedAction]);

  if (!taskParametersSet || !selectedAction) return <Loading />;

  return (
    <div>
      <JSONParamEditor
        open={paramEditorOpen}
        data={fullParameters}
        onClose={handleParamEditorClose}
        disabled={locked}
      />

      <TaskHelp model={selectedModel} />

      <Form
        form={form}
        layout="vertical"
        onFinish={saveTask}
        scrollToFirstError
      >
        <Space direction="vertical" size="middle" style={{ width: '100%' }}>
          <Card>
            <Space direction="vertical" size="middle" style={{ width: '100%' }}>
              <DescriptionItem
                title="Create Task"
                description="Create a task from an engine"
              />

              <div>
                <strong style={{ fontWeight: 500 }}>Current Project:</strong>{' '}
                {projectAlias}
              </div>
            </Space>
          </Card>

          <Card>
            <Card.Grid hoverable={false} className={classes.upperGrid}>
              <Row justify="space-between">
                <Col xs={24} md={12} lg={8}>
                  <Form.Item
                    label={<strong>Alias</strong>}
                    name="alias"
                    rules={[
                      {
                        required: true,
                        message: 'Task Name is a required field',
                      },
                    ]}
                    labelAlign="left"
                  >
                    <Input
                      size="middle"
                      onChange={onChangeAlias}
                      className={classes.upperGridInput}
                      disabled={locked}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={8}>
                  <Form.Item
                    label={<strong>Channel</strong>}
                    name="global_channel"
                    rules={[{ required: true }]}
                    initialValue={selectedEngine.default_channel}
                    required
                    labelAlign="left"
                    disabled={locked}
                  >
                    <Select
                      className={classes.upperGridInput}
                      onChange={onChangeChannel}
                    >
                      <Select.Option value="stable">
                        Stable <i>(Default)</i>
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Card.Grid>
            <Card.Grid hoverable={false} className={classes.upperGrid}>
              <Space direction="vertical">
                <Row>
                  <Col style={{ marginRight: '18px' }}>
                    <img
                      src={
                        selectedModel.icons['1x']?.url
                          ? `${selectedModel.icons['1x'].url}`
                          : './static/favicon/apple-touch-icon.png'
                      }
                      style={{
                        width: '50px',
                        height: '50px',
                        objectFit: 'contain',
                      }}
                    />
                  </Col>
                  <Col>
                    <h5 className={classes.modelAlias}>
                      {selectedModel.alias}
                    </h5>
                    <p className={classes.modelDesc}>
                      {selectedModel.description}
                    </p>
                  </Col>
                </Row>

                <Form.Item
                  label={<strong>Action</strong>}
                  name="action_name"
                  rules={[{ required: true }]}
                  initialValue={selectedModelActions[0][0]}
                  labelAlign="right"
                  help={selectedAction?.description}
                  required
                >
                  <Select
                    className={classes.actionSelect}
                    onChange={onChangeAction}
                    disabled={locked}
                  >
                    {selectedModelActions.map(([key, action]) => (
                      <Select.Option key={key} value={key}>
                        {action.display_name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Space>
            </Card.Grid>
          </Card>

          <Card
            size="small"
            tabList={tabList}
            activeTabKey={activeTabKey}
            onTabChange={handleTabChange}
            tabBarExtraContent={
              <BtnGroup>
                <Button
                  icon={
                    locked ? (
                      <UnlockTwoTone twoToneColor={green.primary} />
                    ) : (
                      <LockTwoTone twoToneColor={green.primary} />
                    )
                  }
                  onClick={toggleTaskFieldsLock}
                >
                  {locked ? 'Unlock' : 'Lock'}
                </Button>
                <Button
                  htmlType="submit"
                  style={{
                    backgroundColor: locked ? undefined : green[6],
                    color: locked ? undefined : '#fff',
                  }}
                  disabled={locked}
                  icon={<SaveOutlined />}
                  loading={processing}
                >
                  Create
                </Button>
                <Dropdown
                  trigger={['click', 'hover']}
                  overlay={
                    <Menu>
                      <Menu.Item
                        key="edit"
                        icon={<EditOutlined />}
                        onClick={() => openJSONParamEditor()}
                      >
                        Edit
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <Button>
                    <MenuOutlined />
                  </Button>
                </Dropdown>
              </BtnGroup>
            }
          >
            {activeTabKey === 'parameters' ? (
              <ParametersTab
                parameters={selectedAction.parameters}
                state={taskParameters}
                sharedConfigs={sharedConfigs}
                locked={locked}
                form={form}
              />
            ) : activeTabKey === 'settings' ? (
              <AdvancedSettingsTab
                logLevel={logLevel}
                onLogLevelChange={onChangeLogLevel}
                locked={locked}
              />
            ) : null}
          </Card>
        </Space>
      </Form>
    </div>
  );
}
