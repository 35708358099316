import {
  createSlice,
  createAsyncThunk,
	createDraftSafeSelector,
} from '@reduxjs/toolkit'
import { DEFAULT_PROJECT_UUID } from '../../../constants'

import { api } from '../../api/api'

export const loadProjects = createAsyncThunk('project/loadProjects', async () => {
  const response = await api.projects()
  return response
})

export const loadUserPermissions = createAsyncThunk('project/loadUserPermissions', async () => {
	const response = await api.userPermissions()
	return response
  })

// selectors
const selectSelf = (state) => state.project;
export const selectProjects = createDraftSafeSelector(selectSelf, (project) => project.projects);
export const selectProjectPermissionRoles = createDraftSafeSelector(selectSelf, (project) => project.permissionRoles);
export const selectProjectAlias = createDraftSafeSelector(selectSelf, (project) => project.projectAlias);
export const selectProjectUuid = createDraftSafeSelector(selectSelf, (project) => project.projectUuid);
export const selectProject = createDraftSafeSelector(selectSelf, (project) => {
	return project.projects.find(proj => proj.uuid === project.projectUuid) || null;
});
export const selectProjectsLoading = createDraftSafeSelector(selectSelf, (project) => project.loading);

const projectSlice = createSlice({
	name: 'project',
	initialState: {
		projectUuid: '', 
		projectAlias: '', 
		permissionRoles: [], 
		projects: [],
		loading: false,
	},
	reducers: {
		changeProject(state, action) {
			state.projectUuid = action.payload
			state.projects.forEach(project => {
				if(action.payload==project.uuid) {
					state.projectAlias = project.alias
				}
			})
		},
	},
	extraReducers: {
		    [loadProjects.pending]: (state, action) => {
					state.loading = true;
					state.projects = [];
				},
				[loadProjects.fulfilled]: (state, action) => {
					state.loading = false;
		    	state.projects = action.payload.results
					if (state.projectUuid) {
						// update the project alias
						const project = state.projects.find(({ uuid }) => uuid === state.projectUuid);
						state.projectAlias = project ? project.alias : '';
					}
		    },
				[loadProjects.rejected]: (state, action) => {
					state.loading = false;
		    },
			[loadUserPermissions.fulfilled]: (state, action) => {
				action.payload.groups.forEach(group => {
					group.roles.forEach(role => {
						state.permissionRoles.push(role)
					})
				})
		    },
	},
})

export const { changeProject } = projectSlice.actions

export default projectSlice.reducer
