import { useSelector } from 'react-redux';
import { Form, Select, Card, Row, Col } from 'antd';

import store from '../../../../../store';
import FieldFactory from '../../../SharedField/FieldFactory';
import { generateFieldRules } from '../../../../util/forms';
import { selectSharedConfigs, changeParameter } from '../../sharedConfigSlice';

import ManySection from './ManySection';

const selectStateParameters = (state) => state.sharedConfig.newParameters;

export default function ParameterSection(props) {
  const { section, locked } = props;
  const { parameters } = section;

  const stateParameters = useSelector(selectStateParameters);
  const sharedConfigs = useSelector(selectSharedConfigs);

  const parameterFields = [];

  parameters.forEach((parameter) => {
    const { name, type, display_name, description, required } = parameter;
    const value = stateParameters[name];

    // event handlers
    const onChangeParameter = (value) => {
      store.dispatch(changeParameter({ parameter: name, value }));
    };
    const onChangeOption = () => {};

    const rules = generateFieldRules(parameter);

    const wrapElement = (element, initialValue = value) => (
      <Form.Item
        label={<strong>{display_name}</strong>}
        name={name}
        rules={rules}
        initialValue={initialValue}
        required={required}
        {...(description && { help: description })}
      >
        {element}
      </Form.Item>
    );

    if (parameter.many || type === 'nested') {
      parameterFields.push(<ManySection section={parameter} locked={locked} />);
    } else if (type == 'enum' || name == 'load_pattern') {
      const options = parameter.choices.map((choice) => (
        <Select.Option key={choice} value={choice}>
          {choice}
        </Select.Option>
      ));
      parameterFields.push(
        wrapElement(
          <Select
            allowClear
            value={value}
            disabled={locked}
            onChange={onChangeParameter}
          >
            {options}
          </Select>,
        ),
      );
    } else if (type === 'shared-config' || type === 'shared_config') {
      const options = sharedConfigs.map((d) => (
        <Select.Option key={d.uuid} value={d.uuid}>
          {d.alias}
        </Select.Option>
      ));

      const onChange = (value) => {
        onChangeParameter({
          shared_config_uuid: value,
        });
      };

      parameterFields.push(
        wrapElement(
          <Select
            allowClear
            value={value ? value.shared_config_uuid : value}
            disabled={locked}
            onChange={onChange}
            notFoundContent="No shared configs found"
          >
            {options}
          </Select>,
          value ? value.shared_config_uuid : value,
        ),
      );
    } else {
      parameterFields.push(
        wrapElement(
          FieldFactory.create(type, {
            value,
            options: {},
            onChange: onChangeParameter,
            onOptionsChange: onChangeOption,
            disabled: locked,
          }),
        ),
      );
    }
  });

  return (
    <Card title={section.display_name} type="inner" style={{ width: '100%' }}>
      <Row gutter={[30, 20]}>
        {parameterFields.map((field, idx) => {
          const param = parameters[idx];
          const md =
            FieldFactory.hasMetaProperty(param.type, 'codeElement') ||
            param.type === 'nested' ||
            param.many
              ? 24
              : 12;
          return (
            <Col key={idx} xs={24} md={md}>
              {field}
            </Col>
          );
        })}
      </Row>
    </Card>
  );
}
