const infoStyle = `color: blue`;
const debugStyle = ``;
const errorStyle = `color: red`;
const warnStyle = `color: yellow`;

export class Logger {
  isDebug;

  /**
   * Initializes the logger instance
   * @param {boolean} isDebug
   */
  constructor(isDebug) {
    this.isDebug = isDebug;
  }

  /**
   * Logs to the console.
   * @param {any} message - passed directly to console.info
   */
  info(message) {
    console.info('%c%s', infoStyle, message);
  }

  /**
   * Logs to the console. Does not apply any formatting
   * @param {any} message - passed directly to console.log
   */
  debugRaw = (object) => {
    if (this.isDebug) {
      console.log(object);
    }
  };

  /**
   * Logs to the console.
   * @param {any} message - passed directly to console.log
   */
  debug = (message) => {
    if (this.isDebug) {
      console.log('%c%s', debugStyle, message);
    }
  };

  /**
   * Logs to the console.
   * @param {any} message - passed directly to console.error
   */
  error(message) {
    console.error('%c%s', errorStyle, message);
  }

  /**
   * Logs to the console.
   * @param {any} message - passed directly to console.warn
   */
  warn(message) {
    console.warn('%c%s', warnStyle, message);
  }
}

const logger = new Logger(
  process.env.NODE_ENV === 'development' ? true : false,
);

export default logger;
