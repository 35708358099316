import { useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import {
  Input,
  Space,
  Form,
  Row,
  Col,
  Button,
  Switch,
  Select,
  Radio,
  Typography,
} from 'antd';

import TaskArray from './TaskArray';
// this is lazy loaded
// import TaskGraph from './TaskGraph/TaskGraph';

import store from '../../../store';

import { selectSequences, selectAllSequences } from './sequencesSlice';
import { selectTasks, selectTasksLoading } from '../Tasks/shared/tasksSlice';
import { selectProjectUuid } from '../Project/projectSlice.js';
import checkIfGraph from './shared/checkIfGraph';

const { Text } = Typography;
const { Button: RadioButton, Group: RadioGroup } = Radio;

const MOBILE_WIDTH = 950;

const TaskGraph = (props) => {
  const Graph = require('./TaskGraph/TaskGraph').default;
  return <Graph {...props} />;
};

export default function SequenceForm({
  sequenceID,
  selectSequenceData,
  updateSequenceData,
  isGraph,
  onSubmit,
}) {
  const project_uuid = useSelector(selectProjectUuid);
  const sequences = useSelector(selectSequences);
  const allSequences = useSelector(selectAllSequences);
  const tasks = useSelector(selectTasks);
  const tasksLoading = useSelector(selectTasksLoading);
  const {
    alias,
    schedule,
    triggerSequence,
    triggerType,
    enabled: switchEnabled,
    tasks: sequenceTasks,
    taskGraph: sequenceTaskGraph,
  } = useSelector(selectSequenceData);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= MOBILE_WIDTH);
  const [submitting, setSubmitting] = useState(false);

  const projectTasks = useMemo(() => {
    return tasks.filter((task) => task.project_uuid === project_uuid);
  }, [project_uuid, tasks]);

  const sequenceOptions = useMemo(() => {
    const options = [];
    console.log(allSequences)
    allSequences.forEach(({ uuid, alias, project_uuid }) => {
      
      if (uuid !== sequenceID) {
        options.push(
          <Select.Option key={uuid} value={uuid}>
            {alias} - [{project_uuid}]
          </Select.Option>,
        );
      }
    });
    return options;
  }, [allSequences]);

  useEffect(() => {
    const checkSize = () => {
      setIsMobile(window.innerWidth <= MOBILE_WIDTH);
    };

    window.addEventListener('resize', checkSize);
    return function cleanup() {
      window.removeEventListener('resize', checkSize);
    };
  }, []);

  const changeEnabled = (checked) => {
    store.dispatch(updateSequenceData({ enabled: checked }));
  };

  const changeSchedule = (e) => {
    store.dispatch(updateSequenceData({ schedule: e.target.value }));
  };

  const handleTriggerTypeChange = (e) => {
    store.dispatch(updateSequenceData({ triggerType: e.target.value }));
  };

  const changeAlias = (e) => {
    store.dispatch(updateSequenceData({ alias: e.target.value }));
  };

  const changeTriggerSequence = (sequenceUUID) => {
    store.dispatch(updateSequenceData({ triggerSequence: sequenceUUID }));
  };

  const handleSubmit = () => {
    setSubmitting(true);
    onSubmit().finally(() => {
      setSubmitting(false);
    });
  };

  return (
    <Form
      scrollToFirstError
      autoComplete="off"
      layout="horizontal"
      onFinish={handleSubmit}
    >
      <Form.Item
        label={<strong>Name</strong>}
        name="alias"
        initialValue={alias}
        rules={[{ required: true, message: 'Name is a required field.' }]}
        required
      >
        <Input onChange={changeAlias} style={{ maxWidth: 400 }} />
      </Form.Item>

      <Form.Item
        label={<strong>Enabled</strong>}
        name="enabled"
        initialValue={switchEnabled}
        rules={[{ required: true }]}
        required
      >
        <Switch checked={switchEnabled} onChange={changeEnabled} />
      </Form.Item>

      <Form.Item label={<strong>Trigger Type</strong>} name="trigger" required>
        <Row gutter={[10, 20]}>
          <Col>
            <RadioGroup
              name="triggerType"
              value={triggerType}
              defaultValue="cron"
              buttonStyle="solid"
              onChange={handleTriggerTypeChange}
            >
              <RadioButton value="cron">Cron</RadioButton>
              <RadioButton value="sequence">Sequence</RadioButton>
            </RadioGroup>
          </Col>
          <Col sm={24} md={12}>
            {triggerType === 'cron' ? (
              <Form.Item
                name="schedule"
                extra="Trigger a sequence on a cron schedule"
                initialValue={schedule}
                rules={[
                  {
                    required: true,
                    message: 'Schedule is a required field.',
                  },
                ]}
                required
              >
                <Input
                  placeholder="* * * * *"
                  value={schedule}
                  onChange={changeSchedule}
                />
              </Form.Item>
            ) : (
              <Form.Item
                name="sequence"
                extra="Trigger sequence following the completion of another sequence"
                initialValue={triggerSequence}
                rules={[
                  {
                    required: true,
                    message: 'Source sequence is a required field.',
                  },
                ]}
                required
              >
                <Select
                  showSearch
                  value={triggerSequence}
                  onChange={changeTriggerSequence}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {sequenceOptions}
                </Select>
              </Form.Item>
            )}
          </Col>
        </Row>
      </Form.Item>

      <Form.Item
        required
        colon={false}
        labelCol={{ span: 24 }}
        label={
          <Space>
            <strong>Tasks</strong>
            <Text type="secondary">
              Add, remove, and change order of tasks in sequence
            </Text>
          </Space>
        }
      >
        {!isGraph ? (
          <TaskArray
            tasks={projectTasks}
            loadingTasks={tasksLoading}
            values={sequenceTasks}
            updateSequenceData={updateSequenceData}
          />
        ) : (
          <TaskGraph
            tasks={projectTasks}
            loadingTasks={tasksLoading}
            values={sequenceTasks}
            taskGraph={sequenceTaskGraph}
            updateSequenceData={updateSequenceData}
          />
        )}
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" loading={submitting}>
          Save
        </Button>
      </Form.Item>
    </Form>
  );
}
