const SidebarBackground = (props) => {
  const image = '/static/media/sidebar-2.22f68ba5.jpg';

  const classes = props.styleClasses;
  return <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
          />
};

export default SidebarBackground