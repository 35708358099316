import { navigate } from 'hookrouter';
import { Tabs } from 'antd';

const { TabPane } = Tabs;

export default function SequenceTabs({ projectID, sequenceID, tab }) {
  const onChange = (key) => {
    navigate(`/${projectID}/sequences/${key}/${sequenceID}`);
  };

  return (
    <Tabs
      defaultActiveKey="edit"
      activeKey={tab}
      size="large"
      tabBarStyle={{
        margin: 0
      }}
      onChange={onChange}
    >
      <TabPane tab="Edit" key="edit"></TabPane>
      <TabPane tab="Executions" key="executions"></TabPane>
    </Tabs>
  );
}
