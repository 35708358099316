import { makeStyles } from '@material-ui/core';
import { Button, Divider, Drawer, Space, Typography } from 'antd';
import PropTypes from 'prop-types';

const { Paragraph, Text, Title } = Typography;

const useStyles = makeStyles({
  drawer: {
    fontWeight: 'normal',
  },
});

export default function TaskGraphInfo(props) {
  const { open, onClose } = props;
  const classes = useStyles();

  const handleClose = () => {
    onClose();
  };

  return (
    <Drawer
      title="Task Graph Editor"
      placement="right"
      visible={open}
      className={classes.drawer}
      onClose={() => onClose()}
      destroyOnClose
      footerStyle={{ textAlign: 'right' }}
      footer={
        <Space>
          <Button type="primary" onClick={handleClose}>
            Close
          </Button>
        </Space>
      }
    >
      <Paragraph>
        The graph editor gives you total control over the execution of your
        tasks.
      </Paragraph>
      <Paragraph>
        <Title level={5}>Adding tasks</Title>
        <Text>
          Select a task from the Add Task dropdown. Click on the{' '}
          <Text strong>Add</Text> button. This adds a new task node to the
          editor.
        </Text>
      </Paragraph>
      <Paragraph>
        <Title level={5}>Deleting tasks</Title>
        <Text>
          Select a task node to highlight it. Press{' '}
          <Text keyboard>backspace</Text> to delete it.
        </Text>
      </Paragraph>
      <Paragraph>
        <Title level={5}>Updating tasks</Title>
        <Text>
          Select a task node to highlight it. Head over to the Selected Task
          section to update what task that node points to. Click{' '}
          <Text strong>Update</Text> to save your changes.
        </Text>
      </Paragraph>

      <Divider />

      <Paragraph>
        Edges help to show the flow of task execution. The arrows used to
        represent edges are an indicator of the direction of flow.
      </Paragraph>

      <Paragraph>
        <Title level={5}>Creating edges</Title>
        <Text>
          Hold <Text keyboard>Shift</Text> while dragging your mouse from a node
          to another. Then release. <br />
          This creates a link between the two tasks, where the former task's
          execution triggers the latter task's execution.
        </Text>
      </Paragraph>

      <Paragraph>
        <Title level={5}>Deleting edges</Title>
        <Text>
          Select an edge to highlight. Press <Text keyboard>backspace</Text> to
          delete it.
        </Text>
      </Paragraph>
    </Drawer>
  );
}

TaskGraphInfo.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
