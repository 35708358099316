import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { navigate } from 'hookrouter';
import { Card, Row, Col, Input, Form, Select, Space, Button } from 'antd';
import { SaveOutlined, LockTwoTone, UnlockTwoTone } from '@ant-design/icons';
import { green, red } from '@ant-design/colors';

import {
  changeNewAlias,
  changeNewParameters,
  changeNewCategories,
  changeNewOptions,
  resetEdit,
  resetSharedFields,
} from './sharedFieldSlice';
import store from '../../../store.js';

import { api } from '../../api/api.js';

import FieldFactory from './FieldFactory.js';
import { selectProjectUuid } from '../Project/projectSlice';
import toast from '../../util/toasts';

const { Group: BtnGroup } = Button;

export default function CreateSharedField(props) {
  const projectUuid = useSelector(selectProjectUuid);

  const type = props.type.toLowerCase();
  const newAlias = useSelector((state) => state.sharedField.newAlias);
  const newParameters = useSelector((state) => state.sharedField.newParameters);
  const newCategories = useSelector((state) => state.sharedField.newCategories);
  const newOptions = useSelector((state) => state.sharedField.newOptions);

  const [fieldCategories, setFieldCategories] = useState([]);
  const [loadingCategories, setLoadingCategories] = useState(true);
  const [saving, setSaving] = useState(false);

  const [locked, setLocked] = useState(false);

  const save = () => {
    setSaving(true);
    api.sharedFields
      .post(type, newAlias, newParameters, newCategories, newOptions)
      .then(() => {
        store.dispatch(resetSharedFields());
        navigate('/'+projectUuid+'/sharedfield');
      })
      .catch(() => {
        toast.error('Failed to create new shared field.');
      })
      .finally(() => {
        setSaving(false);
      });
  };

  const onChangeAlias = (e) => {
    const value = e.target.value;
    store.dispatch(changeNewAlias(value))
  };

  const onChangeCategory = (value) => {
    store.dispatch(changeNewCategories(value));
  };

  const onChangeParameters = (value) => {
    store.dispatch(changeNewParameters(value));
  };

  const onChangeOptions = (value) => {
    store.dispatch(changeNewOptions(value));
  };

  const fieldElement = FieldFactory.create(type, {
    value: newParameters,
    options: newOptions,
    onChange: onChangeParameters,
    onOptionsChange: onChangeOptions,
    disabled: locked,
  });

  const typeCapitalized = type.charAt(0).toUpperCase() + type.substr(1);
  const cardTitle = typeCapitalized + ' Type';

  const validationMessages = {
    required: '${label} is a required field',
  };

  const isFullWidth = ['sql', 'json', 'text', 'code'].includes(type);

  const toggleSharedFieldLock = () => {
    setLocked(!locked);
  };

  useEffect(() => {
    return () => {
      store.dispatch(resetEdit());
    };
  }, []);

  useEffect(() => {
    api.sharedFields
      .categories()
      .then(({ results }) => setFieldCategories(results.map((res) => res.name)))
      .finally(() => setLoadingCategories(false));
  }, []);

  return (
    <div>
      <Form
        layout="horizontal"
        validateMessages={validationMessages}
        onFinish={save}
      >
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          <Card
            title={cardTitle}
            extra={
              <BtnGroup>
                <Button
                  icon={
                    locked ? (
                      <UnlockTwoTone twoToneColor={green.primary} />
                    ) : (
                      <LockTwoTone twoToneColor={green.primary} />
                    )
                  }
                  onClick={toggleSharedFieldLock}
                >
                  {locked ? 'Unlock' : 'Lock'}
                </Button>
                <Button
                  style={{
                    backgroundColor: locked ? undefined : green[6],
                    color: locked ? undefined : '#fff',
                  }}
                  disabled={locked}
                  loading={saving}
                  htmlType="submit"
                  icon={<SaveOutlined />}
                >
                  Create
                </Button>
              </BtnGroup>
            }
          >
            <Row gutter={20}>
              <Col xs={24} lg={12}>
                <Form.Item
                  name="alias"
                  label={<strong>Alias</strong>}
                  rules={[{ required: true }]}
                  required
                >
                  <Input
                    value={newAlias}
                    onChange={onChangeAlias}
                    disabled={locked}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Card>

          <Card>
            <Form.Item
              name="field"
              label={<strong>{typeCapitalized}</strong>}
              style={{ width: '100%' }}
              required
            >
              <Row>
                <Col xs={24} lg={isFullWidth ? 24 : 12}>
                  {fieldElement}
                </Col>
              </Row>
            </Form.Item>
          </Card>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              icon={<SaveOutlined />}
              disabled={locked}
              loading={saving}
            >
              Save
            </Button>
          </Form.Item>
        </Space>
      </Form>
    </div>
  );
}
