import { navigate } from 'hookrouter'

import styles from "../../assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { makeStyles } from "@material-ui/core/styles"

import { Button } from 'antd';

const useStyles = makeStyles(styles);

function ViewAction(props) {
	const classes = useStyles();

  const onClick = () => {
    navigate(props.target)
  }
	return (
    <Button onClick={onClick} type="primary">{props.description}</Button>
	)
}

export default ViewAction
