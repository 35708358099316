/*
This file handles screen resizing functions and is best left alone.
You do not need to make changes here, ever.
*/
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Navbar from "../components/Navbars/Navbar.js";
import Footer from "../components/Footer/Footer.js";
import Sidebar from "../components/Sidebar/Sidebar.js";

import styles from "../assets/jss/material-dashboard-react/layouts/adminStyle.js";

import bgImage from "../assets/img/sidebar-2.jpg";

import { changeProject, selectProjectUuid } from '../views/Project/projectSlice.js'
import store from "../../store.js";
import Loading from '../components/Loading.js';
import classNames from 'classnames';

let ps;

const useStyles = makeStyles(styles);

// props contains only the target page
export default function Admin(props) {
  // styles
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();

  const projectID = useSelector(selectProjectUuid);
  // states and functions
  const [image, setImage] = React.useState(bgImage);
  const [color, setColor] = React.useState("blue");
  const [fixedClasses, setFixedClasses] = React.useState("dropdown show");
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const toggleSidebar = () => {
    setSidebarCollapsed(!sidebarCollapsed);
  };

  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };
  
  useEffect(() => {
    store.dispatch(changeProject(props.projectID))
  }, [props.projectID]);

  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, [mainPanel]);

  useEffect(() => {
    const resizeHandler = () => {
      if (window.innerWidth < 960) {
          setSidebarCollapsed(true);
      } else {
          setSidebarCollapsed(false);
      }
    };

    // call it the first time
    resizeHandler();

    window.addEventListener('resize', resizeHandler);

    return function cleanup() {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  const showPanelOverlay = window.innerWidth < 767 && !sidebarCollapsed ? "block" : "none";

  return (
    <div className={classes.wrapper}>
      <Sidebar
        sidebarCollapsed={sidebarCollapsed}
        handleDrawerToggle={handleDrawerToggle}
      />
      <div 
        className={classNames(classes.mainPanel, {
          [classes.mainPanelCollapsed]: sidebarCollapsed
        })} 
        ref={mainPanel}
      >
        <div 
          className={classes.mainPanelOverlay} 
          style={{ display: showPanelOverlay }}
          onClick={() => setSidebarCollapsed(true)}
        ></div>
        <Navbar
          sidebarCollapsed={sidebarCollapsed}
          toggleSidebar={toggleSidebar}
          handleDrawerToggle={handleDrawerToggle}
        />
          <div className={classes.content}>
            <div className={classes.container}>
              {props.projectID !== projectID ? <Loading /> : <props.TargetPage props={props} />}
            </div>
          </div>
        <Footer />
      </div>
    </div>
  );
}
