import { useSelector } from 'react-redux';
import { Row, Col, Card, Space } from 'antd';

import store from '../../../store';

import ProjectItem from '../Project/ProjectItem';
import DescriptionItem from '../Description/DescriptionItem';

import ViewAction from '../ViewAction/ViewAction';

import SequencesTable from './SequencesTable';
import CreateSequence from './CreateSequence';
import EditSequence from './EditSequence';
import SequenceExecutions from './SequenceExecutions';
import SequenceTabs from './SequenceTabs';

import { selectProjectUuid, selectProjectAlias } from '../Project/projectSlice';
import { loadAllSequences } from './sequencesSlice';

export default function SequencesPage({ props }) {
  const projectAlias = useSelector(selectProjectAlias);
  const projectUuid = useSelector(selectProjectUuid);

  store.dispatch(loadAllSequences());

  let Target = <div />;
  if (props.create) {
    Target = <CreateSequence />;
  } else if (props.edit) {
    Target = <EditSequence sequenceID={props.sequenceID} />;
  } else if (props.executions) {
    Target = <SequenceExecutions sequenceID={props.sequenceID} />;
  } else {
    Target = <SequencesTable />;
  }

  const isTabbed = props.edit || props.executions;

  return (
    <Space direction="vertical" size="small" style={{ width: '100%' }}>
      <div>
        <Card bordered={false}>
          <Row justify="space-between" align="middle">
            <Col>
              <DescriptionItem
                title="Sequences"
                description="Manage Groups of Tasks as Sequences."
              />
            </Col>
            <Col>
              {props.executions || props.edit || props.create ? (
                <div />
              ) : (
                <ViewAction
                  target={`/${projectUuid}/sequences/create`}
                  description={'Create Sequence'}
                />
              )}
            </Col>
          </Row>
          {isTabbed ? (
            <div>
              <strong style={{ fontWeight: 500 }}>Current Project:</strong>{' '}
              {projectAlias}
            </div>
          ) : (
            <ProjectItem />
          )}
        </Card>
        <Card bordered={false} bodyStyle={{ paddingTop: 0, paddingBottom: 0 }}>
          {isTabbed ? (
            <SequenceTabs
              tab={props.edit ? 'edit' : 'executions'}
              projectID={projectUuid}
              sequenceID={props.sequenceID}
            />
          ) : null}
        </Card>
      </div>
      <div>{Target}</div>
    </Space>
  );
}
