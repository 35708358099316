import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import {
  DatePicker,
  Form,
  Button,
  Select,
  Row,
  Col,
  Card,
  Space,
} from 'antd';
import { FilterOutlined } from '@ant-design/icons';
import SequenceExecutionsTable from './SequenceExecutionsTable.js';

import store from '../../../store.js';
import { fetchSequenceExecutions, selectSequenceExecutions } from './sequencesSlice.js';

const { Option } = Select;
const { RangePicker } = DatePicker;

const preconfiguredDateRanges = {
  'Last Hour': [moment().subtract(1, 'h'), moment()],
  Today: [moment().startOf('day'), moment().endOf('day')],
  'Last 48 Hours': [moment().subtract(48, 'h'), moment().endOf('day')],
  'Last 7 Days': [moment().subtract(7, 'd'), moment().endOf('day')],
  'This Month': [moment().startOf('month'), moment().endOf('month')],
};

// previous 7 days
const defaultRange = preconfiguredDateRanges['Last 7 Days'];

export default function SequenceExecutions(props) {
  const { sequenceID } = props;
  const { loading: executionsLoading } = useSelector(selectSequenceExecutions);
  const [filters, setFilters] = useState({
    status: {
      running: false,
      Error: false,
      complete: false,
    },
    range: {
      from: defaultRange[0],
      to: defaultRange[1],
    },
    pageSize: 100,
  });

  const updatePageSize = (size) => {
    setFilters({ ...filters, pageSize: size });
  };

  const toggleStatusFilter = (event) => {
    const { name } = event.currentTarget.dataset;
    setFilters({
      ...filters,
      status: {
        ...filters.status,
        [name]: !filters.status[name],
      },
    });
  };

  const buttonType = (applied) => {
    if (applied) return 'primary';
    return 'default';
  };

  const updateRangeFilter = (dates) => {
    if (dates) {
      const [from, to] = dates;
      setFilters({ ...filters, range: { from, to } });
    } else {
      setFilters({ ...filters, range: { from: null, to: null } });
    }
  };

  const applyFilters = () => {
    const { pageSize, range, status } = filters;

    const query = {};

    query.status_list = Object.entries(status)
      .filter(([_, value]) => value)
      .map(([key]) => key)
      .join(',');

    query.page_size = pageSize;

    if (range.from && range.to) {
      query.created_start = range.from.valueOf();
      query.created_end = range.to.valueOf();
    }

    // reset store
    store.dispatch(fetchSequenceExecutions({ sequenceID, filters: query }));
  };

  useEffect(() => {
    applyFilters();
  }, []);

  return (
    <Space direction="vertical" style={{ width: '100%', fontWeight: 'normal' }}>
      <Card size="small">
        <Form layout="horizontal">
          <Row gutter={[20, 0]}>
            <Col>
              <Form.Item label={<strong>Status</strong>}>
                <Button.Group>
                  <Button
                    data-name="running"
                    type={buttonType(filters.status.running)}
                    onClick={toggleStatusFilter}
                  >
                    Running
                  </Button>
                  <Button
                    data-name="Error"
                    type={buttonType(filters.status.Error)}
                    onClick={toggleStatusFilter}
                  >
                    Error
                  </Button>
                  <Button
                    data-name="complete"
                    type={buttonType(filters.status.complete)}
                    onClick={toggleStatusFilter}
                  >
                    Complete
                  </Button>
                </Button.Group>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label={<strong>Range</strong>}>
                <RangePicker
                  ranges={preconfiguredDateRanges}
                  defaultValue={defaultRange}
                  showTime
                  allowClear={false}
                  style={{ width: '100%' }}
                  format="YYYY/MM/DD HH:mm:ss"
                  onChange={updateRangeFilter}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label={<strong>Max Results</strong>}>
                <Select
                  defaultValue={100}
                  onChange={(value) => {
                    updatePageSize(value);
                  }}
                >
                  <Option value={10}>10</Option>
                  <Option value={50}>50</Option>
                  <Option value={100}>100</Option>
                  <Option value={500}>500</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col>
              <Button
                type="primary"
                icon={<FilterOutlined />}
                loading={executionsLoading}
                onClick={applyFilters}
              >
                Apply Filters
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>

      <Card size="small">
        <SequenceExecutionsTable />
      </Card>
    </Space>
  );
}
