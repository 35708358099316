import React from 'react';
import { useSelector } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles';
import { Grid, Card, CardActionArea } from '@material-ui/core';

import LoginComponent from './LoginComponent.js'
import ForgotPasswordComponent from './ForgotPasswordComponent.js'
import loginStyles from './loginStyles';

export default function LoginView() {
	const useStyles = makeStyles(loginStyles);
	const classes = useStyles();

	const forgotPassword = useSelector((state) => state.login.forgotPassword)
	const form = forgotPassword ? <ForgotPasswordComponent /> : <LoginComponent />;

	return (
		<Grid
		  container
		  spacing={0}
		  direction="column"
		  alignItems="center"
		  style={{ minHeight: '100vh' }}
		>
	  		<Grid item xs={8}>
	  			<img className={classes.marginTop} src="/static/img/SVG/banner-black.svg" />
	  			<Card className={classes.root}>
						{form}
    			</Card>
	  		</Grid> 
	  		<Grid item xs={6}>
	  		</Grid>  
		</Grid> 
	)
}
