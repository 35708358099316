import { useEffect } from 'react';
import { List, Space, Card } from 'antd';
import { useSelector } from 'react-redux';
import { A } from 'hookrouter';

import DescriptionItem from '../Description/DescriptionItem';
import TaskTabs from './TaskTabs';

import store from '../../../store';
import {
  fetchTaskDependencies,
  resetTaskDependencies,
  selectTaskDependencies,
} from './shared/tasksSlice';
import { selectProjectAlias, selectProjectUuid } from '../Project/projectSlice';

export default function TaskDependencies(props) {
  const { taskID } = props;
  const projectUuid = useSelector(selectProjectUuid);
  const projectAlias = useSelector(selectProjectAlias);
  const {
    loading: loadingDeps,
    error: loadError,
    data: taskDependencies,
  } = useSelector(selectTaskDependencies);

  useEffect(() => {
    store.dispatch(fetchTaskDependencies(taskID));
    return function cleanup() {
      store.dispatch(resetTaskDependencies());
    };
  }, []);

  return (
    <Space direction="vertical" size="middle" style={{ width: '100%' }}>
      <div>
        <Card>
          <Space direction="vertical" size="middle" style={{ width: '100%' }}>
            <DescriptionItem
              title="Task Details"
              description="Details of an existing task"
            />

            <div>
              <strong style={{ fontWeight: 500 }}>Current Project:</strong>{' '}
              {projectAlias}
            </div>
          </Space>
        </Card>
        <Card bordered={false} bodyStyle={{ paddingTop: 0, paddingBottom: 0 }}>
          <TaskTabs
            tab="dependencies"
            projectID={projectUuid}
            taskID={props.taskID}
          />
        </Card>
      </div>

      <Card title="Dependent Sequences">
        <List
          bordered
          loading={loadingDeps}
          dataSource={taskDependencies || []}
          renderItem={({ uuid, alias }) => (
            <List.Item key={uuid}>
              <A href={`${projectUuid}/sequences/edit/${uuid}`}>
                <strong>{alias}</strong>
              </A>
            </List.Item>
          )}
        />
      </Card>
    </Space>
  );
}
