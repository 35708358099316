import { useSelector } from 'react-redux'

import { Button } from 'antd'

import { api } from '../../api/api'

import { loadRoles, addRole, editRole, cancelNewRole, saveNewRole, saveEditRole, addRoleValues, setNewRoleActions, setNewRoleResources, setEditRolesSet, deleteRole } from './permissionSlice.js'
import store from '../../../store.js'

import { Tabs } from 'antd';

const { TabPane } = Tabs;

import { Table, Tag, Input, Select, Space } from 'antd';

const { Option } = Select;

import toast from '../../util/toasts';

export default function Roles() {
    let projects = useSelector((state) => state.permissions.projects)
    let rolesloaded = useSelector((state) => state.permissions.rolesloaded)
    let roles = useSelector((state) => state.permissions.roles)
    let newRole = useSelector((state) => state.permissions.newRole)
    let edit = useSelector((state) => state.permissions.editRole)

    const editRoleName = useSelector((state) => state.permissions.editRoleName)
    const editRoleProject = useSelector((state) => state.permissions.editRoleProject)
    const editRoleResources = useSelector((state) => state.permissions.editRoleResources)
    const editRoleActions = useSelector((state) => state.permissions.editRoleActions)
    const editRoleResourcesSet = useSelector((state) => state.permissions.editRolesSet)

    const newRoleResources = useSelector((state) => state.permissions.newRoleResources)
    const newRoleActions = useSelector((state) => state.permissions.newRoleActions)
    if(!rolesloaded) {
        store.dispatch(loadRoles())
    }

    let dataSource = []

    function save() {
        if(newRoleResources.length == 0 || newRoleActions.length == 0) {
            alert('neither resources nor actions can be empty')
        } else {
            store.dispatch(saveNewRole())
        }
    }

    function saveEdit() {
        if(newRoleResources.length == 0 || newRoleActions.length == 0) {
            alert('neither resources nor actions can be empty')
        } else {
            store.dispatch(saveEditRole())
        }
    }

    function cancel() {
        store.dispatch(cancelNewRole())
    }

    function add() {
        store.dispatch(addRole())
    }

    function nameChange(e) {
        store.dispatch(addRoleValues({field: 'role', value: e.target.value}))
    }

    function descriptionChange(value) {
        store.dispatch(addRoleValues({field: 'project', value: value}))
    }

    let TransfersElement = null;
    let addRow = {}
    if(newRole) {
        let projectOptions = []
        projects.forEach(project => {
            projectOptions.push(<Option key={project.uuid} value={project.uuid}>{project.alias}</Option>)
        })

        TransfersElement = (
            <>
                <div>Resources</div>
                <TransferResources initial={[]} />
                <div>Actions</div>
                <TransferActions initial={[]} />
            </>
        );

        addRow = {uuid: 'new', role: <Input placeholder="role name" onChange={nameChange} />, project: <Select defaultValue="default" onChange={descriptionChange}>
          {projectOptions}
        </Select>, edit: <div><Button onClick={save} type="primary">Save</Button><Button onClick={cancel}>Cancel</Button></div>}
    } else {
        addRow = {uuid: 'not-new', name: '', description: '', edit: <Button onClick={add} type="primary">New Role</Button>}
    }

    if(edit) {
        TransfersElement = (
            <>
                <div>Resources</div>
                <TransferResources initial={editRoleResources} />
                <div>Actions</div>
                <TransferActions initial={editRoleActions} />
            </>
        );

        if(!editRoleResourcesSet) {
            store.dispatch(setNewRoleResources(editRoleResources))
            store.dispatch(setNewRoleActions(editRoleActions))
            store.dispatch(setEditRolesSet())
        }

        addRow = {uuid: 'edit', role: <Input placeholder="role name" defaultValue={editRoleName} onChange={nameChange} />, project: editRoleProject, edit: <div><Button onClick={saveEdit} type="primary">Save</Button><Button onClick={cancel}>Cancel</Button></div>}
    }

    dataSource.push(addRow)
    if(!newRole && !edit) {
        roles.forEach(role => {
            let alias = ''
            if(role.project) {
                alias = role.project.alias
            }
            let editFunctor = () => {
                store.dispatch(editRole({role: role.role_name, role_uuid: role.uuid, projectname: alias, project_uuid: role.project_uuid, editRoleResources: role.resources, editRoleActions: role.actions}))
            }

            
            let deleteRow = () => {
                store.dispatch(deleteRole({project_uuid: role.project_uuid, uuid: role.uuid}))
                toast.success('Role deleted.');
            }

            dataSource.push({uuid: role.uuid, project: alias, role: role.role_name, edit: <Button onClick={editFunctor}>{'Edit'}</Button>, delete: <Button danger type="primary" onClick={deleteRow}>{'Delete'}</Button>})
        })
    }
    const columns = [
        {
            title: 'Role Name',
            dataIndex: 'role',
            key: 'role',
        },
        {
            title: 'Project Name',
            dataIndex: 'project',
            key: 'project',
        },
        {
            title: '',
            dataIndex: 'edit',
            key: 'edit',
        },
        {
            title: '',
            dataIndex: 'delete',
            key: 'delete',
        },
    ];

    return (
        <div>
            <Table rowKey="uuid" dataSource={dataSource} columns={columns} pagination={false} />
            {TransfersElement}
        </div>
    );
}

const resources = ['task', "policy", "project", "connection", "sequence", "sequence_execution", "sequence_task", "task_execution", "system_logs", "config", "dashboard", "group", "shared_object", "user"]
const actions = ['get', 'list', 'update', 'patch', 'delete', 'create', 'execute']

import { useState } from 'react';
import { Transfer } from 'antd';

function TransferActions(props) {
    const data = actions
    const mockData = []
    data.forEach(item => {
            mockData.push({
                key: item,
                title: item,
                description: ''
            })
    })

    let initialTargetKeys = []
    if (props.initial[0] == '*') {
        initialTargetKeys = actions
    } else {
        initialTargetKeys = props.initial;
    }

    const [targetKeys, setTargetKeys] = useState(initialTargetKeys);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const onChange = (nextTargetKeys, direction, moveKeys) => {
        setTargetKeys(nextTargetKeys);
        let set = new Set(targetKeys)
        if(direction=='right') {
            selectedKeys.forEach(key => {
                set.add(key)
            })
        } else {
            selectedKeys.forEach(key => {
                set.delete(key)
            })
        }
        const actionsArray = []
        set.forEach(item =>  {
            actionsArray.push(item)
        })
        store.dispatch(setNewRoleActions(actionsArray))
    };

    const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
        setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
    };

    return <Transfer
        dataSource={mockData}
        titles={['Available', 'Selected']}
        targetKeys={targetKeys}
        selectedKeys={selectedKeys}
        onChange={onChange}
        onSelectChange={onSelectChange}
        render={item => item.title}
    />
}

function TransferResources(props) {
        const data = resources
        const mockData = []
        data.forEach(item => {
            mockData.push({
                key: item,
                title: item,
                description: ''
            })
        })

    let initialTargetKeys = []
    if (props.initial[0] == '*') {
        initialTargetKeys = resources
    } else {
        initialTargetKeys = props.initial;
    }
    

    const [targetKeys, setTargetKeys] = useState(initialTargetKeys);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const onChange = (nextTargetKeys, direction, moveKeys) => {
        setTargetKeys(nextTargetKeys);
        let set = new Set(targetKeys)
        if(direction=='right') {
            selectedKeys.forEach(key => {
                set.add(key)
            })
        } else {
            selectedKeys.forEach(key => {
                set.delete(key)
            })
        }
        const resourcesArray = []
        set.forEach(item =>  {
            resourcesArray.push(item)
        })
        store.dispatch(setNewRoleResources(resourcesArray))
    };

    const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
        setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
    };

    return <Transfer
        dataSource={mockData}
        titles={['Available', 'Selected']}
        targetKeys={targetKeys}
        selectedKeys={selectedKeys}
        onChange={onChange}
        onSelectChange={onSelectChange}
        render={item => item.title}
    />
}