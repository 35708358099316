import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { navigate } from 'hookrouter';

import { Card, Button, Radio, Typography, notification } from 'antd';
import { DeleteOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { red } from '@ant-design/colors';
import { makeStyles } from '@material-ui/core/styles';

import store from '../../../store.js';
import {
  selectNewSequence,
  updateNewSequence,
  resetSequences,
  loadSequences,
  loadAllSequences,
} from './sequencesSlice';
import { selectProjectUuid } from '../Project/projectSlice';
import { loadTasks } from '../Tasks/shared/tasksSlice.js';

import { api } from '../../api/api.js';

import Loading from '../../components/Loading.js';
import SequenceForm from './SequenceForm.js';
import toast from '../../util/toasts.js';
import checkIfGraph from './shared/checkIfGraph.js';

export default function CreateSequence(props) {
  const project_uuid = useSelector(selectProjectUuid);
  const {
    alias,
    schedule,
    triggerSequence,
    triggerType,
    enabled: switchEnabled,
    tasks: sequenceTasks,
    taskGraph: sequenceTaskGraph,
  } = useSelector(selectNewSequence);

  const isGraph = true;

  const getTasksError = () => {
    if (!sequenceTasks.length) return 'Must have at least one task in sequence';
    if (sequenceTasks.some((task) => !task))
      return 'Select or remove empty tasks before submitting';
    return null;
  };

  const handleSubmit = async () => {
    const error = getTasksError(sequenceTasks);
    if (error) {
      toast.error(error);
      return Promise.reject(error);
    }

    let sequenceTasksWithOrder = [];
    let count = 1;
    sequenceTasks.forEach((task) => {
      sequenceTasksWithOrder.push({ order_id: count, task_uuid: task });
      count += 1;
    });

    const handleError = ({ response = { data: {} } }) => {
      const { data } = response;
      let message;
      if (data.errors) {
        message = Object.values(data.errors.json)[0];
      } else {
        message =
          data.error ||
          'An error occured on our servers. We are working to fix it.';
      }
      if (message) {
        toast.error(message);
      }
    };

    let action;

    if (triggerType == 'cron') {
      if (isGraph) {
        action = api.sequences.postCronGraph(
          alias,
          switchEnabled,
          schedule,
          project_uuid,
          sequenceTaskGraph,
        );
      } else {
        action = api.sequences.postCron(
          alias,
          switchEnabled,
          schedule,
          project_uuid,
          sequenceTasksWithOrder,
        );
      }
    } else if (triggerType == 'sequence') {
      if (isGraph) {
        action = api.sequences.postSequenceGraph(
          alias,
          switchEnabled,
          triggerSequence,
          project_uuid,
          sequenceTaskGraph,
        );
      } else {
        action = api.sequences.postSequence(
          alias,
          switchEnabled,
          triggerSequence,
          project_uuid,
          sequenceTasksWithOrder,
        );
      }
    }

    try {
      await action;
      store.dispatch(resetSequences());
      navigate('/' + project_uuid + '/sequences');
    } catch (error) {
      return handleError(error);
    }
  };

  useEffect(() => {
    store.dispatch(loadSequences(project_uuid));
    store.dispatch(loadAllSequences());
    store.dispatch(loadTasks(project_uuid));
  }, [project_uuid]);

  return (
    <Card>
      <SequenceForm
        sequenceID={null}
        selectSequenceData={selectNewSequence}
        updateSequenceData={updateNewSequence}
        isGraph={isGraph}
        onSubmit={handleSubmit}
      />
    </Card>
  );
}
