import { Row, Col, Card, Space } from 'antd';

// core components

import ProjectItem from '../Project/ProjectItem';
import DescriptionItem from '../Description/DescriptionItem';

import ViewAction from '../ViewAction/ViewAction';

import AddConnection from './AddConnection';
import ConnectionDetail from './ConnectionDetail';
import CreateConnection from './CreateConnection';
import ConnectionsTable from './ConnectionsTable';

export default function ConnectionsPage(props) {
  let Target = <div />;
  if (props.props.create) {
    Target = <AddConnection />;
  } else if (props.props.createID) {
    Target = (
      <CreateConnection connectionTypeID={props.props.connectionTypeID} />
    );
  } else if (props.props.edit) {
    Target = <ConnectionDetail connectionID={props.props.connectionID} />;
  } else {
    Target = <ConnectionsTable />;
  }

  return (
    <Space direction="vertical" size="small" style={{ width: '100%' }}>
      <Card>
        <Row justify="space-between" align="middle">
          <Col>
            <DescriptionItem
              title="Connections"
              description="View and manage connections."
            />
          </Col>
          { props.props.create || props.props.createID || props.props.edit ? <div /> : 
            <Col>
              <ViewAction
                target={'./connections/create'}
                description={'Create Connection'}
              />
            </Col>
          }
          
        </Row>
        <ProjectItem />
      </Card>
      <div>{Target}</div>
    </Space>
  );
}
