import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Card,
  Row,
  Col,
  Input,
  Form,
  Space,
  Button,
  Divider,
  Dropdown,
  Menu,
} from 'antd';
import { green } from '@ant-design/colors';
import {
  EditOutlined,
  MenuOutlined,
  LockTwoTone,
  SaveOutlined,
  UnlockTwoTone,
} from '@ant-design/icons';
import { navigate } from 'hookrouter';
// @material-ui/core

import {
  changeNewAlias,
  configureNewSharedConfig,
  resetEdit,
  loadSharedConfigTypes,
  loadSharedConfigs,
  resetSharedConfigs,
  hardSetParameters,
  selectSharedConfigTypes,
} from '../sharedConfigSlice';
import { selectProjectUuid } from '../../Project/projectSlice';
import store from '../../../../store.js';
import { api } from '../../../api/api.js';

import JSONParamEditor from '../../../components/JSONParamEditor';

import toast from '../../../util/toasts';

import ManySection from './renderers/ManySection';
import ParameterSection from './renderers/ParameterSection';
import SingleParameterSection from './renderers/SingleParameterSection';

const { Group: BtnGroup } = Button;
const { useForm } = Form;

// selectors
const selectStateParamaters = (state) => state.sharedConfig.newParameters;
const selectNewAlias = (state) => state.sharedConfig.newAlias;
const selectNewSharedConfigSet = (state) =>
  state.sharedConfig.newSharedConfigSet;
const selectParametersSet = (state) => state.sharedConfig.parametersSet;

export default function CreateSharedConfig(props) {
  const projectUuid = useSelector(selectProjectUuid);

  let stateParameters = useSelector(selectStateParamaters);

  const newAlias = useSelector(selectNewAlias);
  const newSharedConfigSet = useSelector(selectNewSharedConfigSet);

  const parametersSet = useSelector(selectParametersSet);

  const sharedConfigUUID = props.uuid;
  const sharedConfigTypes = useSelector(selectSharedConfigTypes);
  const targetType = sharedConfigTypes.find(
    (type) => sharedConfigUUID == type.uuid,
  );

  const [form] = useForm();
  const [locked, setLocked] = useState(false);
  const [saving, setSaving] = useState(false);
  const [paramEditorOpen, setParamEditorOpen] = useState(false);

  const fullParameters = {
    ...stateParameters,
    alias: newAlias,
  };

  const toggleSharedFieldLock = () => {
    setLocked(!locked);
  };

  const onChangeAlias = (e) => {
    const value = e.target.value;
    store.dispatch(changeNewAlias(value));
  };

  const save = () => {
    setSaving(true);
    // TODO: turn into proper actionreducer
    api.sharedConfigs
      .post(targetType.uuid, newAlias, stateParameters)
      .then((res) => {
        navigate('/' + projectUuid + '/sharedconfig');
      })
      .catch(() => {
        toast.error('Failed to create new shared config.');
      })
      .finally(() => {
        setSaving(false);
      });
  };

  const openJSONParamEditor = () => {
    setParamEditorOpen(true);
  };

  const handleParamEditorClose = (data, wasCancelled) => {
    if (wasCancelled) {
      setParamEditorOpen(false);
      return;
    }

    store.dispatch(hardSetParameters(data));
    for (let name in data) {
      form.setFieldsValue({ [name]: data[name] });
    }
    store.dispatch(changeNewAlias(data.alias));
    setParamEditorOpen(false);
  };

  useEffect(() => {
    // load shared config types
    store.dispatch(loadSharedConfigTypes());
    store.dispatch(loadSharedConfigs());

    return () => {
      store.dispatch(resetSharedConfigs());
      store.dispatch(resetEdit());
    };
  }, []);

  useEffect(() => {
    if (!newSharedConfigSet && targetType)
      store.dispatch(configureNewSharedConfig(targetType));
  }, [newSharedConfigSet, targetType]);

  if (!targetType || !newSharedConfigSet) return null;

  let parameterCategories = [];
  let parameters = [];

  targetType.parameters.forEach((category) => {
    parameterCategories.push(category);
    if (category.name != 'alias') {
      if (category.many || category.type === 'array') {
        parameters.push(
          <ManySection
            key={category.name}
            section={category}
            parameterSet={parametersSet}
            locked={locked}
          />,
        );
      } else if (category.type === 'group' || category.type === 'nested') {
        parameters.push(
          <ParameterSection
            key={category.name}
            section={category}
            locked={locked}
          />,
        );
      } else {
        parameters.push(
          <SingleParameterSection
            key={category.name}
            parameter={category}
            state={stateParameters}
            locked={locked}
          />,
        );
      }
    }
  });

  const validationMessages = {
    required: '${label} is a required field',
  };

  return (
    <div>
      <JSONParamEditor
        open={paramEditorOpen}
        data={fullParameters}
        onClose={handleParamEditorClose}
        disabled={locked}
      />

      <Form
        form={form}
        layout="vertical"
        onFinish={save}
        validateMessages={validationMessages}
      >
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          <Card
            title={targetType.alias}
            extra={
              <BtnGroup>
                <Button
                  icon={
                    locked ? (
                      <UnlockTwoTone twoToneColor={green.primary} />
                    ) : (
                      <LockTwoTone twoToneColor={green.primary} />
                    )
                  }
                  onClick={toggleSharedFieldLock}
                >
                  {locked ? 'Unlock' : 'Lock'}
                </Button>
                <Button
                  htmlType="submit"
                  style={{
                    backgroundColor: locked ? undefined : green[6],
                    color: locked ? undefined : '#fff',
                  }}
                  disabled={locked}
                  loading={saving}
                  icon={<SaveOutlined />}
                >
                  Create
                </Button>
                <Dropdown
                  trigger={['click', 'hover']}
                  overlay={
                    <Menu>
                      <Menu.Item
                        key="edit"
                        icon={<EditOutlined />}
                        onClick={() => openJSONParamEditor()}
                      >
                        Edit
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <Button>
                    <MenuOutlined />
                  </Button>
                </Dropdown>
              </BtnGroup>
            }
          >
            <Card.Meta description={targetType.description} />

            <Divider />

            <Row gutter={20}>
              <Col xs={24} lg={12}>
                <Form.Item
                  name="alias"
                  label={<strong>Alias</strong>}
                  rules={[
                    { required: true, message: 'Alias is a required field' },
                  ]}
                  initialValue={newAlias}
                  required
                >
                  <Input
                    size="large"
                    value={newAlias}
                    onChange={onChangeAlias}
                    disabled={locked}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Card>

          <Card title="Parameters">
            <Space direction="vertical" size="large" style={{ width: '100%' }}>
              {parameters}
            </Space>
          </Card>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              icon={<SaveOutlined />}
              loading={saving}
              disabled={locked}
            >
              Save
            </Button>
          </Form.Item>
        </Space>
      </Form>
    </div>
  );
}
