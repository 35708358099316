import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { A } from 'hookrouter';
import { capitalize } from 'lodash';

import { Card, Input, Space, Table, Tag } from 'antd';

import store from '../../../store.js';
import {
  loadConnections,
  loadConnectionTypes,
  resetConnections,
  selectConnections,
  selectConnectionTypes,
} from './connectionsSlice';
import { selectProjectUuid } from '../Project/projectSlice';

import { compareStrings } from '../../util/strings';

const { Search } = Input;

const columns = [
  {
    title: 'Alias',
    dataIndex: 'alias',
    filtered: true,
    render: (value, record) => (
      <A href={'/' + record.project_uuid + '/connections/' + record.uuid}>
        {value}
      </A>
    ),
    sorter: (a, b) => compareStrings(a.alias, b.alias),
  },
  {
    title: 'Connection Type',
    dataIndex: ['connection_type', 'alias'],
    width: 200,
    onFilter: (value, record) => record.connection_type.uuid === value,
    render(value) {
      return (
        <Tag
          style={{
            borderStyle: 'dashed',
            padding: '0.4rem 0.6rem',
            backgroundColor: 'transparent',
          }}
        >
          {capitalize(value)}
        </Tag>
      );
    },
    sorter: (a, b) =>
      compareStrings(a.connection_type.alias, b.connection_type.alias),
  },
  {
    title: 'Description',
    dataIndex: 'description',
  },
  {
    title: 'Last Updated',
    dataIndex: 'updated',
    render: (value) => new Date(value).toLocaleString(),
    sorter: (a, b) => new Date(a.updated) - new Date(b.updated),
  },
];

export default function ConnectionsTable(props) {
  const projectUuid = useSelector(selectProjectUuid);
  const connections = useSelector(selectConnections);
  const connectionTypes = useSelector(selectConnectionTypes);
  const connectionsLoaded = useSelector(
    (state) => state.connections.connectionsLoaded,
  );

  const [filteredData, setFilteredData] = useState(connections);
  const [tableColumns, setTableColumns] = useState(columns);

  useEffect(() => {
    const column = columns[1];
    const typeColumn = {
      ...column,
      filters: connectionTypes.map(({ uuid: value, alias: text }) => ({
        text,
        value,
      })),
    };
    const newColumns = tableColumns.slice();
    newColumns[1] = typeColumn;
    setTableColumns(newColumns);
  }, [connectionTypes.length]);

  const searchConnections = (query) => {
    query = query.toLowerCase();
    if (!query) setFilteredData(connections);
    else
      setFilteredData(
        connections.filter((data) => {
          return data.alias.toLowerCase().includes(query);
        }),
      );
  };

  useEffect(() => {
    if (!connections.length) {
      setFilteredData([]);
    } else {
      // ensure that we don't populate the table with the result
      // of a past connections fetch. this is important because
      // we can't predict the order in which connections fetch resolve
      // Main problem: connections for the default project are requested for
      // first before the connections for the selected project 
      if (connections[0].project_uuid === projectUuid)
        setFilteredData(connections);
    }
  }, [connections]);

  useEffect(() => {
    store.dispatch(loadConnections());
  }, [projectUuid]);

  useEffect(() => {
    store.dispatch(loadConnectionTypes());

    return () => {
      store.dispatch(resetConnections());
    };
  }, []);

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Card size="small">
        <Search
          allowClear
          style={{ maxWidth: 300 }}
          placeholder="Search by connection name"
          onChange={({ target }) => searchConnections(target.value)}
          onSearch={(value) => searchConnections(value)}
        />
      </Card>

      <Card size="small">
        <Table
          expandable
          rowKey="uuid"
          columns={tableColumns}
          dataSource={filteredData}
          pagination={{
            pageSize: 50,
            position: 'both',
            size: 'middle',
          }}
          loading={!connectionsLoaded}
        />
      </Card>
    </Space>
  );
}
