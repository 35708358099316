import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { navigate } from 'hookrouter';

import { makeStyles } from '@material-ui/core/styles';
import {
  Space,
  Card,
  Input,
  Button,
  Select,
  Form,
  Typography,
  Alert,
  Steps,
  Spin
} from 'antd';
import { UserOutlined, LockOutlined, LoadingOutlined } from '@ant-design/icons';

import {
  setUsername,
  setPassword,
  submitLogin,
  registerAccount,
  changeOrgUuid,
  changeOrgName,
  resetReady,
  setLoginReady
} from './setupSlice';

import { card } from '../../assets/jss/material-dashboard-react';

import { api } from '../../api/api';
import store from '../../../store.js';

const { Option } = Select;
const { Paragraph, Text } = Typography;
const { Step } = Steps;
const { Item: FormItem } = Form;

// TODO: create EULA accept view - as StepZero - BLOCKED by Legal approval

// TODO: block the login button on StepThree out for 2 minutes

const useStyles = makeStyles({
  viewContainer: {
    padding: '6rem 1rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
    maxWidth: 661,
    fontWeight: 'normal',
  },
  formCard: {
    boxShadow: card.boxShadow,
    borderRadius: card.borderRadius,
  },
  submitButton: {
    width: '100%',
  },
  stepOneTitle: {
    textAlign: 'center',
  },
});

export default function SetupView() {
  const classes = useStyles();
  const step = useSelector((state) => state.setup.step);

  const stepElement = useMemo(() => {
    if (step == 1) {
      return <StepOne classes={classes} />;
    } else if (step == 2) {
      return <StepTwo classes={classes} />;
    }
    return <StepThree classes={classes} />;
  }, [step]);

  return (
    <div>
      <div className={classes.viewContainer}>
        <Space direction="vertical" style={{ width: '100%' }}>
          <div>
            <img src="/static/img/SVG/banner-black.svg" />
          </div>
          {stepElement}
        </Space>
      </div>
    </div>
  );
}

function StepOne({ classes }) {
  const [submitError, setSubmitError] = useState(null);

  function changeUsername(e) {
    // reset error
    submitError && setSubmitError(null);
    store.dispatch(setUsername(e.target.value));
  }

  function changePassword(e) {
    // reset error
    submitError && setSubmitError(null);
    store.dispatch(setPassword(e.target.value));
  }

  async function submit() {
    const { error } = await store.dispatch(submitLogin());
    if (error) {
      setSubmitError(
        'Login failed. Please ensure your credentials are correct.',
      );
    }
  }

  return (
    <Card
      className={classes.formCard}
      title="Log in with your Nom Nom Data account"
    >
      {submitError && (
        <Paragraph>
          <Alert type="error" message={submitError} />
        </Paragraph>
      )}
      <Form
        layout="vertical"
        autoComplete="off"
        onFinish={submit}
        scrollToFirstError
      >
        <FormItem
          name="username"
          rules={[
            {
              required: true,
              message: 'Username is a required field',
            },
          ]}
          required
        >
          <Input
            size="large"
            prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            onChange={changeUsername}
            placeholder="Username"
          />
        </FormItem>
        <FormItem
          name="password"
          rules={[
            {
              required: true,
              message: 'Password is a required field',
            },
          ]}
          required
        >
          <Input.Password
            prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            size="large"
            onChange={changePassword}
            placeholder="Password"
          />
        </FormItem>
        <FormItem>
          <Button
            htmlType="submit"
            type="primary"
            className={classes.submitButton}
            size="large"
          >
            Submit
          </Button>
        </FormItem>
      </Form>
    </Card>
  );
}

function StepTwo({ classes }) {
  const organizations = useSelector((state) => state.setup.organizations);
  const [submitError, setSubmitError] = useState(null);

  async function submit() {
    const { error } = await store.dispatch(registerAccount());
    if (error) {
      setSubmitError('Could not process request. Please try again.');
    }
  }

  function changeName(e) {
    // reset error
    submitError && setSubmitError(null);
    store.dispatch(changeOrgName(e.target.value));
  }

  function changeOrganization(value) {
    // reset error
    submitError && setSubmitError(null);
    store.dispatch(changeOrgUuid(value));
  }

  return (
    <Card className={classes.formCard} title="Configure your nominode">
      {submitError && (
        <Paragraph>
          <Alert type="error" message={submitError} />
        </Paragraph>
      )}
      <Form layout="vertical" onFinish={submit}>
        <FormItem
          name="node_name"
          label="Name"
          extra="Give your nominode a memorable name"
          rules={[
            {
              required: true,
              message: "Can't use an empty name!",
            },
          ]}
          required
        >
          <Input size="large" onChange={changeName} />
        </FormItem>
        <FormItem
          name="organization"
          label="Organization"
          extra="Register your Nominode to an existing organization."
          rules={[
            { required: true, message: 'Please select an organization!' },
          ]}
        >
          <Select size="large" onChange={changeOrganization}>
            {organizations.map(({ uuid, name }) => (
              <Option key={uuid} value={uuid}>
                {name}
              </Option>
            ))}
          </Select>
        </FormItem>
        <FormItem>
          <Button
            htmlType="submit"
            type="primary"
            size="large"
            className={classes.submitButton}
          >
            Submit
          </Button>
        </FormItem>
      </Form>
    </Card>
  );
}

const steps = [
  {
    title: 'Registering',
  },
  {
    title: 'Rebooting schedulers',
  },
  {
    title: 'Rebooting workers',
  },
];

function StepThree({ classes }) {
  const [counter, setCounter] = useState(0);
  const [finished, setFinished] = useState(false);
  const [error, setError] = useState(null);

  const loginReady = useSelector((state) => state.setup.loginReady)

  function goToLogin() {
    store.dispatch(resetReady())
  }

  function configureNominode() {
    setError(null);
    setFinished(false);

    (async () => {
        // call restarts here
        setCounter(0);
        api.resetApi();

        setCounter(1);
        api.resetScheduler();

        setCounter(2);
        await api.resetWorkers();
        setFinished(true);
        setTimeout(function(){ store.dispatch(setLoginReady()) }, 60000);
    })();
  }

  useEffect(() => {
    configureNominode();
  }, []);

  return (
    <div>
      {!finished ? (
        <div>
          <Steps current={counter}>
            {steps.map(({ title }, i) => (
              <Step
                key={title}
                title={title}
                icon={counter === i ? <LoadingOutlined /> : null}
              />
            ))}
          </Steps>

          {error && (
            <Paragraph style={{ marginTop: 10 }}>
              <Alert type="error" message={error} />
            </Paragraph>
          )}
        </div>
      ) : (
        <div>
          <Paragraph style={{ textAlign: 'center' }}>
            <Text>
              Congratulations, your Nominode will be ready in about 2 minutes!
            </Text>
          </Paragraph>

          {!loginReady ? <Spin size="large" /> :
            <Button
              type="primary"
              size="large"
              onClick={goToLogin}
              className={classes.submitButton}
            >
              Login
            </Button>
          }
          
        </div>
      )}
    </div>
  );
}
