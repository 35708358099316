import { useSelector } from 'react-redux';
import { Form, Select, Row, Col } from 'antd';

import store from '../../../../../store';
import FieldFactory from '../../../SharedField/FieldFactory';
import { selectSharedConfigs, changeParameter } from '../../sharedConfigSlice';
import { generateFieldRules } from '../../../../util/forms';

export default function SingleParameterSection(props) {
  const { parameter, state: stateParameters, locked } = props;
  const sharedConfigs = useSelector(selectSharedConfigs);

  const { name, type, display_name, description, required } = parameter;
  const value = stateParameters[name];

  let parameterField;

  // event handlers
  const onChangeParameter = (value) => {
    store.dispatch(
      changeParameter({
        parameter: name,
        value,
      }),
    );
  };

  const onChangeOption = () => {};

  const rules = generateFieldRules(parameter);

  const wrapElement = (element, initialValue = value) => (
    <Form.Item
      label={<strong>{display_name}</strong>}
      name={name}
      rules={rules}
      help={description}
      initialValue={initialValue || parameter.default || undefined}
      required={required}
    >
      {element}
    </Form.Item>
  );

  if (type == 'enum' || name == 'load_pattern') {
    const options = parameter.choices.map((choice) => (
      <Select.Option key={choice} value={choice}>
        {choice}
      </Select.Option>
    ));
    parameterField = wrapElement(
      <Select
        allowClear
        value={value}
        disabled={locked}
        onChange={onChangeParameter}
      >
        {options}
      </Select>,
    );
  } else if (type === 'shared-config' || type === 'shared_config') {
    const options = sharedConfigs.map((d) => (
      <Select.Option key={d.uuid} value={d.uuid}>
        {d.alias}
      </Select.Option>
    ));

    const onChange = (value) => {
      onChangeParameter({
        shared_config_uuid: value,
      });
    };

    parameterField = wrapElement(
      <Select
        allowClear
        value={value ? value.shared_config_uuid : value}
        disabled={locked}
        onChange={onChange}
        notFoundContent="No shared configs found"
      >
        {options}
      </Select>,
      value ? value.shared_config_uuid : value,
    );
  } else {
    parameterField = wrapElement(
      FieldFactory.create(type, {
        value,
        options: {},
        onChange: onChangeParameter,
        onOptionsChange: onChangeOption,
        disabled: locked,
      }),
    );
  }

  const md = FieldFactory.hasMetaProperty(type, 'codeElement') ? 24 : 12;

  return (
    <Row gutter={[30, 20]}>
      <Col xs={24} md={md}>
        {parameterField}
      </Col>
    </Row>
  );
}
