import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { A, navigate } from 'hookrouter';
import moment from 'moment';
import { makeStyles } from '@material-ui/core';
import { SettingOutlined, ThunderboltOutlined } from '@ant-design/icons';
import {
  Card,
  Row,
  Col,
  Button,
  Table,
  Input,
  Space,
  Tag,
  Tooltip,
} from 'antd';
import Loading from '../../../components/Loading';
import ProjectItem from '../../Project/ProjectItem';
import DescriptionItem from '../../Description/DescriptionItem';

import store from '../../../../store';
import {
  selectTasksLoading,
  selectTasks,
  loadTasks,
  executeTask,
  selectTaskExecutions,
} from '../shared/tasksSlice';
import { loadEngines, selectEngines } from '../../Engines/engineSlice';
import { selectProjectUuid } from '../../Project/projectSlice';
import logger from '../../../util/logger';

import { loadExecutionLock } from '../../Status/statusSlice.js';

const { Search } = Input;

const useStyles = makeStyles({});

const StatusGroups = {
  running: ['Queueing', 'Initializing', 'Validating', 'Routing', 'Executing'],
  new: ['New'],
  failed: ['Failure'],
  skipped: ['Skipped'],
  completed: ['Completed'],
};

export default function TaskList() {
  const styles = useStyles();
  const tasks = useSelector(selectTasks);
  const engines = useSelector(selectEngines);
  const tasksLoading = useSelector(selectTasksLoading);
  const taskExecutions = useSelector(selectTaskExecutions);
  const [filteredData, setFilteredData] = useState([]);
  const [aliasQuery, setAliasQuery] = useState('');
  const [statusFilters, setStatusFilters] = useState({
    new: false,
    running: false,
    failed: false,
    skipped: false,
    completed: false,
  });
  // { uuid: bool }
  const [favTasks, setFavTasks] = useState({});
  // { uuid: bool }

  let executionLockLoaded = useSelector(
    (state) => state.status.executionLockLoaded,
  );
  let executionLock = useSelector((state) => state.status.executionLock);
  if (!executionLockLoaded) {
    store.dispatch(loadExecutionLock());
  }

  const toggleFavorite = (uuid) => {
    const newFavTasks = { ...favTasks, [uuid]: !favTasks[uuid] };
    localStorage.setItem('favTasks', JSON.stringify(newFavTasks));
    setFavTasks(newFavTasks);
  };

  const gotoEngineSelector = () => {
    navigate('./tasks/create');
  };

  const projectUuid = useSelector(selectProjectUuid);

  const applyFilters = () => {
    let taskList = tasks.slice();
    if (aliasQuery) {
      taskList = taskList.filter((task) => {
        return task.alias.toLowerCase().includes(aliasQuery.toLowerCase());
      });
    }

    const statusList = Object.entries(statusFilters)
      .filter(([_, v]) => v)
      .map(([k]) => k);

    if (statusList.length) {
      taskList = taskList.filter((task) => {
        // filter by status
        const { last_execution } = task;
        if (last_execution) {
          return statusList.some((status) => {
            return StatusGroups[status].includes(
              last_execution.status.execution_state,
            );
          });
        }
        if (last_execution == null && statusFilters.new) {
          return true;
        }
        return false;
      });
    }

    setFilteredData(taskList);
  };

  const toggleStatusFilter = (event) => {
    const { filter } = event.currentTarget.dataset;
    setStatusFilters({
      ...statusFilters,
      [filter]: !statusFilters[filter],
    });
  };

  const buttonType = (applied) => {
    if (applied) return 'primary';
    return 'default';
  };

  const reloadTasks = async () => {
    const { payload: tasks } = await store.dispatch(loadTasks(projectUuid));
    try {
      const favTasks = JSON.parse(localStorage.getItem('favTasks'));
      const needed = Object.entries(favTasks)
        .filter(([uuid, value]) => tasks.find((task) => task.uuid === uuid))
        .reduce((fav, [uuid, value]) => {
          fav[uuid] = value;
          return fav;
        }, {});
      setFavTasks(needed);
    } catch (e) {
      // suppress
      // logger.error(e);
    }
  };

  useEffect(() => {
    applyFilters();
  }, [tasks, statusFilters, aliasQuery]);

  useEffect(() => {
    // load the engines
    store.dispatch(loadEngines());
  }, []);

  useEffect(() => {
    reloadTasks();
  }, [projectUuid]);

  if (!engines) return <Loading />;

  const tableColumns = getColumns({
    styles,
    engines,
    favTasks,
    taskExecutions,
    executionLock,
    toggleFavorite,
  });

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Card>
        <Row justify="space-between" align="middle">
          <Col>
            <DescriptionItem
              title="Tasks"
              description="Create and manage tasks"
            />
          </Col>
          <Col>
            <Button type="primary" onClick={gotoEngineSelector}>
              Create Task
            </Button>
          </Col>
        </Row>
        <ProjectItem />
      </Card>

      <Card size="small">
        <Row gutter={[10, 10]}>
          <Col>
            <Space>
              <strong>Name:</strong>
              <Search
                allowClear
                style={{ maxWidth: 300 }}
                placeholder="Search by Task name"
                onChange={({ target }) => setAliasQuery(target.value)}
              />
            </Space>
          </Col>
          <Col>
            <Space>
              <strong>Task Status:</strong>
              <Button.Group>
                <Button
                  data-filter="new"
                  type={buttonType(statusFilters.new)}
                  onClick={toggleStatusFilter}
                >
                  New
                </Button>
                <Button
                  data-filter="running"
                  type={buttonType(statusFilters.running)}
                  onClick={toggleStatusFilter}
                >
                  Running
                </Button>
                <Button
                  data-filter="failed"
                  type={buttonType(statusFilters.failed)}
                  onClick={toggleStatusFilter}
                >
                  Failed
                </Button>
                <Button
                  data-filter="skipped"
                  type={buttonType(statusFilters.skipped)}
                  onClick={toggleStatusFilter}
                >
                  Skipped
                </Button>
                <Button
                  data-filter="completed"
                  type={buttonType(statusFilters.completed)}
                  onClick={toggleStatusFilter}
                >
                  Completed
                </Button>
              </Button.Group>
            </Space>
          </Col>
        </Row>
      </Card>

      <Card size="small">
        <Table
          expandable
          rowKey="uuid"
          columns={tableColumns}
          dataSource={filteredData}
          pagination={false}
          loading={tasksLoading}
        />
      </Card>
    </Space>
  );
}

function getColumns({ styles, engines, taskExecutions, executionLock }) {
  const execute = async (record) => {
    if (executionLock) return;
    const { error, payload: execution } = await store.dispatch(
      executeTask(record.uuid),
    );
    if (error) {
      logger.debug(`Failed to execute task: ${record.uuid}.`);
    } else {
      logger.debug(`Executing task: ${record.uuid}.`);
      navigate(`./executions/${execution.uuid}`);
    }
  };

  const getEngine = (uuid) => {
    let model = null;
    engines.find((engine) => {
      const m = engine.models.find((model) => model.uuid === uuid);
      if (m) model = m;
      return !!m;
    });
    return model;
  };

  return [
    {
      title: 'Last Run',
      key: 'status',
      dataIndex: ['last_execution', 'status', 'execution_state'],
      render: (status, record) => {
        if (record.children) {
          return null;
        }
        switch (status) {
          case 'Queueing':
          case 'Initializing':
          case 'Validating':
          case 'Routing':
          case 'Executing':
            return (
              <Tag color="cyan">
                <SettingOutlined spin />
                &nbsp;{status}
              </Tag>
            );
          case 'Completed':
            return <Tag color="green">Success</Tag>;
          case 'Failure':
            return <Tag color="red">Failed</Tag>;
          default:
            return <Tag>{status}</Tag>;
        }
      },
    },
    {
      title: 'Task Name',
      dataIndex: 'alias',
      key: 'alias',
      sorter: (a, b) => {
        if (a.alias < b.alias) {
          return -1;
        }
        if (a.alias > b.alias) {
          return 1;
        }
        return 0;
      },
      render: (name, record) => {
        if (record.children) {
          return record.alias;
        }
        return (
          <A href={record.project_uuid + '/tasks/' + record.uuid}>
            {name ? name.toString() : ''}
          </A> 
        );
      },
    },
    {
      title: 'Engine Type',
      dataIndex: 'engine_alias',
      key: 'engine_alias',
      filters: engines.map(({ uuid, models }) => ({
        text: models[0].alias,
        value: models[0].uuid,
      })),
      onFilter: (value, record) => {
        return (record.engine_uuid || false) === value;
      },
      render: (alias, record) => {
        if (record.children) {
          return null;
        }

        const eng = getEngine(record.engine_uuid);
        const toolTipMessage = eng
          ? eng.description
          : 'Engine Information Unavailable';

        return (
          <Tooltip placement="right" title={toolTipMessage}>
            <Button htmlType="button" type="dashed">
              {alias}
            </Button>
          </Tooltip>
        );
      },
    },
    {
      title: 'Last Run Date',
      dataIndex: ['last_execution', 'created'],
      render: (created, record) => {
        if (record.children) {
          return null;
        }
        const createdUtc = moment.utc(created);
        const createdFrom = createdUtc.fromNow();
        return created
          ? `${createdUtc.toDate().toUTCString()} (${createdFrom})`
          : 'Never';
      },
      sorter: (a, b) => {
        if (a.last_execution && b.last_execution) {
          return (
            new Date(a.last_execution.created) -
            new Date(b.last_execution.created)
          );
        }
        if (a.last_execution) {
          return 1;
        }
        return -1;
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Executions',
      dataIndex: ['last_execution', 'uuid'],
      render: (uuid, record) => {
        if (uuid) {
          return (
            <A href={record.project_uuid + '/executions/tasks/' + record.uuid}>
              View
            </A> 
          );
        } else {
          return '';
        }
      },
    },
    {
      title: 'Execute',
      dataIndex: 'uuid',
      key: 'uuid',
      render: (uuid, record) => {
        if (record.children) {
          return null;
        }
        return (
          <Tooltip
            title={executionLock ? 'Executions are locked' : 'Click to execute'}
          >
            <Button
              key={uuid}
              shape="circle"
              type={executionLock ? '' : 'primary'}
              htmlType="button"
              icon={<ThunderboltOutlined />}
              className={styles.executeBtn}
              loading={taskExecutions[uuid]}
              disabled={taskExecutions[uuid]}
              onClick={() => execute(record)}
            />
          </Tooltip>
        );
      },
    },
  ];
}
