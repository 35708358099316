import { Collapse } from 'antd';
import ParameterSection from '../renderers/ParameterSection';

const { Panel } = Collapse;

export default function ParametersTab(props) {
  const { parameters, state, sharedConfigs, locked, form } = props;

  const panels = parameters.map((parameter) => {
    if (parameter.type === 'group')
      return (
        <ParameterSection
          key={parameter.name}
          section={parameter}
          state={state}
          sharedConfigs={sharedConfigs}
          locked={locked}
          form={form}
        />
      );
    else return null; // to handle this?
  });

  return (
    <>
      {panels.map((panel, index) => (
        <Collapse key={index} defaultActiveKey={1} style={{ marginBottom: 10 }}>
          <Panel header={parameters[index].display_name} key={1}>
            {panel}
          </Panel>
        </Collapse>
      ))}
    </>
  );
}
