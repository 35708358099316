import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { A } from 'hookrouter';
import moment from 'moment';

import { Card, Input, Space, Table, Tag, Tooltip, Switch, Button, notification } from 'antd';
import { SettingOutlined, ThunderboltOutlined } from '@ant-design/icons';

import store from '../../../store.js';
import {
  loadSequences,
  patchSequence,
  runSequence,
  selectSequenceList,
  selectSequences,
} from './sequencesSlice';

import {
  loadExecutionLock,
} from '../Status/statusSlice.js';

import { selectProjectUuid } from '../Project/projectSlice';

import { compareStrings } from '../../util/strings';


const { Search } = Input;

export default function SequencesTable(props) {
  const projectUuid = useSelector(selectProjectUuid);
  useEffect(() => {
    store.dispatch(loadSequences(projectUuid));
  }, [projectUuid]);

  const sequences = useSelector(selectSequences);
  const sequencesLoaded = useSelector(
    (state) => state.sequences.sequencesLoaded,
  );
  const { patching: isPatching, running: isRunning } =
    useSelector(selectSequenceList);
  const [filteredData, setFilteredData] = useState(sequences);

  // SEQUENCE NOTIFICATIONS HERE
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (placement) => {
    api.info({
      message: `Sequence Successfully Started`,
      placement,
      duration: 10,
    });
  };
  // END SEQUENCE NOTIFICATIONS

  let executionLockLoaded = useSelector(
    (state) => state.status.executionLockLoaded,
  );
  let executionLock = useSelector((state) => state.status.executionLock);
  if(!executionLockLoaded) {
    store.dispatch(loadExecutionLock())
  }
  

  const searchSequences = (query) => {
    query = query.toLowerCase();
    if (!query) setFilteredData(sequences);
    else
      setFilteredData(
        sequences.filter((data) => {
          return data.alias.toLowerCase().includes(query);
        }),
      );
  };

  const handlePatchSequence = (sequenceID, patch) => {
    store.dispatch(patchSequence({ sequenceID, patch }));
  };

  const handleRunSequence = (sequenceID) => {
    if(!executionLock) {
      openNotification('bottomRight')
      store.dispatch(runSequence(sequenceID));
    }
  };

  const getColumns = ({
    isRunning,
    isPatching,
    handlePatchSequence,
    handleRunSequence,
  }) => [
    {
      title: 'Last Run',
      dataIndex: ['last_execution', 'status'],
      render: (status, record) => {
        if (record.children) {
          return null;
        }
        switch (status) {
          case 'running':
            return (
              <Tag color="cyan">
                <SettingOutlined spin />
                &nbsp;Running
              </Tag>
            );
          case 'complete':
            return <Tag color="green">Success</Tag>;
          case 'Error':
            return <Tag color="red">Error</Tag>;
          default:
            return <Tag>{status}</Tag>;
        }
      },
    },
    {
      title: 'Name',
      dataIndex: 'alias',
      filtered: true,
      render: (alias, record) => {
        if (record.children) {
          return alias;
        }
        return <A href={'/'+projectUuid+`/sequences/edit/${record.uuid}`}>{alias}</A>;
      },
      sorter: (a, b) => compareStrings(a.alias, b.alias),
    },
    {
      title: 'Schedule',
      dataIndex: 'schedule',
    },
    {
      title: 'Last Run Date',
      dataIndex: ['last_execution', 'created'],
      render: (created, record) => {
        if (record.children) {
          return null;
        }
        return created
          ? `${new Date(created).toUTCString()} (${moment
              .utc(created)
              .fromNow()})`
          : 'Never';
      },
    },
    {
      title: 'Enabled',
      dataIndex: 'enabled',
      filters: [
        { text: 'Enabled', value: true },
        { text: 'Disabled', value: false },
      ],
      onFilter: (value, record) => value === record.enabled,
      render: (enabled, record) => {
        if (record.children) {
          return null;
        }
        return (
          <Switch
            loading={isPatching}
            onChange={(checked) =>
              handlePatchSequence(record.uuid, {
                enabled: checked,
              })
            }
            defaultChecked={enabled}
          />
        );
      },
    },
    {
      title: 'Execute',
      dataIndex: 'uuid',
      render: (uuid, record) => {
        if (record.children) {
          return null;
        }
        return (
          <Tooltip title={executionLock ? "Executions are locked" : "Click to execute"}>
            <Button
              icon={<ThunderboltOutlined />}
              htmlType="button"
              shape="circle"
              type={executionLock ? "" : 'primary'}
              disabled={isRunning}
              loading={isRunning}
              onClick={() => handleRunSequence(uuid)}
            />
          </Tooltip>
        );
      },
    },
  ];

  const tableColumns = getColumns({
    isRunning,
    isPatching,
    handlePatchSequence,
    handleRunSequence,
  });

  useEffect(() => {
    setFilteredData(sequences);
  }, [sequences.length]);

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      {contextHolder}
      <Card size="small">
        <Search
          allowClear
          style={{ maxWidth: 300 }}
          placeholder="Search by sequence name"
          onChange={({ target }) => searchSequences(target.value)}
          onSearch={(value) => searchSequences(value)}
        />
      </Card>

      <Card size="small">
        <Table
          expandable
          rowKey="uuid"
          columns={tableColumns}
          dataSource={filteredData}
          loading={!sequencesLoaded}
        />
      </Card>
    </Space>
  );
}
