import { Card, Row, Col, Spin } from 'antd';

export default function Loading() {
  return (
    <Card>
      <Row
        justify="center"
        align="middle"
        style={{ width: '100%', minHeight: 200 }}
      >
        <Col>
          <Spin size="large" />
        </Col>
      </Row>
    </Card>
  );
}
