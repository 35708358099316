import type { CSSProperties } from 'react';

const prismColors = {
  char: '#D8DEE9',
  comment: '#B2B2B2',
  keyword: '#C5A5C5',
  lineHighlight: '#353B45',
  primitive: '#5A9BCF',
  string: '#8DC891',
  variable: '#D7DEEA',
  boolean: '#FF8B50',
  punctuation: '#88C6BE',
  tag: '#FC929E',
  function: '#79B6F2',
  className: '#FAC863',
  method: '#6699CC',
  operator: '#FC929E',
};

const theme: { [key: string]: CSSProperties } = {
  'code[class*="language-"]': {
    MozTabSize: '1.5em',
    OTabSize: '1.5em',
    tabSize: '1.5em',
    WebkitHyphens: 'none',
    MozHyphens: 'none',
    msHyphens: 'none',
    hyphens: 'none',
    whiteSpace: 'pre',
    wordWrap: 'normal',
    fontFamily: 'source-code-pro,Menlo,Monaco,Consolas,Courier New,monospace',
    fontSize: '14px',
    color: '#ffffff',
    textShadow: 'none',
  },
  'pre[class*="language-"]': {
    MozTabSize: '1.5em',
    OTabSize: '1.5em',
    tabSize: '1.5em',
    WebkitHyphens: 'none',
    MozHyphens: 'none',
    msHyphens: 'none',
    wordBreak: 'normal',
    wordSpacing: 'normal',
    hyphens: 'none',
    whiteSpace: 'pre',
    wordWrap: 'normal',
    fontFamily: 'source-code-pro,Menlo,Monaco,Consolas,Courier New,monospace',
    fontSize: '14px',
    color: '#ffffff',
    textShadow: 'none',
    background: '#282c34',
    padding: '15px',
    borderRadius: '4px',
    border: '1px solid #e1e1e8',
    overflow: 'auto',
    position: 'relative',
  },
  ':not(pre)>code[class*="language-"]': {
    background: '#282c34',
    padding: '0.15em 0.2em 0.05em',
    borderRadius: '10px',
    border: '0.13em solid #7a6652',
    boxShadow: '1px 1px 0.3em -0.1em #000 inset',
  },
  'pre[class*="language-"] code': {
    whiteSpace: 'pre',
    display: 'block',
  },
  namespace: {
    Opacity: '.7',
  },
  comment: {
    color: prismColors.comment,
  },
  prolog: {
    color: prismColors.comment,
  },
  doctype: {
    color: prismColors.comment,
  },
  cdata: {
    color: prismColors.comment,
  },
  operator: {
    color: '#d7deea',
  },
  boolean: {
    color: '#ff8b50',
  },
  number: {
    color: '#5a9bcf',
  },
  'attr-name': {
    color: prismColors.keyword,
  },
  string: {
    color: '#8dc891',
  },
  entity: {
    color: '#d7deea',
    cursor: 'help',
  },
  url: {
    color: '#d7deea',
  },
  '.language-css .token.string': {
    color: '#8dc891',
  },
  '.style .token.string': {
    color: '#8dc891',
  },
  selector: {
    color: prismColors.char,
  },
  inserted: {
    color: prismColors.char,
  },
  char: {
    color: prismColors.char,
  },
  atrule: {
    color: prismColors.className,
  },
  'attr-value': {
    color: '#8dc891',
  },
  keyword: {
    color: prismColors.keyword,
  },
  important: {
    color: '#ef3b7d',
    fontWeight: '400',
  },
  deleted: {
    color: '#5a9bcf',
  },
  regex: {
    color: '#61dafb',
  },
  statement: {
    color: '#61dafb',
    fontWeight: 'bold',
  },
  placeholder: {
    color: '#d7deea',
  },
  variable: {
    color: '#d7deea',
  },
  bold: {
    fontWeight: '700',
  },
  builtin: {
    color: prismColors.char,
  },
  punctuation: {
    color: '#88c6be',
  },
  italic: {
    fontStyle: 'italic',
  },
  'function-name': {
    color: '#5a9bcf',
  },
  function: {
    color: '#79b6f2',
  },
  'class-name': {
    color: '#fac863',
  },
  tag: {
    color: '#fc929e',
  },
  'code.language-markup': {
    color: '#f9f9f9',
  },
  'code.language-markup .token.tag': {
    color: '#fc929e',
  },
  'code.language-markup .token.attr-name': {
    color: prismColors.keyword,
  },
  'code.language-markup .token.attr-value': {
    color: '#8dc891',
  },
  'code.language-markup .token.style': {
    color: '#61dafb',
  },
  'code.language-markup .token.script': {
    color: '#61dafb',
  },
  'code.language-markup .token.script .token.keyword': {
    color: '#61dafb',
  },
  'pre[class*="language-"][data-line]': {
    position: 'relative',
    padding: '1em 0 1em 3em',
  },
  'pre[data-line] .line-highlight': {
    position: 'absolute',
    left: '0',
    right: '0',
    padding: '0.125rem calc(1rem + 15px)',
    margin: '-0.125rem calc(-1rem - 15px)',
    background: prismColors.lineHighlight,
    pointerEvents: 'none',
    lineHeight: 'inherit',
    whiteSpace: 'pre',
  },
  'pre[data-line] .line-highlight:before': {
    content: 'attr(data-start)',
    position: 'absolute',
    top: '.4em',
    left: '.6em',
    minWidth: '1em',
    padding: '0.2em 0.5em',
    backgroundColor: 'rgba(255, 255, 255, 0.4)',
    color: 'black',
    font: 'bold 65%/1 sans-serif',
    height: '1em',
    lineHeight: '1em',
    textAlign: 'center',
    borderRadius: '999px',
    textShadow: 'none',
    boxShadow: '0 1px 1px rgba(255, 255, 255, 0.7)',
  },
  'pre[data-line] .line-highlight[data-end]:after': {
    content: 'attr(data-end)',
    position: 'absolute',
    top: 'auto',
    left: '.6em',
    minWidth: '1em',
    padding: '0.2em 0.5em',
    backgroundColor: 'rgba(255, 255, 255, 0.4)',
    color: 'black',
    font: 'bold 65%/1 sans-serif',
    height: '1em',
    lineHeight: '1em',
    textAlign: 'center',
    borderRadius: '999px',
    textShadow: 'none',
    boxShadow: '0 1px 1px rgba(255, 255, 255, 0.7)',
    bottom: '.4em',
  },
};

export default theme;
