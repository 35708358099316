import { Button } from 'antd';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';

import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';

// core components
import AdminNavbarLinks from './AdminNavbarLinks.js';

import styles from '../../assets/jss/material-dashboard-react/components/headerStyle.js';

const useStyles = makeStyles(styles);

export default function Header(props) {
  const { toggleSidebar, sidebarCollapsed } = props;

  const classes = useStyles();
  return (
    <AppBar className={classes.appBar}>
      <Toolbar className={classes.container}>
        <Button
          type="text"
          onClick={toggleSidebar}
          aria-label="open drawer"
          icon={
            sidebarCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />
          }
        ></Button>

        <div className={classes.flex}></div>
        <Hidden smDown implementation="css">
          <AdminNavbarLinks />
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}
