import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';

import * as Diff from 'diff';

import { Select, Switch, Row, Col } from 'antd';

import { Typography } from 'antd';

const { Text } = Typography;
const { Option } = Select;

import store from '../../../store.js';

import {
  successColor,
  grayColor,
  blackColor,
} from '../../../UI/assets/jss/material-dashboard-react';

import { loadHistory, resetHistory } from './historySlice';

const defaultStyles = {
  display: 'flex',
  alignItems: 'center',
  padding: '0px 12px',
  margin: 0,
  width: 'calc(100% - 50px)',
  fontWeight: 'normal',
  '& span': {
    width: 10,
  },
  '& pre': {
    margin: 0,
    padding: 0,
    lineHeight: '1.2rem',
    overflow: 'hidden',
  },
};

const useStyles = makeStyles({
  addition: {
    ...defaultStyles,
    backgroundColor: '#dafbe1',
    '&::before': {
      content: '+',
    },
  },
  deletion: {
    ...defaultStyles,
    backgroundColor: '#ffebe9',
    '&::before': {
      content: '-',
    },
  },
  unchanged: {
    ...defaultStyles,
  },
  lineNumber: {
    textAlign: 'right',
    width: 50,
    fontSize: '.8rem',
    lineHeight: '1.2rem',
    padding: '4px 6px',
    color: grayColor[2],
    fontWeight: 'normal',
  },
  versionSelect: {
    fontWeight: 'normal',
    width: '100%',
  },
});

export default function TasksPage(props) {
  useEffect(() => {
    return () => {
      store.dispatch(resetHistory())
    }
  }, []);

  const classes = useStyles();
  const historyLoaded = useSelector(
    (state) => state.history.historyLoaded,
  );
  const history = useSelector(
    (state) => state.history.history,
  );
  const [versionA, setVersionA] = useState(0);
  const [versionB, setVersionB] = useState(0);
  const [limit, setLimit] = useState(0);
  const [diffRenderData, setDiffRenderData] = useState(null);
  const [inlineRender, setInlineRender] = useState(false);

  const toggleInline = (checked) => setInlineRender(checked);

  const renderDiffPart = ({ lineNumber, line }) => (
    <div style={{ display: 'flex' }}>
      <div
        className={lineNumber.className}
        style={{ backgroundColor: lineNumber.backgroundColor }}
      >
        {lineNumber.value}
      </div>
      <line.Component className={line.className} text={line.content} />
    </div>
  );

  const renderDiffPartInline = ({ left, right }) => {
    const lineNumber = (part, showValue = true) => (
      <div
        className={part.lineNumber.className}
        style={{ backgroundColor: part.lineNumber.backgroundColor }}
      >
        {showValue ? part.lineNumber.value : null}
      </div>
    );
    const component = (part) => (
      <part.line.Component
        className={part.line.className}
        text={part.line.content}
      />
    );
    return left && right && left.type === right.type ? (
      <div style={{ display: 'flex' }}>
        {lineNumber(left)}
        {lineNumber(right)}
        {component(left)}
      </div>
    ) : left && left.type === 'deletion' ? (
      [
        <div style={{ display: 'flex' }}>
          {lineNumber(left)}
          {lineNumber(left, false)}
          {component(left)}
        </div>,
        <div style={{ display: 'flex' }}>
          {lineNumber(right)}
          {lineNumber(right, false)}
          {component(right)}
        </div>,
      ]
    ) : right && right.type === 'addition' ? (
      <div style={{ display: 'flex' }}>
        {lineNumber(right)}
        {lineNumber(right, false)}
        {component(right)}
      </div>
    ) : null;
  };

  useEffect(() => {
    if (!history || !history.length) return;
    setVersionA(history.length);
    setVersionB(history.length);
    setLimit(history.length);
  }, [history]);

  useEffect(() => {
    if (versionA < 1 || versionB < 1) return;
    const jsonA = history[versionA - 1];
    const jsonB = history[versionB - 1];
    const data = generateDiffElements(jsonA, jsonB, classes);
    setDiffRenderData(data);
  }, [versionA, versionB]);

  useEffect(() => {
    if (!historyLoaded) {
        store.dispatch(loadHistory(props.props));
    }
  }, [historyLoaded]);

  if (!historyLoaded) return null;

  const versions = history.map((_, i) => i + 1).reverse();

  let versionASelector = versions.map((_, i) => ({
    label: (
      <div>
        <span>{`Version ${i + 1}: ${new Date(history[i].updated).toDateString()}`}</span>
        <span style={{ color: successColor[2] }}>
          {i === versions.length - 1 ? ' (Current)' : ''}
        </span>
      </div>
    ),
    value: i + 1,
  }))

  return (
    <div>
      <div style={{ marginBottom: '1rem' }}>
        <span style={{ fontWeight: 'normal', marginRight: '0.2rem' }}>
          Toggle inline:
        </span>{' '}
        <Switch checked={inlineRender} onClick={toggleInline} />
      </div>
      <Row justify="space-between" style={{ marginBottom: '1.5rem' }}>
        <Col xs={12} md={7}>
          <Select
            size="middle"
            className={classes.versionSelect}
            value={versionA}
            options={versionASelector}
            onChange={(v) => {
              setLimit(v)
              setVersionA(v)
              if (v > versionB) {
                setVersionB(v)
              }
            }}
          />
        </Col>
        <Col xs={12} md={7}>
          <Select
            size="middle"
            className={classes.versionSelect}
            value={versionB}
            options={versionASelector.slice(limit-1, history.length)}
            onChange={(v) => {setVersionB(v)}}
          />
        </Col>
      </Row>

      <div style={{ minWidth: '20rem' }}>
        <Row
          style={{
            background: '#fafbfc',
            color: blackColor,
            fontWeight: 'normal',
            borderBottom: '1px solid #ddd',
            display: inlineRender ? 'none' : undefined,
          }}
        >
          <Col span={12} style={{ padding: '8px 12px' }}>
            Version {versionA} <span className="diff-author">Author: Name</span>
          </Col>
          <Col
            span={12}
            style={{ padding: '8px 12px', borderLeft: '1px solid #ddd' }}
          >
            Version {versionB} <span className="diff-author">Author: Name</span>
          </Col>
        </Row>

        {!inlineRender ? (
          <div>
            {diffRenderData?.map(({ left, right }, idx) => (
              <Row key={idx}>
                <Col span={12}>{left ? renderDiffPart(left) : null}</Col>
                <Col span={12}>{right ? renderDiffPart(right) : null}</Col>
              </Row>
            ))}
          </div>
        ) : (
          <div>
            {diffRenderData
              ?.map(renderDiffPartInline)
              .flat()
              .map((element, idx) => (
                <Row key={idx}>
                  <Col span={24}>{element}</Col>
                </Row>
              ))}
          </div>
        )}
      </div>
    </div>
  );
}

function AddText(props) {
  return (
    <div className={props.className}>
      <span>+</span>
      <pre>{props.text}</pre>
    </div>
  );
}

function DeleteText(props) {
  return (
    <div className={props.className}>
      <span>-</span>
      <pre>{props.text}</pre>
    </div>
  );
}

function UnchangedText(props) {
  return (
    <div className={props.className}>
      <span></span>
      <pre>{props.text}</pre>
    </div>
  );
}

/**
 * Splits a block of text into lines
 * @param {string} block the block of the text
 * @param {string} type unchanged, addition or deletion
 * @returns lines of text
 */
const splitBlockIntoLines = (block, type) =>
  block
    .trimEnd('\n') // remove newline at the end of text
    .split('\n')
    .map((line) => ({ type, line }));

/**
 * Generates markup for a line of text
 * @param {object} options
 * @param {string} options.type unchanged, addition, deletion
 * @param {string} options.line the line of text
 * @param {number} lineNumber line number for this line of text
 * @returns {JSX.Element}
 */
const generateLineRowElement = ({ type, line }, lineNumber, classes) => {
  let lineNumberBackground, textClassName, Component;

  switch (type) {
    case 'addition':
      lineNumberBackground = '#bff0ca';
      Component = AddText;
      textClassName = classes.addition;
      break;
    case 'deletion':
      lineNumberBackground = '#ffd7d5';
      Component = DeleteText;
      textClassName = classes.deletion;
      break;
    case 'unchanged':
    default:
      lineNumberBackground = '#f7f7f7';
      Component = UnchangedText;
      textClassName = classes.unchanged;
      break;
  }

  return {
    type,
    lineNumber: {
      value: lineNumber,
      className: classes.lineNumber,
      backgroundColor: lineNumberBackground,
    },
    line: {
      content: line,
      className: textClassName,
      Component,
    },
  };
};

/**
 * Generates rows for the json diff. Rows span the full width
 * @param {object[]} leftLines the lines of text (transformed using `splitBlockIntoLines`)
 * @param {object[]} rightLines the lines of text (transformed using `splitBlockIntoLines`)
 * @param {number} leftLineNumber
 * @param {number} rightLineNumber
 * @returns {JSX.Element}
 */
const generateRows = (
  leftLines,
  rightLines,
  leftLineNumber,
  rightLineNumber,
  classes,
) => {
  const max = Math.max(leftLines.length, rightLines.length);
  let left, right;
  const res = [];
  for (let i = 0; i < max; i++) {
    left = leftLines[i]
      ? generateLineRowElement(leftLines[i], leftLineNumber + i, classes)
      : null;
    right = rightLines[i]
      ? generateLineRowElement(rightLines[i], rightLineNumber + i, classes)
      : null;

    // res.push(
    //   <Row key={++generateRows.uid_}>
    //     <Col span={12}>{left}</Col>
    //     <Col span={12}>{right}</Col>
    //   </Row>,
    // );
    res.push({ left, right });
  }

  return res;
};

generateRows.uid_ = 0;

const generateDiffElements = (jsonA, jsonB, classes) => {
  const result = [];
  let diff = Diff.diffJson(jsonA, jsonB);
  let lines, part, other, otherLines;
  let leftLineNumber = 1;
  let rightLineNumber = 1;

  while (diff.length) {
    // green for additions, red for deletions
    // grey for common parts
    part = diff.shift();

    if (part.removed) {
      lines = splitBlockIntoLines(part.value, 'deletion');
      if (diff.length && diff[0].added) {
        other = diff.shift();
        otherLines = splitBlockIntoLines(other.value, 'addition');
        result.push(
          ...generateRows(
            lines,
            otherLines,
            leftLineNumber,
            rightLineNumber,
            classes,
          ),
        );
        rightLineNumber += otherLines.length;
      }
      leftLineNumber += lines.length;
    } else if (part.added) {
      lines = splitBlockIntoLines(part.value, 'addition');
      result.push(
        ...generateRows([], lines, leftLineNumber, rightLineNumber, classes),
      );
      rightLineNumber += lines.length;
    } else {
      lines = splitBlockIntoLines(part.value, 'unchanged');
      result.push(
        ...generateRows(lines, lines, leftLineNumber, rightLineNumber, classes),
      );
      leftLineNumber += lines.length;
      rightLineNumber += lines.length;
    }
  }

  return result;
};
