import { useSelector } from 'react-redux'

import { loadUsers } from './permissionSlice.js'
import store from '../../../store.js'

import { Table, Tag } from 'antd';

export default function Users() {
    let usersloaded = useSelector((state) => state.permissions.usersloaded)
    let users = useSelector((state) => state.permissions.users)
    if(!usersloaded) {
        store.dispatch(loadUsers())
    }

    let dataSource = []

    users.forEach(user => {
        let tags = []
        user.groups.forEach(group => {
            tags.push(<Tag key={group.uuid}>{group.name}</Tag>)
        })
        dataSource.push({uuid: user.uuid, name: user.user_name, groups: tags})
    })

    const columns = [
        {
          title: 'User Name',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: 'Groups',
          dataIndex: 'groups',
        },
    ];

    return  <div><Table rowKey="uuid" dataSource={dataSource} columns={columns} pagination={false} /></div>
}