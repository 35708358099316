import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Space,
  Card,
  Button,
  Tooltip,
  Descriptions,
  Progress,
  Collapse,
  notification,
  Spin,
  Alert,
} from 'antd';
import {
  CloseCircleOutlined,
  CopyOutlined,
  RedoOutlined,
  ReloadOutlined,
  StopOutlined,
} from '@ant-design/icons';
import { makeStyles } from '@material-ui/core';
import { PrismAsyncLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import json from 'react-syntax-highlighter/dist/cjs/languages/prism/json';
import { A, navigate } from 'hookrouter';
import { selectProjectAlias, selectProjectUuid } from '../Project/projectSlice';
import DescriptionItem from '../Description/DescriptionItem';
import Loading from '../../components/Loading';

import prism from '../../util/jsonHighlighter';
import {
  successColor,
  dangerColor,
} from '../../assets/jss/material-dashboard-react';
import ErrorBoundary from '../../components/ErrorBoundary';

import store from '../../../store';
import {
  executeTask,
  fetchExecutionDetail,
  fetchExecutionStatsPlot,
  killExecution,
  selectExecutionDetail,
  selectExecutionStatsPlot,
} from './executionSlice';
import Plot from '../../components/Plot';
import ExecutionLogs from './ExecutionLogs';
import toast from '../../util/toasts';

SyntaxHighlighter.registerLanguage('json', json);

const useStyles = makeStyles({
  copyButton: {
    position: 'absolute',
    top: 15,
    right: 15,
  },
  copySuccess: {
    maxWidth: '100%',
    width: 'fit-content',
    fontWeight: 'normal',
    background: successColor[2],
    '& *': {
      color: '#fff',
    },
  },
  copyFailure: {
    maxWidth: '100%',
    width: 'fit-content',
    fontWeight: 'normal',
    background: dangerColor[2],
    '& *': {
      color: '#fff',
    },
  },
});

export default function ExecutionDetails({ props }) {
  const { executionID } = props;
  const classes = useStyles();
  const projectUuid = useSelector(selectProjectUuid);
  const projectAlias = useSelector(selectProjectAlias);
  const executionStatsPlot = useSelector(selectExecutionStatsPlot);
  const { execution, loading, error, killing, killingError } = useSelector(
    selectExecutionDetail,
  );

  const [rerunning, setRerunning] = useState(false);

  const displayMemoryPanel = true;

  const copyExecutionParameters = () => {
    const payload = JSON.stringify(execution.parameters, null, 4);
    navigator.clipboard
      .writeText(payload)
      .then(() => {
        toast.success('Copied to clipboard.', <CopyOutlined />);
      })
      .catch(() => {
        toast.error('Error copying to clipboard.');
      });
  };

  const rerunTask = async () => {
    setRerunning(true);
    const { payload, error } = await store.dispatch(
      executeTask(execution.task.uuid),
    );
    setRerunning(false);
    if (error) {
      toast.error('Failed to re-run task.');
    } else {
      const { execution } = payload;
      navigate('/' + projectUuid + `/executions/${execution.uuid}`);
    }
  };

  const kill = async () => {
    try {
      await store.dispatch(killExecution(executionID));
      await store.dispatch(fetchExecutionDetail(executionID));
    } catch (err) {
      toast.error('Failed to kill task execution.');
    }
  };

  const refreshExecution = () => {
    store.dispatch(fetchExecutionDetail(executionID));
  };

  const isRunning =
    execution &&
    !['Killed', 'Completed', 'Failure', 'Skipped'].includes(
      execution.status.execution_state,
    );

  useEffect(() => {
    store.dispatch(fetchExecutionDetail(executionID));
  }, [executionID]);

  return (
    <Space direction="vertical" size="middle" style={{ width: '100%' }}>
      <Card>
        <Space direction="vertical" size="middle" style={{ width: '100%' }}>
          <DescriptionItem
            title="Execution Details"
            description="Logs and parameter details of execution"
          />

          <div>
            <strong style={{ fontWeight: 500 }}>Current Project:</strong>{' '}
            {projectAlias}
          </div>
        </Space>
      </Card>

      {loading ? (
        <Loading />
      ) : error ? (
        <Alert type="error" message={error} />
      ) : execution ? (
        <Card
          title={
            <A href={'/' + projectUuid + `/tasks/${execution.task.uuid}`}>
              {execution.task.alias}
            </A>
          }
          extra={
            <Button.Group>
              <Button
                icon={<RedoOutlined />}
                loading={rerunning}
                onClick={rerunTask}
              >
                Re-run
              </Button>
              <Tooltip title="Kill execution">
                <Button
                  type="primary"
                  danger
                  icon={<StopOutlined />}
                  disabled={!isRunning || killing}
                  onClick={kill}
                >
                  Kill
                </Button>
              </Tooltip>
              <Tooltip title="Refresh execution status">
                <Button
                  type="primary"
                  icon={<ReloadOutlined />}
                  onClick={refreshExecution}
                ></Button>
              </Tooltip>
            </Button.Group>
          }
          bodyStyle={{ padding: 0 }}
        >
          <Descriptions bordered>
            <Descriptions.Item label="Progress">
              <Progress
                percent={execution.progress}
                status={(() => {
                  switch (execution.status.execution_state) {
                    case 'Completed':
                      return 'success';
                    case 'Killed':
                    case 'Failure':
                      return 'exception';
                    default:
                      return 'active';
                  }
                })()}
                style={{ width: 70 }}
              />
            </Descriptions.Item>
            <Descriptions.Item label="Started">
              {new Date(execution.created).toLocaleString()}
            </Descriptions.Item>
            <Descriptions.Item label="Last Update">
              {new Date(execution.updated).toLocaleString()}
            </Descriptions.Item>
            <Descriptions.Item label="Status">
              {execution.status.execution_state}
            </Descriptions.Item>
          </Descriptions>

          <Collapse
            bordered={false}
            onChange={(openArray) => {
              if (openArray.includes('statsPlot')) {
                store.dispatch(fetchExecutionStatsPlot(executionID));
              }
            }}
          >
            <Collapse.Panel key="message" header="Message">
              {execution.message}
            </Collapse.Panel>
            <Collapse.Panel key="parameters" header="Parameters">
              <div style={{ position: 'relative' }}>
                <SyntaxHighlighter
                  wrapLines
                  style={prism}
                  showLineNumbers={false}
                  lineProps={() => ({
                    style: {
                      display: 'block',
                      cursor: 'pointer',
                    },
                  })}
                  language="json"
                >
                  {JSON.stringify(execution.parameters, null, 4)}
                </SyntaxHighlighter>
                <Button
                  htmlType="button"
                  className={classes.copyButton}
                  onClick={copyExecutionParameters}
                  icon={<CopyOutlined />}
                  ghost
                >
                  Copy
                </Button>
              </div>
            </Collapse.Panel>
            {displayMemoryPanel ? 
              <Collapse.Panel
                key="statsPlot"
                forceRender={false}
                header="CPU & Memory"
              >
                {executionStatsPlot.isFetching && <Spin />}
                {executionStatsPlot.plot && (
                  <ErrorBoundary>
                    <Plot
                      plotData={executionStatsPlot.plot.result}
                      plotType={executionStatsPlot.plot.result_type}
                    />
                  </ErrorBoundary>
                )}
                {!executionStatsPlot.plot && !executionStatsPlot.isFetching && (
                  <Alert
                    type="warning"
                    message="No Data. Tasks must be running for at least 15 seconds for stats to be collected"
                  />
                )}
              </Collapse.Panel> 
            : <div />}
          </Collapse>
        </Card>
      ) : null}

      {!loading && <ExecutionLogs executionID={executionID} />}
    </Space>
  );
}
