import React, { useEffect, useState } from 'react';

import { Row, Col, Space, Button, Card, Typography, notification } from 'antd';
import { grey, blue } from '@ant-design/colors';
import {
  CopyOutlined,
  DesktopOutlined,
} from '@ant-design/icons';
import { makeStyles } from '@material-ui/core';

// core components
import { useSelector } from 'react-redux';

import DescriptionItem from '../Description/DescriptionItem';

import {
  loadVersions,
  loadLatest,
  loadExecutionLock,
  setExecutionLock,
  deleteExecutionLock,
  startSystemLogStream,
} from './statusSlice.js';
import store from '../../../store.js';
import {
  card,
  dangerColor,
  successColor,
} from '../../assets/jss/material-dashboard-react.js';

const VERSION = 'v2.1.4'

const { Text } = Typography;

const useStyles = makeStyles({
  componentVersionRow: {
    '& > .ant-col': {
      padding: '0.2rem 0.6rem',
    },
    '& > .ant-col:first-child': {
      background: blue[5],
      color: '#fff',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '& > .ant-col:last-child': {
      border: '1px solid #ddd',
    },
  },
  logsSection: {
    ...card,
    display: 'block',
    width: '100%',
    color: '#fff',
    fontWeight: 'normal',
    backgroundColor: '#191F28',
  },
  logsSectionHeader: {
    padding: '1rem 1.6rem',
    backgroundColor: '#1D2426',
    borderTopLeftRadius: card.borderRadius,
    borderTopRightRadius: card.borderRadius,
  },
  logsOuterContainer: {
    padding: '1rem 1rem',
    height: '434px',
    overflow: 'auto',
  },
  logsContainer: {
    padding: '0.4rem 0',
  },
  logEntry: {
    position: 'relative',
    padding: '0.2rem 1rem',
    whiteSpace: 'pre',
    fontWeight: 'normal',
    fontSize: '0.9rem',
    width: 'fit-content',
    minWidth: '100%',
    '&:hover, &[data-selected=true]': {
      backgroundColor: '#4b515a',
    },
    '& > div': {
      display: 'inline-block',
      textAlign: 'right',
      width: '2.5rem',
      padding: '0 0.8rem',
      marginRight: '15px',
      color: grey[2],
    },
    '&:hover > div': {
      color: '#FFFDFA',
    },
  },
  copiedNotification: {
    maxWidth: '100%',
    width: 'fit-content',
    fontWeight: 'normal',
    background: successColor[2],
    '& *': {
      color: '#fff',
    },
  },
});

export default function StatusPage() {
  const classes = useStyles();
  let systemLogs = useSelector((state) => state.status.systemLogs);
  let latest = useSelector((state) => state.status.latest);
  let loaded = useSelector((state) => state.status.loaded);
  let executionLockLoaded = useSelector(
    (state) => state.status.executionLockLoaded,
  );
  let executionLock = useSelector((state) => state.status.executionLock);
  const [selectedLog, setSelectedLog] = useState(null);

  let Versions = (
    <Row>
      <Col>API: </Col>
      <Col>App: </Col>
      <Col>UI: </Col>
    </Row>
  );
  if (!loaded) {
    store.dispatch(loadVersions());
    store.dispatch(loadLatest());
  } else {
    const StatusBox = ({ icon: Icon, label, text }) => (
      <Card.Grid
        hoverable={false}
        style={{ width: '50%', textAlign: 'center' }}
      >
        <Row justify="center" className={classes.componentVersionRow}>
          <Col xs={24} md={8}>
            <Space direction="horizontal" align="center">
              <Icon />
              <span>{label}</span>
            </Space>
          </Col>
          <Col xs={24} md={16}>{text}</Col>
        </Row>
      </Card.Grid>
    );
    Versions = (
      <Card title="Nominode Version" bodyStyle={{ padding: 0, display: 'flex' }}>
        <StatusBox icon={DesktopOutlined} label="Current" text={VERSION}></StatusBox>
        <StatusBox icon={DesktopOutlined} label="Latest" text={latest}></StatusBox>
      </Card>
    );
  }

  if (!executionLockLoaded) {
    store.dispatch(loadExecutionLock());
  }

  function toggleExecutions() {
    if (executionLock) {
      store.dispatch(deleteExecutionLock());
    } else {
      store.dispatch(setExecutionLock());
    }
  }

  function copySelectedLog() {
    if (selectedLog) {
      const log = systemLogs.find((log) => log.line === selectedLog);
      if (log) {
        navigator.clipboard.writeText(log.data);
        notification.success({
          message: '1 row copied to the clipboard.',
          className: classes.copiedNotification,
          icon: <CopyOutlined />,
        });
      }
    }
  }

  // TODO: Display log stream
  useEffect(() => {
    store.dispatch(startSystemLogStream());
  }, []);

  return (
    <div style={{ fontWeight: 'normal' }}>
      <Space direction="vertical" size={'large'} style={{ width: '100%' }}>
        <Row align="middle">
          <Col span={20}>
            <DescriptionItem title={'Status Page'} />
          </Col>
          <Col></Col>
        </Row>
        {Versions}
        <Button
          type={executionLock ? 'primary' : ''}
          onClick={toggleExecutions}
        >
          {executionLock ? 'Unlock Executions' : 'Lock Executions'}
        </Button>

      </Space>

      <section className={classes.logsSection}>
        <Row
          justify="space-between"
          align="middle"
          className={classes.logsSectionHeader}
        >
          <Col>
            <Text strong style={{ color: '#fff' }}>
              Logs
            </Text>
          </Col>
          <Col>
            <Button
              type="default"
              ghost
              icon={<CopyOutlined />}
              onClick={copySelectedLog}
            >
              Copy Selected
            </Button>
          </Col>
        </Row>

        <div className={classes.logsOuterContainer}>
          <div className={classes.logsContainer}>
            {systemLogs.map((log, i) => (
              <div
                key={log._id}
                className={classes.logEntry}
                onClick={() => setSelectedLog(log.line)}
                data-selected={log.line === selectedLog}
                onBlur={() => setSelectedLog(null)}
              >
                <div>{i + 1}</div>
                <span
                  style={{
                    paddingRight: '0.8rem',
                    color: log.level === 'INFO' ? '#FFFDFA' : dangerColor[2],
                  }}
                >
                  {log.line}
                </span>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}
