import { useSelector } from 'react-redux'
import React, { useEffect } from 'react';

import { Button } from 'antd'

import { api } from '../../api/api'

import { loadUsers, deleteUsers, loadRoles, loadGroups, deleteGroups, addGroup, editGroup, cancelNewGroup, saveNewGroup, addGroupValues, deleteGroup } from './permissionSlice.js'
import store from '../../../store.js'

import { Tabs } from 'antd';

const { TabPane } = Tabs;

import toast from '../../util/toasts';

import { Table, Input } from 'antd';

export default function Groups() {
    
    let newGroup = useSelector((state) => state.permissions.newGroup)
    let edit = useSelector((state) => state.permissions.editGroup)

    const editGroupName = useSelector((state) => state.permissions.editGroupName)

    let groupsloaded = useSelector((state) => state.permissions.groupsloaded)
    let groups = useSelector((state) => state.permissions.groups)
    if(!groupsloaded) {
        store.dispatch(loadGroups())
    }

    let dataSource = []

    function saveEdit() {
        store.dispatch(deleteGroups());
        store.dispatch(cancelNewGroup())
    }

    function save() {
        store.dispatch(saveNewGroup())
    }

    function nameChange(e) {
        store.dispatch(addGroupValues({field: 'group', value: e.target.value}))
    }

    function add() {
        store.dispatch(addGroup())
    }

    function cancel() {
        store.dispatch(cancelNewGroup())
    }

    let addRow = {}
    if(newGroup) {
        addRow = {uuid: 'new', name: <Input placeholder="group name" onChange={nameChange} />, edit: <div><Button onClick={save} type="primary">Save</Button><Button onClick={cancel}>Cancel</Button></div>}
    } else {
        addRow = {uuid: 'not-new', name: '', description: '', edit: <Button onClick={add} type="primary">New Group</Button>}
    }

    let TransfersElement = null;

    if(edit) {
        TransfersElement = (
            <>
                <div>Users</div>
                <UserTable />
                <div>Roles</div>
                <RoleTable />
            </>
        );

        addRow = {uuid: 'edit', name: editGroupName, edit: <div><Button onClick={saveEdit} type="primary">Back</Button></div>}
    }
    

    dataSource.push(addRow)
    if(!newGroup && !edit) {
        groups.forEach(group => {
            let editFunctor = () => {
                store.dispatch(editGroup({group: group}))
            }
            
            let deleteRow = () => {
                if (group.roles.length === 0 && group.users.length === 0 ) {
                    console.log(group)
                    store.dispatch(deleteGroup(group.uuid))
                    toast.success('Group deleted.');
                } else {
                    toast.error('Please remove all dependant objects first.');
                }
            }

            dataSource.push({uuid: group.uuid, name: group.name, edit: <Button onClick={editFunctor}>{'Edit'}</Button>, delete: <Button danger type="primary" onClick={deleteRow}>{'Delete'}</Button>})
        })
    }

    const columns = [
        {
            title: 'Group Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Edit',
            dataIndex: 'edit',
            key: 'edit',
        },
        {
            title: '',
            dataIndex: 'delete',
            key: 'delete',
        },
    ];

    return  <div><Table rowKey="uuid" dataSource={dataSource} columns={columns} pagination={false} />{TransfersElement}</div>
}

function UserTable() {
    let usersloaded = useSelector((state) => state.permissions.usersloaded)
    let users = useSelector((state) => state.permissions.users)
    let _editGroup = useSelector((state) => state.permissions._editGroup)

    const newGroupUsers = useSelector((state) => state.permissions.newGroupUsers)

    if(!usersloaded) {
        store.dispatch(loadUsers())
    }

    let dataSource = []

    let editGroupUserUUIDs = []
    _editGroup.users.forEach(user => {
        editGroupUserUUIDs.push(user.uuid)
    })

    const userSet = new Set(editGroupUserUUIDs)
    users.forEach(user => {
        function addFunctor() {
            api.groups.user.add(_editGroup.uuid, user.uuid)
            let newEditGroup = JSON.parse(JSON.stringify(_editGroup))
            newEditGroup.users.push(user)
            store.dispatch(editGroup({group: newEditGroup}))
            store.dispatch(deleteUsers())
            toast.success('User added.');
        }
        function removeFunctor() {
            api.groups.user.remove(_editGroup.uuid, user.uuid)
            let newEditGroup = JSON.parse(JSON.stringify(_editGroup))
            newEditGroup.users = newEditGroup.users.filter(function(item) {
                return item.uuid !== user.uuid
            })
            store.dispatch(editGroup({group: newEditGroup}))
            store.dispatch(deleteUsers())
            toast.success('User removed.');
        }
        if(userSet.has(user.uuid)) {
            dataSource.push({uuid: user.uuid, name: user.user_name, groups: <Button onClick={removeFunctor}>{'Remove'}</Button>})
        } else {
            dataSource.push({uuid: user.uuid, name: user.user_name, groups: <Button onClick={addFunctor}>{'Add'}</Button>})
        }
    })

    const columns = [
        {
          title: 'User Name',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: 'Groups',
          dataIndex: 'groups',
          key: 'edit',
        },
    ];

    return  <div><Table rowKey="uuid" dataSource={dataSource} columns={columns} pagination={false} /></div>
}

function RoleTable() {
    let roles = useSelector((state) => state.permissions.roles)
    let rolesloaded = useSelector((state) => state.permissions.rolesloaded)
    if(!rolesloaded) {
        store.dispatch(loadRoles())
    }

    let _editGroup = useSelector((state) => state.permissions._editGroup)

    let dataSource = []

    let editGroupRoleUUIDs = []
    _editGroup.roles.forEach(role => {
        editGroupRoleUUIDs.push(role.uuid)
    })

    const roleSet = new Set(editGroupRoleUUIDs)
    roles.forEach(role => {
        async function addFunctor() {
            api.groups.role.add(_editGroup.uuid, role.uuid)
            let newEditGroup = JSON.parse(JSON.stringify(_editGroup))
            newEditGroup.roles.push(role)
            store.dispatch(editGroup({group: newEditGroup}))
        }
        async function removeFunctor() {
            api.groups.role.remove(_editGroup.uuid, role.uuid)
            let newEditGroup = JSON.parse(JSON.stringify(_editGroup))
            newEditGroup.roles = newEditGroup.roles.filter(function(item) {
                return item.uuid !== role.uuid
            })
            store.dispatch(editGroup({group: newEditGroup}))
        }
        // HIDING SYSTEM ROLES BECAUSE THEY ARE NOT MODIFIABLE
        if(role.role_type != 'system') {
            if(roleSet.has(role.uuid)) {
                dataSource.push({uuid: role.uuid, role: role.role_name, edit: <Button onClick={removeFunctor}>{'Remove'}</Button>})
            } else {
                dataSource.push({uuid: role.uuid, role: role.role_name, edit: <Button onClick={addFunctor}>{'Add'}</Button>})
            }
        }
        
    })

    const columns = [
        {
            title: 'Role Name',
            dataIndex: 'role',
            key: 'role',
        },
        {
          title: '',
          dataIndex: 'edit',
          key: 'edit',
        },
    ];

    return <div><Table rowKey="uuid" dataSource={dataSource} columns={columns} pagination={false} /></div>
}