export default function checkIfGraph(rawSequenceTasks) {
  let isGraph = true;

  if (rawSequenceTasks && rawSequenceTasks.length) {
    const [first] = rawSequenceTasks;
    if (first.uuid) {
      // it has been created
      isGraph = first.options && first.options.coords;
    } else {
      // this is an attempt to create a new sequence
      isGraph = true;
    }
  }

  return isGraph;
}