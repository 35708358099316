// Do not remove license for legal reasons.
import {} from './license.js';

import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

import "./UI/assets/css/material-dashboard-react.css?v=1.10.0";
import "./UI/antd.css";

import { Provider } from 'react-redux';
import store from './store';

import { useRoutes, useRedirect, navigate } from 'hookrouter';
import { useSelector } from 'react-redux'

import {  startUp } from './UI/views/Login/loginSlice'
import { routeActiveKey } from './UI/components/Sidebar/sidebarSlice'

import routes from './routes'

import loginValidator from './UI/loginValidator'

import { checkReady } from './UI/views/Setup/setupSlice'

const MyApp = () => {
    useRedirect('/', '/00000-00000/tasks');
    const routeResult = useRoutes(routes);
    store.dispatch(startUp())
    store.dispatch(routeActiveKey())

    const authenticated = useSelector((state) => state.login.authenticated)
    const ready = useSelector((state) => state.setup.ready)

    const readyChecked = useSelector((state) => state.setup.readyChecked)
	  if(!readyChecked) {
		  store.dispatch(checkReady())
	  }

    if(!ready) {
      navigate('/setup')
    } else if(!authenticated) {
      navigate('/login')
    }
    if(window.location.pathname=='/login' && authenticated) {
      navigate('/00000-00000/tasks')
    }

    loginValidator()
    return routeResult || <NotFoundPage />;
}

function NotFoundPage() {
  return <div>404 lol</div>
}

ReactDOM.render(
    <Provider store={store}>
      <MyApp />
    </Provider>,
  document.getElementById('root')
)
