import { useSelector } from 'react-redux';
import { Checkbox, Select, Form, Row, Col } from 'antd';
import { LinkOutlined, CloseSquareOutlined } from '@ant-design/icons';
import { blue, yellow } from '@material-ui/core/colors';

import InputLabel from './InputLabel';
import ManyParameter from './ManyParameter';

import store from '../../../../../store';
import {
  selectSharedConfigs,
  selectSharedConfigsLoading,
} from '../../../SharedConfig/sharedConfigSlice';
import {
  selectSharedFields,
  selectSharedFieldsLoading,
} from '../../../SharedField/sharedFieldSlice';
import {
  selectConnections,
  selectConnectionsLoading,
} from '../../../Connections/connectionsSlice';
import {
  selectTaskEditValidations,
  changeTaskEditValue,
} from '../../shared/tasksSlice';

import FieldFactory from '../../../SharedField/FieldFactory';
import {
  generateFieldRules,
  generateFieldArguments,
} from '../../../../util/forms';

export default function ParameterSection(props) {
  const { section, state: stateParameters, locked, form } = props;
  const { parameters } = section;

  const sharedConfigs = useSelector(selectSharedConfigs);
  const sharedConfigsLoading = useSelector(selectSharedConfigsLoading);
  const sharedFields = useSelector(selectSharedFields);
  const sharedFieldsLoading = useSelector(selectSharedFieldsLoading);
  const connections = useSelector(selectConnections);
  const connectionsLoading = useSelector(selectConnectionsLoading);
  const validations = useSelector(selectTaskEditValidations);

  const sharedfields = useSelector((state) => state.sharedField.sharedFields);

  const parameterFields = [];
  parameters.forEach((parameter) => {
    const { name, type, display_name, description, required } = parameter;
    const validationErrors = validations[name];

    let value = stateParameters[name];

    // event handlers
    const onChangeParameter = (value, objectField = undefined) => {
      store.dispatch(
        changeTaskEditValue({
          name,
          value,
        }),
      );
      if (!objectField) {
        form.setFieldsValue({ [name]: value });
      } else {
        form.setFieldsValue({ [name + '_object']: value[objectField] });
      }
    };

    const onChangeOption = () => {};

    const rules = generateFieldRules(parameter);
    const isLinked = value
      ? Object.getPrototypeOf(value) === {}.__proto__
      : false;

    const initialValue = value !== undefined ? value : undefined;

    if (value === undefined) value = initialValue;

    if (parameter.many) {
      parameterFields.push(
        <ManyParameter parameter={parameter} locked={locked} />,
      );
    } else if (
      type == 'enum' ||
      type == 'enum_list' ||
      name == 'load_pattern'
    ) {
      let inputElement;
      if (parameter.display_type === 'checkbox_group') {
        inputElement = (
          <Checkbox.Group
            options={parameter.choices}
            value={value}
            onChange={onChangeParameter}
            disabled={locked}
          />
        );
      } else {
        const options = parameter.choices.map((choice) => (
          <Select.Option key={choice} value={choice}>
            {choice}
          </Select.Option>
        ));
        inputElement = (
          <Select
            allowClear
            value={value}
            disabled={locked}
            onChange={onChangeParameter}
          >
            {options}
          </Select>
        );
      }

      parameterFields.push(
        <Form.Item
          label={
            <InputLabel label={display_name} help={parameter.help?.header_id} />
          }
          name={name}
          rules={rules}
          initialValue={initialValue}
          required={required}
          {...(description && { help: description })}
        >
          {inputElement}
        </Form.Item>,
      );
    } else if (type === 'shared-config' || type === 'shared_config') {
      const options = sharedConfigs.map((d) => (
        <Select.Option key={d.uuid} value={d.uuid}>
          {d.alias}
        </Select.Option>
      ));

      const onChange = (value) => {
        onChangeParameter(
          {
            shared_config_uuid: value,
          },
          'shared_config_uuid',
        );
      };

      parameterFields.push(
        <Form.Item
          name={name + '_object'}
          label={
            <InputLabel label={display_name} help={parameter.help?.header_id} />
          }
          rules={rules}
          required={required}
          initialValue={initialValue?.shared_config_uuid}
          {...(description && { help: description })}
        >
          <Select
            allowClear
            showSearch
            optionFilterProp="children"
            value={value ? value.shared_config_uuid : value}
            loading={sharedConfigsLoading}
            disabled={locked}
            onChange={onChange}
            notFoundContent="No shared configs found"
          >
            {options}
          </Select>
        </Form.Item>,
      );
    } else if (type === 'connection') {
      const options = connections
        .filter(
          ({ connection_type_uuid: uuid }) =>
            uuid === parameter.connection_type_uuid,
        )
        .map(({ uuid, alias }) => (
          <Select.Option key={uuid} value={uuid}>
            {alias}
          </Select.Option>
        ));

      const onChange = (value) => {
        onChangeParameter(
          {
            connection_uuid: value,
          },
          'connection_uuid',
        );
      };

      parameterFields.push(
        <Form.Item
          name={name + '_object'}
          label={
            <InputLabel label={display_name} help={parameter.help?.header_id} />
          }
          rules={rules}
          required={required}
          initialValue={initialValue?.connection_uuid}
          {...(description && { help: description })}
        >
          <Select
            allowClear
            showSearch
            disabled={locked}
            optionFilterProp="children"
            value={value ? value.connection_uuid : value}
            onChange={onChange}
            loading={connectionsLoading}
            notFoundContent="No connections found"
          >
            {options}
          </Select>
        </Form.Item>,
      );
    } else {
      const extraArgs = generateFieldArguments(parameter);

      const linkField = () => {
        if (locked) return;
        onChangeParameter(
          {
            shared_field_uuid: '',
          },
          'shared_field_uuid',
        );
      };

      const unlinkField = () => {
        if (locked) return;
        if (value && value.shared_field_uuid) {
          const fieldValue = sharedFields.find(
            ({ uuid }) => uuid === value.shared_field_uuid,
          );
          // update the paramater value only if the user set the
          // shared field
          fieldValue && onChangeParameter(fieldValue.parameters);
        } else {
          onChangeParameter(parameter.default);
        }
      };

      let sharedFieldAlias;
      
      if (value) {
        for (const sharedField of sharedfields) {
          if (value.shared_field_uuid == sharedField.uuid) {
            sharedFieldAlias = sharedField.alias;
            break;
          }
        }
      }

      const fullLabel = (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <InputLabel label={display_name} help={parameter.help?.header_id} />
          {!isLinked ? (
            <LinkOutlined onClick={linkField} style={{ color: blue.primary }} />
          ) : (
            <div>
              <CloseSquareOutlined
                onClick={unlinkField}
                style={{ color: yellow[7] }}
              />
              <span className="ant-typography ant-typography-secondary" style={{ marginLeft: '5px', color: '#262626'}}>
                {sharedFieldAlias}
              </span>
            </div>
          )}
        </div>
      );

      if (!isLinked) {
        parameterFields.push(
          <Form.Item
            name={name}
            label={fullLabel}
            rules={rules}
            required={required}
            initialValue={initialValue}
            {...(description && { help: description })}
          >
            {FieldFactory.create(type, {
              value,
              options: {},
              onChange: onChangeParameter,
              onOptionsChange: onChangeOption,
              disabled: locked,
              ...extraArgs,
            })}
          </Form.Item>,
        );
      } else {
        const selectedField = sharedFields.find(
          ({ uuid }) => uuid === value.shared_field_uuid,
        );

        // selectedField is also undefined if the previously selected
        // sharedfield was deleted OR sharedFields hasn't loaded
        if (value.shared_field_uuid === '' || !selectedField) {
          const options = sharedFields
            .filter(({ field_type }) => field_type === type)
            .map(({ uuid, alias }) => (
              <Select.Option key={uuid} value={uuid}>
                {alias}
              </Select.Option>
            ));

          parameterFields.push(
            <Form.Item
              label={fullLabel}
              name={name + '_object'}
              rules={rules}
              required={required}
              preserve={false}
              {...(description && { help: description })}
            >
              <Select
                allowClear
                showSearch
                autoFocus
                defaultOpen
                optionFilterProp="children"
                value={value.shared_field_uuid}
                disabled={locked}
                loading={sharedFieldsLoading}
                notFoundContent="No shared fields found"
                onChange={(val) => {
                  const selected = sharedFields.find(
                    (field) => val === field.uuid,
                  );
                  if (selected) {
                    onChangeParameter(
                      {
                        shared_field_uuid: val,
                      },
                      'shared_field_uuid',
                    );
                  } else {
                    onChangeParameter(
                      {
                        shared_field_uuid: '',
                      },
                      'shared_field_uuid',
                    );
                  }
                }}
              >
                {options}
              </Select>
            </Form.Item>,
          );
        } else {
          parameterFields.push(
            <Form.Item
              label={fullLabel}
              rules={rules}
              required={required}
              {...(description && { help: description })}
            >
              {FieldFactory.create(type, {
                value: selectedField.parameters,
                options: {},
                onChange: () => {},
                onOptionsChange: () => {},
                disabled: true,
                ...extraArgs,
              })}
            </Form.Item>,
          );
        }
      }
    }
  });

  return (
    <Row gutter={[30, 20]}>
      {parameterFields.map((field, idx) => {
        const { type } = parameters ? parameters[idx] : section;
        const md = FieldFactory.hasMetaProperty(type, 'codeElement') ? 24 : 12;
        return (
          <Col key={idx} xs={24} md={md}>
            {field}
          </Col>
        );
      })}
    </Row>
  );
}
