import { Typography, Row, Col, Space } from 'antd';

const { Title, Text } = Typography;

function DescriptionItem(props) {
  return (
    <Space align="center">
      <Title level={4} style={{ margin: 0, fontWeight: "500" }}>
        {props.title}
      </Title>
      <Text type="secondary" style={{ margin: 0 }}>{props.description}</Text>
    </Space>
  );
}

export default DescriptionItem;
