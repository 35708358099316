import { useSelector } from 'react-redux'

import store from '../../../store.js'
import { loadEngines } from './engineSlice'

import Engine from './Engine'

function EnginesContainer(props) {
	const classes = props.classes

  const engines = useSelector((state) => state.engines.engines)
  let Containers = []

  if (typeof engines === "undefined") {
    store.dispatch(loadEngines())
  } else {
    let engineArray = []
    engines.forEach(engine => engineArray.push(engine))
    arrangeContainers(Containers, engineArray, classes)
  }

  // TODO: Render engines correctly
	return (
		<div>
      {Containers}
    </div>
	)
}

// TODO: this code should probably live in <Engine />
function arrangeContainers(Containers, engineArray, classes) {
  console.log(engineArray)
  engineArray.forEach(engine => {
      const engineInformation = engine.models[0]
      let item = {}
      item.active = !engine.archived && !engine.disabled
      item.alias = engineInformation.alias
      item.description = engineInformation.description
      try {
        item.iconURL = engineInformation.icons['1x'].url
      } catch (err) {
        item.iconURL = 'https://stg.nomnomdata.com/static/favicon/apple-touch-icon.png'
      }
      item.categories = engineInformation.categories
      item.uuid = engineInformation.uuid

      Containers.push(<Engine classes={classes} item={item} />)
    })
}

export default EnginesContainer
