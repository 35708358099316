import React from "react";
import { useSelector } from 'react-redux';
import { Space, Row, Col, Card } from "antd";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";

import styles from "../../assets/jss/material-dashboard-react/views/dashboardStyle.js";

import ViewAction from '../ViewAction/ViewAction'

import ProjectItem from '../Project/ProjectItem'
import DescriptionItem from '../Description/DescriptionItem'

import SharedConfigList from './SharedConfigList/SharedConfigList'
import AddSharedConfig from './AddSharedConfig'
import CreateSharedConfig from './CreateSharedConfig/CreateSharedConfig'
import EditSharedConfig from './EditSharedConfig/EditSharedConfig'

import store from "../../../store.js";

import { loadSharedConfigs } from './sharedConfigSlice'
import { selectProjectUuid } from '../Project/projectSlice';

const useStyles = makeStyles(styles);

export default function SharedConfigPage(props) {
  const classes = useStyles();
  const projectUuid = useSelector(selectProjectUuid);

  const sharedConfigsLoaded = useSelector((state) => state.sharedConfig.loaded)
  if (!sharedConfigsLoaded) {
      store.dispatch(loadSharedConfigs())
  }

  let Target = <SharedConfigList classes={classes} />
  if (props.props.create) {
    Target = <AddSharedConfig />
    if (props.props.uuid) {
      Target = <CreateSharedConfig uuid={props.props.uuid} />
    }
  }
  if (props.props.edit) {
    Target = <EditSharedConfig sharedConfigID={props.props.sharedConfigID} />
  }

  return (
    <Space direction="vertical" style={{ width: '100%', fontWeight: 'normal' }}>
      <Card>
        <Row justify="space-between">
          <Col>
            <DescriptionItem
              classes={classes}
              description="View and Manage Shared Config"
              title="Shared Config"
            />
          </Col>
          <Col>
            {props.props.create || props.props.uuid || props.props.edit ? (
              <div />
            ) : (
              <ViewAction
                classes={classes}
                target={'/' + projectUuid + '/sharedconfig/create'}
                description="Create Shared Config"
              />
            )}
          </Col>
        </Row>
      </Card>
      <div>
        {Target}
      </div>
    </Space>
  );
}
