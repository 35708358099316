import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { navigate } from 'hookrouter';
import { Card, Select } from 'antd';
import { makeStyles } from '@material-ui/core/styles';

import store from '../../../store.js';
import { selectProject } from '../Project/projectSlice';
import { loadConnectionTypes } from './connectionsSlice';

const useStyles = makeStyles((theme) => ({
  select: {
    width: '100%',
    maxWidth: 400,
  },
}));

export default function AddProject() {
  const project = useSelector(selectProject);
  
  const classes = useStyles();
  const connectionTypes = useSelector(
    (state) => state.connections.connectionTypes,
  );

  const handleTypeSelection = (value) => {
    navigate('./create/' + value);
  };

  useEffect(() => {
    store.dispatch(loadConnectionTypes());
  }, []);

  return (
    <Card title="Create a connection">
      <div className="w-full">
        <Select
          showSearch
          optionFilterProp="children"
          onChange={handleTypeSelection}
          className={classes.select}
          placeholder="Select a type"
          loading={!connectionTypes?.length}
        >
          {connectionTypes.map(({ uuid, alias }) => (
            <Select.Option key={uuid} value={uuid}>
              {alias}
            </Select.Option>
          ))}
        </Select>
      </div>
    </Card>
  );
}

const _useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
