import { createSlice } from '@reduxjs/toolkit';

import store from '../../../store.js';

const keyLookUp = {
  '/tasks': 0,
  '/executions': 1,
  '/sequences': 2,
  '/connections': 3,
  '/engines': 4,
  '/sharedconfig': 5,
  '/sharedfield': 6,
  '/admin': 7,
  '/admin/appmanagement': 8,
  '/admin/permissions': 9,
};

const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState: { activeKey: 0 },
  reducers: {
    setActiveKey(state, action) {
      state.activeKey = action.payload;
    },
    routeActiveKey(state, action) {
      const parts = window.location.pathname.split('/').slice(2);
      let key = null;
      while (parts.length) {
        const full = '/' + parts.join('/');
        if (full in keyLookUp) {
          key = full;
          break;
        }
        parts.pop();
      }
      if (key) {
        state.activeKey = keyLookUp[key];
      } else {
        state.activeKey = -1;
      }
    },
  },
});

export const { setActiveKey, routeActiveKey } = sidebarSlice.actions;

export default sidebarSlice.reducer;
