import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Form, Space, Button, Tag } from 'antd';

// selectors and dispatchers
import store from '../../../../../store';
import {
  selectNewTaskManyParameters,
  selectNewTaskParameters,
  changeNewTaskParameterValue,
} from '../../shared/tasksSlice';

// form util
import FieldFactory from '../../../SharedField/FieldFactory';
import { generateFieldRules } from '../../../../util/forms';

export default function ManyParameter(props) {
  const { locked, parameter } = props;

  const { [parameter.name]: parameterDefinition } = useSelector(
    selectNewTaskManyParameters,
  );
  const { [parameter.name]: parameterValues } = useSelector(
    selectNewTaskParameters,
  );

  const { name, display_name, type, required, description } =
    parameterDefinition;

  const defaultParameterValue = FieldFactory.getDefaultValue(type);
  const [newValue, setNewValue] = useState(defaultParameterValue);

  const rules = generateFieldRules(parameterDefinition);

  const handleOptionsChange = () => {};
  const handleValueChange = (value) => {
    setNewValue(value);
  };
  const handleKeyPress = (event) => {
    if (event.key.toLowerCase() === 'enter') {
      event.preventDefault();
      event.stopPropagation();
      addNewValue();
    }
  };

  const addNewValue = () => {
    if (!newValue?.trim()) {
      return;
    }
    
    if (parameterValues.includes(newValue)) {
      return;
    }

    store.dispatch(
      changeNewTaskParameterValue({
        name: parameter.name,
        value: [...parameterValues, newValue],
      }),
    );
    setNewValue(FieldFactory.getDefaultValue(parameterDefinition.type));
  };

  const deleteValueByIndex = (index) => {
    const newValues = parameterValues.filter((_, idx) => idx !== index);
    store.dispatch(
      changeNewTaskParameterValue({
        name: parameter.name,
        value: newValues,
      }),
    );
  };

  return (
    <Form.Item
      name={name + '_input'}
      label={<strong>{display_name}</strong>}
      rules={rules}
      required={required}
      initialValue={defaultParameterValue}
      {...(description && { help: description })}
    >
      <Space direction="vertical" style={{ width: '100%', marginBottom: 10 }}>
        <Space direction="horizontal" style={{ width: '100%' }}>
          {FieldFactory.create(type, {
            value: newValue,
            options: {},
            onChange: handleValueChange,
            onOptionsChange: handleOptionsChange,
            onKeyPress: handleKeyPress,
            disabled: locked,
          })}
          <Button type="primary" onClick={addNewValue} disabled={locked}>
            Add
          </Button>
        </Space>

        <div style={{ width: '100%' }}>
          {parameterValues.map((value, idx) => {
            return (
              <Tag
                key={value}
                closable={!locked}
                onClose={() => deleteValueByIndex(idx)}
              >
                {value}
              </Tag>
            );
          })}
        </div>
      </Space>
    </Form.Item>
  );
}
