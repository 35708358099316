import { useSelector } from 'react-redux';

import { Menu, Typography } from 'antd';

import { A, navigate } from 'hookrouter';
import classNames from 'classnames';

import { selectProjectUuid } from '../../views/Project/projectSlice';

import routes from '../../routes.js';

const { Text } = Typography;

const SidebarItemsFromRoutes = (props) => {
  const projectUuid = useSelector(selectProjectUuid);

  const { classes, sidebarCollapsed } = props;

  const active = useSelector((state) => state.sidebar.activeKey);

  return (
    <div className={classes.sidebarWrapper}>
      <Menu
        className={classes.list}
        mode="inline"
        inlineCollapsed={sidebarCollapsed}
      >
        {routes.map((route, key) => {
          const targetRoute = '/' + projectUuid + route.path;
          
          return (
            <Menu.Item
              key={key}
              icon={<route.icon className={classes.itemIcon} />}
              className={classNames(classes.item, {
                [classes.itemActive]: key === active,
              })}
              onClick={() => navigate(targetRoute)}
            >
              <A href={targetRoute} className={classes.itemLink}>
                <Text className={classes.itemText}>{route.name}</Text>
              </A>
            </Menu.Item>
          );
        })}
      </Menu>
    </div>
  );
};

export default SidebarItemsFromRoutes;
