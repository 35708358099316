import { useSelector } from 'react-redux'

import { loadProjects, addProject, addProjectValues, saveNewProject, cancelNewProject, editProject, editProjectValues, saveEditProject, cancelEditProject, deleteProject } from './permissionSlice.js'
import store from '../../../store.js'

import { Tabs, Tag, Table, Input, Button, Tooltip } from 'antd';

import toast from '../../util/toasts';

export default function Projects() {
    let projectsloaded = useSelector((state) => state.permissions.projectsloaded)
    let projects = useSelector((state) => state.permissions.projects)
    let newProject = useSelector((state) => state.permissions.newProject)
    let editTarget = useSelector((state) => state.permissions.editProjectTarget)
    if(!projectsloaded) {
        store.dispatch(loadProjects())
    }

    let dataSource = []

    function save() {
        store.dispatch(saveNewProject())
    }

    function cancel() {
        store.dispatch(cancelNewProject())
    }

    function saveEdit() {
        store.dispatch(saveEditProject())
    }

    function cancelEdit() {
        store.dispatch(cancelEditProject())
    }

    function add() {
        store.dispatch(addProject())
    }

    function aliasChange(e) {
        store.dispatch(addProjectValues({field: 'alias', value: e.target.value}))
    }

    function descriptionChange(e) {
        store.dispatch(addProjectValues({field: 'description', value: e.target.value}))
    }

    function editAliasChange(e) {
        store.dispatch(editProjectValues({field: 'alias', value: e.target.value}))
    }

    function editDescriptionChange(e) {
        store.dispatch(editProjectValues({field: 'description', value: e.target.value}))
    }

    let addRow = {}
    if(newProject) {
        addRow = {uuid: 'new', name: <Input placeholder="Alias" onChange={aliasChange} />, description: <Input placeholder="Description" onChange={descriptionChange} />, edit: <div><Button onClick={save} type="primary">Save</Button><Button onClick={cancel}>Cancel</Button></div>}
    } else {
        addRow = {uuid: 'not-new', name: '', description: '', edit: <Button onClick={add} type="primary">New Project</Button>}
    }
    dataSource.push(addRow)

    projects.forEach(project => {
        let editFunctor = () => {
            store.dispatch(editProject({alias: project.alias, description: project.description}))
        }
        let deleteFunctor = () => { // this deletes everything
            store.dispatch(deleteProject(project.uuid))
        }

        let deleteRow = () => {
            //store.dispatch(deleteProject(project.uuid))
            //toast.success('Project deleted.');
        }

        if(editTarget==project.alias) {
            dataSource.push({uuid: project.uuid, name: <Input defaultValue={project.alias} onChange={editAliasChange} />, description: <Input defaultValue={project.description} onChange={editDescriptionChange} />, edit: <div><Button type='primary' onClick={saveEdit}>Save</Button><Button onClick={cancelEdit}>Cancel</Button></div>})
        } else {
            dataSource.push({uuid: project.uuid, name: project.alias, description: project.description, 
                edit: <div><Button onClick={editFunctor}>{'Edit'}</Button></div>, 
                delete: <div>
                        <Tooltip placement="left" title={'Delete by deleting all associated roles'}>
                        <Tag >Delete</Tag>
                        
                        </Tooltip>
                    </div>
            })
        }
    })
    console.log(projects);
    const columns = [
        {
            title: 'Project Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: '',
            dataIndex: 'edit',
            key: 'edit',
        },
        {
            title: '',
            dataIndex: 'delete',
            key: 'delete',
          },
    ];

    return  <div><Table rowKey="uuid" dataSource={dataSource} columns={columns} pagination={false} /></div>
}