import { Row, Col, Form, Select } from 'antd';

export default function AdvancedSettingsTab(props) {
  const { logLevel, onLogLevelChange, locked } = props;

  const logLevels = ['Debug', 'Info', 'Warning', 'Error'];

  const onChangeParameter = (value) => {
    onLogLevelChange(value);
  };

  return (
    <Row gutter={[30, 20]}>
      <Col xs={24} md={12}>
        <Form.Item
          label={<strong>Log Level</strong>}
          name="logLevel"
          rules={[{ required: true }]}
          required
        >
          <Select
            value={logLevel}
            onChange={onChangeParameter}
            disabled={locked}
          >
            {logLevels.map((level) => (
              <Select.Option key={level} value={level.toUpperCase()}>
                {level}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
    </Row>
  );
}
