import { successColor, dangerColor } from "../../../assets/jss/material-dashboard-react";

const styles = {
  upperGrid: {
    width: '100%',
  },
  upperGridInput: {
    width: '100%',
  },
  modelAlias: {
    fontSize: '1rem',
  },
  modelDesc: {
    fontSize: '0.9rem',
    fontWeight: '300',
  },
  executionSuccess: {
    maxWidth: '100%',
    width: 'fit-content',
    fontWeight: 'normal',
    background: successColor[2],
    '& *': {
      color: '#fff',
    },
  },
  executionFailure: {
    maxWidth: '100%',
    width: 'fit-content',
    fontWeight: 'normal',
    background: dangerColor[2],
    '& *': {
      color: '#fff',
    },
  },
};

export default styles;