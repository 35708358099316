import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { navigate } from 'hookrouter';
import { Card, Select, Space, Row, Col, Button } from 'antd';

import { makeStyles } from '@material-ui/core/styles';

import ProjectItem from '../Project/ProjectItem';
import DescriptionItem from '../Description/DescriptionItem';

import store from '../../../store.js';
import { selectEngines, loadEngines } from '../Engines/engineSlice.js';

const useStyles = makeStyles((theme) => ({
  select: {
    width: '100%',
    maxWidth: 400,
  },
}));

export default function SelectTaskEngine() {
  const classes = useStyles();
  const engines = useSelector(selectEngines);

  const handleTypeSelection = (engine) => {
    navigate(`./create/${engine}`);
  };

  useEffect(() => {
    store.dispatch(loadEngines());
  }, []);

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Card>
        <Row justify="space-between" align="middle">
          <Col>
            <DescriptionItem
              title="Tasks"
              description="Create new task"
            />
          </Col>
        </Row>
        <ProjectItem />
      </Card>
      <Card title="Create a task">
        <div className="w-full">
          <Select
            size="large"
            showSearch
            onChange={handleTypeSelection}
            className={classes.select}
            placeholder="Select an App"
            loading={!engines?.length}
            optionFilterProp="children"
          >
            {engines
              ? engines.map(({ uuid, models }) => (
                  <Select.Option key={uuid} value={uuid}>
                    {models[0].alias}
                  </Select.Option>
                ))
              : null}
          </Select>
        </div>
      </Card>
    </Space>
  );
}
