export function generateFieldRules(parameter) {
  let { display_name, type, required } = parameter;
  let ruleType = 'string';

  if (type === 'int') ruleType = 'number';
  else if (type === 'password') ruleType = 'string';

  const rules = [{ required, message: `${display_name} is a required field` }];

  const suffix = ruleType === 'string' ? ' characters' : '';

  if (typeof parameter.min === 'number')
    rules.push({
      type: ruleType,
      min: parameter.min,
      message: `${display_name} must be at least ${parameter.min}${suffix}`,
    });
  if (typeof parameter.max === 'number')
    rules.push({
      type: ruleType,
      max: parameter.max,
      message: `${display_name} must be at most ${parameter.max}${suffix}`,
    });

  return rules;
}

export function generateFieldArguments(parameter) {
  const { type } = parameter;
  const args = {};

  switch (type) {
    case 'code':
      if (parameter.dialect) args.dialect = parameter.dialect;
      break;
    default:
      break;
  }

  return args;
}
