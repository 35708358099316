import { useEffect, useState } from 'react';
import { navigate } from 'hookrouter';
import { makeStyles } from '@material-ui/core';
import { pick } from 'lodash';
import {
  Card,
  Form,
  Input,
  Space,
  Row,
  Col,
  Select,
  Collapse,
  Button,
  Dropdown,
  Menu,
  Checkbox,
  Tag,
} from 'antd';
import { useSelector } from 'react-redux';
import { blue, green, yellow, red } from '@ant-design/colors';
import {
  CloseSquareOutlined,
  DeleteTwoTone,
  DotChartOutlined,
  EditOutlined,
  ForkOutlined,
  HistoryOutlined,
  LinkOutlined,
  LockTwoTone,
  MenuOutlined,
  QuestionOutlined,
  SaveOutlined,
  ThunderboltFilled,
  ThunderboltOutlined,
  UnlockTwoTone,
} from '@ant-design/icons';

import DescriptionItem from '../../Description/DescriptionItem';

import store from '../../../../store';
import Loading from '../../../components/Loading';
import JSONParamEditor from '../../../components/JSONParamEditor';

import {
  generateFieldArguments,
  generateFieldRules,
} from '../../../util/forms';
import FieldFactory from '../../SharedField/FieldFactory';

import {
  selectProjectUuid,
  selectProjectAlias,
} from '../../Project/projectSlice';
import {
  changeTaskEditValue,
  configureTaskEditParameters,
  deleteTask,
  executeTask,
  fetchTask,
  hardSetEditParameters,
  resetTaskEdit,
  resetTasks,
  selectTaskCursor,
  selectTaskDelete,
  selectTaskEdit,
  selectTaskEditManyParameters,
  selectTaskEditSet,
  selectTaskEditValidations,
  selectTaskExecutions,
  setTaskHelpActionKey,
  setTaskHelpParameterHeaderId,
} from '../shared/tasksSlice';
import {
  loadSharedConfigs,
  selectSharedConfigs,
  selectSharedConfigsLoading,
} from '../../SharedConfig/sharedConfigSlice';
import {
  loadConnections,
  loadConnectionTypes,
  selectConnections,
  selectConnectionsLoading,
} from '../../Connections/connectionsSlice';
import { api } from '../../../api/api';
import { selectEngines, loadEngines } from '../../Engines/engineSlice';
import {
  loadSharedFields,
  selectSharedFields,
  selectSharedFieldsLoading,
} from '../../SharedField/sharedFieldSlice';
import logger from '../../../util/logger';
import {
  successColor,
  dangerColor,
} from '../../../assets/jss/material-dashboard-react';
import TaskTabs from '../TaskTabs';
import ButtonConfirmation from '../../../components/ButtonConfirmation';
import TaskHelp from '../TaskHelp';
import toast from '../../../util/toasts';
import styles from './styles';
import ParametersTab from './tabs/ParametersTab';
import AdvancedSettingsTab from '../CreateTask/tabs/AdvancedSettingsTab';

const { Panel } = Collapse;
const { Group: BtnGroup } = Button;
const { useForm } = Form;

const useStyles = makeStyles(styles);

const tabList = [
  { key: 'parameters', tab: 'Parameters' },
  { key: 'settings', tab: 'Advanced Settings' },
];

export default function EditTask(props) {
  const { taskID } = props;
  const classes = useStyles();
  const taskCursor = useSelector(selectTaskCursor);
  const taskEdit = useSelector(selectTaskEdit);
  const taskEditSet = useSelector(selectTaskEditSet);
  const projectAlias = useSelector(selectProjectAlias);
  const projectUuid = useSelector(selectProjectUuid);
  const sharedConfigs = useSelector(selectSharedConfigs);

  // uses all engines here to preserve already existing engines functionality
  const engines = useSelector((state) => state.engines.allEngines)
  const taskExecutions = useSelector(selectTaskExecutions);
  const { deleting: deletingTask, error: deletingError } =
    useSelector(selectTaskDelete);
  const [alias, setAlias] = useState('');
  const [channel, setChannel] = useState('stable');
  const [logLevel, setLogLevel] = useState(null);
  // string
  const [action, setAction] = useState(null);
  const [selectedAction, setSelectedAction] = useState(null);
  const [selectedEngine, setSelectedEngine] = useState(null);
  const [selectedModel, setSelectedModel] = useState(null);
  const [selectedModelActions, setSelectedModelActions] = useState(null);
  const [activeTabKey, setActiveTabKey] = useState('parameters');
  const [locked, setLocked] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [cloning, setCloning] = useState(false);
  const [paramEditorOpen, setParamEditorOpen] = useState(false);

  const [form] = useForm();
  const fullParameters = {
    ...taskEdit,
    alias,
    action_name: action,
    ...(logLevel && { log_level: logLevel }),
  };

  const toggleTaskFieldsLock = () => {
    setLocked(!locked);
  };

  const onChangeAlias = (event) => {
    const value = event.target.value;
    setAlias(value);
  };

  const onChangeChannel = (value) => {
    setChannel(value);
  };

  const onChangeLogLevel = (value) => {
    setLogLevel(value);
  };

  const onChangeAction = (newAction, actions = selectedModelActions) => {
    if (newAction === action) return;

    const pair = actions.find(([key]) => key === newAction);
    if (!pair) return;

    const act = pair[1];

    // create an artifical group to capture "general parameters"
    // they are parameters that are not group containers

    const generalGroup = [];
    const otherParameters = [];
    for (let parameter of act.parameters) {
      if (!parameter.parameters) {
        generalGroup.push(parameter);
      } else {
        otherParameters.push(parameter);
      }
    }

    const newParameters = [...otherParameters];
    if (generalGroup.length) {
      newParameters.unshift({
        display_name: 'General Parameters',
        name: 'general_parameters',
        parameters: generalGroup,
        type: 'group',
      });
    }

    if (act.help) {
      store.dispatch(setTaskHelpActionKey(act.help.key));
    }

    setAction(newAction);
    setSelectedAction({
      ...act,
      parameters: newParameters,
    });
  };

  const handleTabChange = (key) => setActiveTabKey(key);

  const save = () => {
    setProcessing(true);

    // pick the parameters for the selected action
    const fields = [];
    selectedAction.parameters.forEach((param) => {
      param.parameters.forEach((p) => {
        fields.push(p.name);
      });
    });

    const uuid = taskCursor.uuid;

    const payload = {
      alias,
      uuid,
      engine_uuid: taskCursor.engine_uuid,
      options: { channel },
      parameters: {
        ...pick(taskEdit, fields),
        alias,
        action_name: action,
      },
      project_uuid: taskCursor.project_uuid,
    };

    if (logLevel) {
      payload.options['log_level'] = logLevel;
    }

    api.tasks
      .put(projectUuid, uuid, payload)
      .then((data) => {
        store.dispatch(resetTaskEdit());
        navigate('/' + projectUuid + '/tasks');
      })
      .catch(() => {
        toast.error('Failed to update task.');
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  const openJSONParamEditor = () => {
    setParamEditorOpen(true);
  };

  const handleParamEditorClose = (data, wasCancelled) => {
    if (wasCancelled) {
      setParamEditorOpen(false);
      return;
    }

    store.dispatch(hardSetEditParameters(data));
    for (let name in data) {
      form.setFieldsValue({ [name]: data[name] });
    }
    setAlias(data.alias);
    // setChannel(data.channel);
    onChangeAction(data.action_name);
    if ('log_level' in fullParameters) {
      setLogLevel(data.log_level);
    }
    setParamEditorOpen(false);
  };

  const goToHistory = () => {
    navigate('./' + props.taskID + '/history');
  };

  const goToDependencies = () => {
    navigate('./' + 'dependencies/' + props.taskID);
  };

  const goToExecutions = () => {
    navigate('/' + projectUuid + '/executions' + '/tasks/' + props.taskID);
  };

  const handleExecuteTask = async (record) => {
    const { error, payload: execution } = await store.dispatch(
      executeTask(taskID),
    );
    if (error) {
      logger.debug(`Failed to execute task: ${taskID}.`);
    } else {
      logger.debug(`Executing task: ${taskID}.`);
      window.location = `./${projectUuid}/executions/${execution.uuid}`;
    }
  };

  const handleSaveAndExecute = async (record) => {
    setProcessing(true);

    // pick the parameters for the selected action
    const fields = [];
    selectedAction.parameters.forEach((param) => {
      param.parameters.forEach((p) => {
        fields.push(p.name);
      });
    });

    const uuid = taskCursor.uuid;

    const payload = {
      alias,
      uuid,
      engine_uuid: taskCursor.engine_uuid,
      options: { channel },
      parameters: {
        ...pick(taskEdit, fields),
        alias,
        action_name: action,
      },
      project_uuid: taskCursor.project_uuid,
    };

    if (logLevel) {
      payload.options['log_level'] = logLevel;
    }

    await api.tasks
      .put(projectUuid, uuid, payload)
      .then((data) => {
      })
      .catch(() => {
        toast.error('Failed to update task.');
      })
      .finally(() => {
        setProcessing(false);
      });
    
    
    const { error, payload: execution } = await store.dispatch(
      executeTask(taskID),
    );

    if (error) {
      logger.debug(`Failed to execute task: ${taskID}.`);
    } else {
      logger.debug(`Executing task: ${taskID}.`);
      window.location = `./${projectUuid}/executions/${execution.uuid}`;
    }
  }

  const handleDeleteTask = async () => {
    const { error } = await store.dispatch(deleteTask(taskID));
    if (error) {
      toast.error('Failed to delete task.');
      logger.error('Failed to delete task: ' + error.message);
    } else {
      // navigate to the list
      await store.dispatch(resetTasks());
      navigate('/' + projectUuid + '/tasks');
    }
  };

  const handleClone = async () => {
    setCloning(true);

    const {
      parameters: { action_name, alias },
      options: { channel, log_level },
    } = taskCursor;

    const action = selectedEngine.models[0].actions[action_name];

    // pick the parameters for the selected action
    const fields = [];
    action.parameters.forEach((param) => {
      param.parameters.forEach((p) => {
        fields.push(p.name);
      });
    });

    const payload = {
      alias,
      engine_uuid: selectedModel.uuid,
      options: { channel },
      parameters: {
        ...pick(taskCursor.parameters, fields),
        alias: alias + ' Clone',
        action_name: action_name,
      },
      project_uuid: projectUuid,
    };

    if (log_level) {
      payload.options['log_level'] = log_level;
    }

    api.tasks
      .create(projectUuid, payload)
      .then((data) => {
        window.open('/' + projectUuid + '/tasks/' + data.uuid);
      })
      .catch(() => {
        toast.error('Failed to clone task.');
      })
      .finally(() => {
        setCloning(false);
      });
  };

  useEffect(() => {
    store.dispatch(loadEngines());
    store.dispatch(loadSharedConfigs());
    store.dispatch(loadSharedFields());
    store.dispatch(loadConnectionTypes());
    store.dispatch(loadConnections());
    return function cleanup() {
      logger.debug('cleanup');
      store.dispatch(resetTaskEdit());
      store.dispatch(resetTasks());
    };
  }, []);

  useEffect(() => {
    if (!(projectUuid == undefined)) {
      store.dispatch(fetchTask(taskID));
    }
  }, [projectUuid]);

  useEffect(() => {
    if (selectedAction) {
      store.dispatch(configureTaskEditParameters(selectedAction.parameters));
    }
  }, [selectedAction]);

  useEffect(() => {
    if (!taskCursor || !engines) return;

    const {
      parameters: { action_name, alias },
      options: { channel, log_level },
      engine_uuid,
    } = taskCursor;

    let modelIndex = null,
      engineIndex = null;
    for (let i = 0; i < engines.length && !engineIndex; i++) {
      for (let j = 0; j < engines[i].models.length; j++) {
        if (engines[i].models[j].uuid === engine_uuid) {
          engineIndex = i;
          modelIndex = j;
          break;
        }
      }
    }

    console.log('engine loading timing fix here');
    console.log(engineIndex);
    if (engineIndex < 0) return;
    console.log(engineIndex);

    const engine = engines[engineIndex];
    const model = engine.models[modelIndex];
    const actions = Object.entries(model.actions);

    setAlias(alias);
    setChannel(channel);
    setLogLevel(log_level || null);
    setSelectedEngine(engine);
    setSelectedModel(model);
    setSelectedModelActions(actions);

    if (action_name in model.actions) onChangeAction(action_name, actions);
    else onChangeAction(actions[0][0], actions);
  }, [!!engines, taskCursor]);

  if (!selectedAction || !taskEditSet) return <Loading />;

  return (
    <div>
      <JSONParamEditor
        open={paramEditorOpen}
        data={fullParameters}
        onClose={handleParamEditorClose}
        disabled={locked}
      />

      <TaskHelp model={selectedModel} />

      <Form form={form} scrollToFirstError layout="vertical" onFinish={save}>
        <Space direction="vertical" size="middle" style={{ width: '100%' }}>
          <div>
            <Card>
              <Space
                direction="vertical"
                size="middle"
                style={{ width: '100%' }}
              >
                <DescriptionItem
                  title="Task Details"
                  description="Details of an existing task"
                />

                <div>
                  <strong style={{ fontWeight: 500 }}>Current Project:</strong>{' '}
                  {projectAlias}
                </div>
              </Space>
            </Card>
            <Card
              bordered={false}
              bodyStyle={{ paddingTop: 0, paddingBottom: 0 }}
            >
              <TaskTabs
                tab="edit"
                projectID={projectUuid}
                taskID={props.taskID}
              />
            </Card>
          </div>

          <Card>
            <Card.Grid hoverable={false} className={classes.upperGrid}>
              <Row justify="space-between" gutter={[30, 20]}>
                <Col xs={24} md={12} lg={8}>
                  <Form.Item
                    label={<strong>Alias</strong>}
                    name="alias"
                    rules={[
                      {
                        required: true,
                        message: 'Task Name is a required field',
                      },
                    ]}
                    labelAlign="left"
                    initialValue={alias}
                  >
                    <Input
                      size="middle"
                      onChange={onChangeAlias}
                      className={classes.upperGridInput}
                      disabled={locked}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={8}>
                  <Form.Item
                    label={<strong>Channel</strong>}
                    name="global_channel"
                    rules={[{ required: true }]}
                    initialValue={selectedEngine.default_channel}
                    required
                    labelAlign="left"
                  >
                    <Select
                      className={classes.upperGridInput}
                      onChange={onChangeChannel}
                      disabled={locked}
                    >
                      <Select.Option value="stable">
                        Stable <i>(Default)</i>
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Card.Grid>
            <Card.Grid hoverable={false} className={classes.upperGrid}>
              <Space direction="vertical">
                <Row>
                  <Col style={{ marginRight: '18px' }}>
                    <img
                      src={
                        selectedModel.icons['1x']?.url
                          ? `${selectedModel.icons['1x'].url}`
                          : './static/favicon/apple-touch-icon.png'
                      }
                      style={{
                        width: '50px',
                        height: '50px',
                        objectFit: 'contain',
                      }}
                    />
                  </Col>
                  <Col>
                    <h5 className={classes.modelAlias}>
                      {selectedModel.alias}
                    </h5>
                    <p className={classes.modelDesc}>
                      {selectedModel.description}
                    </p>
                  </Col>
                </Row>

                <Form.Item
                  label={<strong>Action</strong>}
                  name="action_name"
                  rules={[{ required: true }]}
                  initialValue={taskCursor.parameters.action_name}
                  labelAlign="right"
                  help={selectedAction?.description}
                  required
                >
                  <Select
                    className={classes.actionSelect}
                    onChange={(name) => onChangeAction(name)}
                    disabled={locked}
                  >
                    {selectedModelActions.map(([key, action]) => (
                      <Select.Option key={key} value={key}>
                        {action.display_name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Space>
            </Card.Grid>
          </Card>

          <Card
            size="small"
            tabList={tabList}
            activeTabKey={activeTabKey}
            onTabChange={handleTabChange}
            tabBarExtraContent={
              <BtnGroup>
                <Button
                  icon={
                    locked ? (
                      <UnlockTwoTone twoToneColor={green.primary} />
                    ) : (
                      <LockTwoTone twoToneColor={green.primary} />
                    )
                  }
                  onClick={toggleTaskFieldsLock}
                >
                  {locked ? 'Unlock' : 'Lock'}
                </Button>
                <Button
                  icon={<ThunderboltOutlined />}
                  onClick={handleSaveAndExecute}
                  loading={taskExecutions[taskID]}
                >
                  Save and Execute
                </Button>
                <Button
                  htmlType="submit"
                  style={{
                    backgroundColor: locked ? undefined : green[6],
                    color: locked ? undefined : '#fff',
                  }}
                  disabled={locked || deletingTask}
                  icon={<SaveOutlined />}
                  loading={processing}
                >
                  Save
                </Button>
                <Dropdown
                  trigger={['click', 'hover']}
                  overlay={
                    <Menu>
                      <Menu.Item
                        key="history"
                        icon={<HistoryOutlined />}
                        onClick={goToHistory}
                      >
                        History
                      </Menu.Item>
                      <ButtonConfirmation
                        confirmationText="Would you like to create a new task with the same parameters?"
                        onOk={handleClone}
                      >
                        <Button 
                          style={{border: "none", boxShadow: "none", paddingLeft: "10px"}}
                          icon={<ForkOutlined />} 
                          loading={cloning}
                        >
                          Clone
                        </Button>
                      </ButtonConfirmation>
                      <br/>
                      <Menu.Item
                        key="dependencies"
                        icon={<DotChartOutlined />}
                        onClick={goToDependencies}
                      >
                        Dependencies
                      </Menu.Item>
                      <Menu.Item
                        key="executions"
                        icon={<ThunderboltFilled />}
                        onClick={goToExecutions}
                      >
                        Executions
                      </Menu.Item>
                      <Menu.Item
                        key="edit"
                        icon={<EditOutlined />}
                        onClick={() => openJSONParamEditor()}
                      >
                        Edit
                      </Menu.Item>
                      <ButtonConfirmation
                        confirmationText="Are you sure you would like to delete this task?"
                        onOk={handleDeleteTask}
                      >
                        <Button
                          style={{border: "none", boxShadow: "none", paddingLeft: "10px"}}
                          icon={<DeleteTwoTone twoToneColor={red.primary} />}
                          loading={deletingTask}
                        >
                          Delete
                        </Button>
                      </ButtonConfirmation>
                    </Menu>
                  }
                >
                  <Button>
                    <MenuOutlined />
                  </Button>
                </Dropdown>
              </BtnGroup>
            }
          >
            {activeTabKey === 'parameters' ? (
              <ParametersTab
                parameters={selectedAction.parameters}
                state={taskEdit}
                sharedConfigs={sharedConfigs}
                locked={locked}
                form={form}
              />
            ) : activeTabKey === 'settings' ? (
              <AdvancedSettingsTab
                logLevel={logLevel}
                onLogLevelChange={onChangeLogLevel}
                locked={locked}
              />
            ) : null}
          </Card>
        </Space>
      </Form>
    </div>
  );
}
