import React from 'react';
import { useSelector } from 'react-redux';
import { Card, Input, Space, Table } from 'antd';

import { selectProjectUuid } from '../../Project/projectSlice';
import { getTableColumns } from './tableColumns';
import useSearch from './useSearch';

const { Search } = Input;

export default function SharedConfigsTable(props) {
  const projectUuid = useSelector(selectProjectUuid);
  const columns = getTableColumns({ projectID: projectUuid });

  const sharedConfigs = useSelector(
    (state) => state.sharedConfig.sharedConfigs,
  );

  const sharedConfigsLoaded = useSelector((state) => state.sharedConfig.loaded);
  const { queryResult, searchList } = useSearch({ data: sharedConfigs });

  const searchSharedConfigs = (query) => {
    searchList('alias', query);
  };

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Card size="small">
        <Search
          allowClear
          style={{ maxWidth: 300 }}
          placeholder="Search by shared config name"
          onChange={({ target }) => searchSharedConfigs(target.value)}
          onSearch={(value) => searchSharedConfigs(value)}
        />
      </Card>

      <Card size="small">
        <Table
          expandable
          rowKey="uuid"
          columns={columns}
          dataSource={queryResult}
          loading={!sharedConfigsLoaded}
        />
      </Card>
    </Space>
  );
}
