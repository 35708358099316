import { useEffect, useState } from 'react';
import { Button, Drawer, Space, notification } from 'antd';
import PropTypes from 'prop-types';
import CodeElement from './Codeelement';

export default function JSONParamEditor(props) {
  const { data, open, disabled, onClose } = props;
  const [parameters, setParameters] = useState('');

  const handleChange = (value) => {
    setParameters(value);
  };

  const handleClose = () => {
    try {
      if (!parameters.trim()) throw new Error('JSON cannot be empty');
      const newParameters = JSON.parse(parameters);
      onClose(newParameters, false);
    } catch (err) {
      notification.error({
        message: 'You have provided an invalid JSON',
        style: {
          fontWeight: 'normal',
        },
      });
    }
  };

  useEffect(() => {
    setParameters(JSON.stringify(data, null, 4));
  }, [data]);

  return (
    <Drawer
      title="Manually Edit Fields"
      placement="right"
      visible={open}
      onClose={() => onClose(data, true)}
      width="720"
      destroyOnClose
      footerStyle={{ textAlign: 'right' }}
      footer={
        <Space>
          <Button type="ghost" onClick={() => onClose(data, true)}>
            Cancel
          </Button>
          <Button type="primary" onClick={handleClose} disabled={disabled}>
            Save
          </Button>
        </Space>
      }
    >
      <CodeElement
        mode="json"
        value={parameters}
        onChange={handleChange}
        style={{ width: '100%' }}
        setOptions={{
          tabSize: 4,
          enableBasicAutocompletion: true,
          // enableLiveAutocompletion: true,
          showLineNumbers: true,
        }}
      />
    </Drawer>
  );
}

JSONParamEditor.propTypes = {
  data: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};
