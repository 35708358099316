import { makeStyles } from '@material-ui/core';
import { navigate } from 'hookrouter';
import { useSelector } from 'react-redux';

import { types } from './SharedField';
import { Card, Select } from 'antd';
import { selectProjectUuid } from '../Project/projectSlice';

const useStyles = makeStyles({
  select: {
    width: '100%',
    maxWidth: 400,
  },
});

export default function AddSharedField() {
  const classes = useStyles();
  const projectUuid = useSelector(selectProjectUuid);

  const handleTypeSelection = (value) => {
    navigate('/'+projectUuid+'/sharedfield/create/' + value);
  };

  return (
    <Card title="Create a shared field">
      <div className="w-full">
        <Select onChange={handleTypeSelection} className={classes.select} placeholder="Select a type">
          {types.map((type) => (
            <Select.Option key={type} value={type}>
              {type}
            </Select.Option>
          ))}
        </Select>
      </div>
    </Card>
  );
}
