import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import store from '../../../store.js';

import {
  changeProject,
  loadProjects,
  loadUserPermissions,
  selectProjects,
  selectProjectsLoading,
  selectProjectUuid,
} from './projectSlice';

import { Select, Typography, Space } from 'antd';

import { permissionValidator } from '../../functions/permissionValidator.js';

import { navigate } from 'hookrouter';

const { Text } = Typography;

export default function ProjectItem() {
  const projects = useSelector(selectProjects);
  const projectUUID = useSelector(selectProjectUuid);
  const projectsLoading = useSelector(selectProjectsLoading);

  permissionValidator(1, 2);

  function handleProjectChange(uuid) {
    let path = window.location.pathname.split('/');
    path[1] = uuid;
    navigate(path.join('/'));
  }

  useEffect(() => {
    store.dispatch(loadProjects());
    store.dispatch(loadUserPermissions());
  }, []);

  return (
    <Space align="center">
      <Text strong style={{ margin: 0 }}>
        Select Project:
      </Text>
      <Select
        onChange={handleProjectChange}
        value={projectUUID}
        style={{ width: 150 }}
        loading={projectsLoading}
      >
        {projects.map(({ uuid, alias }) => (
          <Select.Option key={uuid} value={uuid}>
            {alias}
          </Select.Option>
        ))}
      </Select>
    </Space>
  );
}
