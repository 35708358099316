import React from 'react';

import CreateTask from './CreateTask/CreateTask';
import EditTask from './EditTask/EditTask';
import SelectTaskEngine from './SelectTaskEngine';
import TaskDependencies from './TaskDependencies';

import TaskList from './TaskList/TaskList';

export default function TasksPage(props) {
  let Target = <div />;
  if (props.props.create) {
    Target = <SelectTaskEngine />;
    if (props.props.engineID) {
      Target = <CreateTask engineID={props.props.engineID} />;
    }
  } else if (props.props.edit) {
    Target = <EditTask taskID={props.props.taskID} />;
  } else if (props.props.dependencies) {
    Target = <TaskDependencies taskID={props.props.taskID} />;
  } else {
    Target = <TaskList />;
  }

  return <div>{Target}</div>;
}
