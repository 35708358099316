
// core components
import { useSelector } from 'react-redux'

import DescriptionItem from '../Description/DescriptionItem'


import { loadEngines, activateEngine, hideEngine } from './appManagementSlice'

import store from '../../../store.js'

import { Image, Divider, Button, Typography, Row, Col, Space, Card } from 'antd';
const { Title, Text } = Typography;
import { makeStyles } from "@material-ui/core/styles"
import styles from "../../assets/jss/material-dashboard-react/views/dashboardStyle.js";
const useStyles = makeStyles(styles);

export default function StatusPage() {
    let engines = useSelector((state) => state.appManagement.engines)
    let loaded = useSelector((state) => state.appManagement.loaded)

    if(!loaded) {
        store.dispatch(loadEngines())
    }

    let rows = []
    engines.forEach(engine => {
        function toggle() {
            if (engine.disabled) {
                store.dispatch(activateEngine(engine.uuid))
            } else {
                store.dispatch(hideEngine(engine.uuid))
            }
        }
        let iconURL = ''
        try {
            iconURL = engine.models[0].icons['1x'].url
        } catch (err) {
            iconURL = 'http://appdev.nomnomdata.com/static/favicon/apple-touch-icon.png'
        }
        rows.push(<Engine 
            iconURL={iconURL}
            alias={engine.models[0].alias}
            uuid={engine.uuid}
            description={engine.models[0].description}
            disabled={engine.disabled}
            toggle={toggle}
        />)
    })

    return (
      
        <Space direction='vertical' size={'large'}>
          <Card>
        <Row align='middle'>
            <Col span={30}>
            <DescriptionItem title={'App Management'} description='Manage global settings for engines' />
            </Col>
        </Row>
        </Card>
        <Card>
        {rows}
        </Card>
        </Space>
      
  );
}

function Engine(props) {
    return <div>
    <Row align='middle'>
      <Col span={1} />
      <Col span={4}>
        <Image
          width={40}
          src={props.iconURL}
        />
      </Col>
    <Col span={12}>
      <Title level={5} >{props.alias+' ['+props.uuid+']'}</Title>
      <Text type="secondary">{props.description}</Text>
    </Col>
    <Col span={2}/>
    <Col span={2}>
    <Button type={props.disabled ? '' : 'primary'} onClick={props.toggle}>{props.disabled ? 'Unhide' : 'Hide'}</Button>
    </Col>
  </Row>
  <Row>
    <Col span={1} />
  </Row>
  <Divider />
  </div>
}