import { useState } from 'react';

/**
 * useSearch hook to control filter-by-alias.
 * @param {object} props
 * @param {any[]} props.data data in the state
 */
export default function useSearch(props) {
  const data = props.data;
  const [queryResult, setQueryResult] = useState(null);

  const searchList = (field, query) => {
    query = query.toLowerCase();

    if (!query) {
      setQueryResult(data);
    } else {
      const list = data.filter((item) =>
        item[field].toLowerCase().includes(query),
      );
      setQueryResult(list);
    }
  };

  return { queryResult: queryResult || data, searchList };
}
