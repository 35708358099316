import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  Card,
  Row,
  Col,
  Input,
  Form,
  Space,
  Button,
  Divider,
  Dropdown,
  Menu,
} from 'antd';
import { green, red } from '@ant-design/colors';
import {
  DeleteTwoTone,
  EditOutlined,
  MenuOutlined,
  SaveOutlined,
  UnlockTwoTone,
} from '@ant-design/icons';

import { navigate } from 'hookrouter';

import {
  changeParameter,
  configureNewSharedConfig,
  createNewManyRow,
  hardSetParameters,
  deleteSharedConfig,
  resetEdit,
  resetSharedConfigs,
  selectSharedConfigEdit,
  selectSharedConfigsLoading,
} from '../sharedConfigSlice';
import { selectProjectUuid } from '../../Project/projectSlice';

import store from '../../../../store.js';

import { api } from '../../../api/api.js';
import logger from '../../../util/logger';
import ButtonConfirmation from '../../../components/ButtonConfirmation';
import JSONParamEditor from '../../../components/JSONParamEditor';
import toast from '../../../util/toasts';

// parameter renderers
import ManySection from './renderers/ManySection';
import ParameterSection from './renderers/ParameterSection';
import SingleParameterSection from './renderers/SingleParameter';

const { Group: BtnGroup } = Button;
const { useForm } = Form;

export default function EditSharedConfig(props) {
  const sharedConfigID = props.sharedConfigID;
  const projectUuid = useSelector(selectProjectUuid);

  const stateParameters = useSelector(
    (state) => state.sharedConfig.newParameters,
  );
  const manyParameters = useSelector(
    (state) => state.sharedConfig.manyParameters,
  );
  const sharedConfigs = useSelector(
    (state) => state.sharedConfig.sharedConfigs,
  );
  const sharedConfigsLoading = useSelector(selectSharedConfigsLoading);
  const newSharedConfigSet = useSelector(
    (state) => state.sharedConfig.newSharedConfigSet,
  );
  const { deleting: deletingSharedConfig, error: deletingError } = useSelector(
    selectSharedConfigEdit,
  );

  const [locked, setLocked] = useState(true);
  const [saving, setSaving] = useState(false);
  const [sharedConfigType, setSharedConfigType] = useState(null);
  const [paramEditorOpen, setParamEditorOpen] = useState(false);
  const [form] = useForm();

  const toggleSharedFieldLock = () => {
    setLocked(!locked);
  };

  const goToHistory = () => {
    navigate('./' + props.sharedConfigID + '/history');
  };

  const onChangeAlias = (e) => {
    const value = e.target.value;
    store.dispatch(
      changeParameter({
        parameter: 'alias',
        value,
      }),
    );
  };

  const save = () => {
    const sharedConfigID = props.sharedConfigID;
    let config = sharedConfigs.find((config) => {
      return config.uuid === sharedConfigID;
    });
    setSaving(true);
    // TODO: turn into proper actionreducer
    api.sharedConfigs
      .put(
        props.sharedConfigID,
        config.shared_config_type.uuid,
        stateParameters,
      )
      .then((res) => {
        store.dispatch(resetSharedConfigs());
        navigate('/' + projectUuid + '/sharedconfig');
      })
      .catch(() => {
        toast.error('Failed to update shared config.');
      })
      .finally(() => {
        setSaving(false);
      });
  };

  const handleDeleteSharedConfig = async () => {
    const { error } = await store.dispatch(deleteSharedConfig(sharedConfigID));
    if (error) {
      toast.error('Failed to delete shared config.');
      logger.error('Failed to delete shared config: ' + error.message);
    } else {
      // navigate to the list
      await store.dispatch(resetSharedConfigs());
      navigate('/' + projectUuid + '/sharedconfig');
    }
  };

  const openJSONParamEditor = () => {
    setParamEditorOpen(true);
  };

  const handleParamEditorClose = (data, wasCancelled) => {
    if (wasCancelled) {
      setParamEditorOpen(false);
      return;
    }

    store.dispatch(hardSetParameters(data));
    for (let name in data) {
      form.setFieldsValue({ [name]: data[name] });
    }
    setParamEditorOpen(false);
  };

  useEffect(() => {
    return () => {
      store.dispatch(resetEdit());
    };
  }, []);

  useEffect(() => {
    const config = sharedConfigs.find((config) => {
      return config.uuid === sharedConfigID;
    });

    if (!config && !sharedConfigsLoading) {
      navigate('/404', true);
      return;
    }

    if (config && !newSharedConfigSet) {
      const sharedConfigType = config.shared_config_type;

      // setup store
      store.dispatch(configureNewSharedConfig(sharedConfigType));
    } else if (!sharedConfigType && newSharedConfigSet) {
      store.dispatch(
        changeParameter({
          parameter: 'alias',
          value: config.parameters.alias || config.alias || '',
        }),
      );
      // store a mapping of a parameter to the section it belongs to
      // for fast lookup times

      const manyMap = {};
      Object.entries(manyParameters).forEach(([section, rows]) => {
        manyMap[section] = { section, rows: config.parameters[section] };
      });

      // dispatch actions to set the parameter values
      Object.entries(config.parameters).forEach(([key, value]) => {
        const isMany = key in manyMap;
        if (isMany) {
          const { rows } = manyMap[key];
          let params;

          for (let i = 0; i < rows.length; i++) {
            // create row entries
            store.dispatch(createNewManyRow(key));
          }

          for (let i = 0; i < rows.length; i++) {
            params = rows[i];
            Object.entries(params).forEach(([parameter, value]) => {
              store.dispatch(
                changeParameter({
                  many: true,
                  section: key,
                  index: i,
                  parameter,
                  value,
                }),
              );
            });
          }
        } else {
          store.dispatch(
            changeParameter({
              parameter: key,
              value,
            }),
          );
        }
      });

      setSharedConfigType(config.shared_config_type);
    }
  }, [sharedConfigs, sharedConfigsLoading, newSharedConfigSet]);

  if (!sharedConfigType) return null;

  let parameterCategories = [];
  let parameters = [];

  sharedConfigType.parameters.forEach((category) => {
    parameterCategories.push(category);
    if (category.name != 'alias') {
      if (category.many || category.type === 'array') {
        parameters.push(
          <ManySection
            key={category.name}
            section={category}
            parameterSet={true}
            locked={locked}
          />,
        );
      } else if (category.type === 'group' || category.type === 'nested') {
        parameters.push(
          <ParameterSection
            key={category.name}
            section={category}
            locked={locked}
          />,
        );
      } else {
        parameters.push(
          <SingleParameterSection
            key={category.name}
            parameter={category}
            state={stateParameters}
            locked={locked}
          />,
        );
      }
    }
  });

  const validationMessages = {
    required: '${label} is a required field',
  };

  //return <HistoryPage id={'test'} />

  return (
    <div>
      <JSONParamEditor
        open={paramEditorOpen}
        data={stateParameters}
        onClose={handleParamEditorClose}
        disabled={locked}
      />

      <Form
        form={form}
        layout="vertical"
        onFinish={save}
        validateMessages={validationMessages}
      >
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          <Card
            title={sharedConfigType.alias}
            extra={
              <BtnGroup>
                <Button onClick={goToHistory}>History</Button>
                <Button
                  icon={<UnlockTwoTone twoToneColor={green.primary} />}
                  onClick={toggleSharedFieldLock}
                >
                  {locked ? 'Unlock' : 'Lock'}
                </Button>
                <ButtonConfirmation
                  confirmationText="Are you sure you would like to delete this shared config?"
                  onOk={handleDeleteSharedConfig}
                >
                  <Button
                    icon={<DeleteTwoTone twoToneColor={red.primary} />}
                    loading={deletingSharedConfig}
                    disabled={saving}
                  >
                    Delete
                  </Button>
                </ButtonConfirmation>
                <Button
                  htmlType="submit"
                  style={{
                    backgroundColor: locked ? undefined : green[6],
                    color: locked ? undefined : '#fff',
                  }}
                  disabled={locked || deletingSharedConfig}
                  loading={saving}
                  icon={<SaveOutlined />}
                >
                  Save
                </Button>
                <Dropdown
                  trigger={['click', 'hover']}
                  overlay={
                    <Menu>
                      <Menu.Item
                        key="edit"
                        icon={<EditOutlined />}
                        onClick={() => openJSONParamEditor()}
                      >
                        Edit
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <Button>
                    <MenuOutlined />
                  </Button>
                </Dropdown>
              </BtnGroup>
            }
          >
            <Card.Meta description={sharedConfigType.description} />

            <Divider />

            <Row gutter={20}>
              <Col xs={24} lg={12}>
                <Form.Item
                  name="alias"
                  label={<strong>Alias</strong>}
                  rules={[
                    { required: true, message: 'Alias is a required field' },
                  ]}
                  initialValue={stateParameters.alias}
                  required
                >
                  <Input
                    size="large"
                    value={stateParameters.alias}
                    onChange={onChangeAlias}
                    disabled={locked}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Card>

          <Card title="Parameters">
            <Space direction="vertical" size="large" style={{ width: '100%' }}>
              {parameters}
            </Space>
          </Card>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              icon={<SaveOutlined />}
              loading={saving}
              disabled={locked || deletingSharedConfig}
            >
              Save
            </Button>
          </Form.Item>
        </Space>
      </Form>
    </div>
  );
}
