const loginStyles = {
  root: {
    width: 500,
  },
  fieldStyle: {
    marginBottom: 10,
    width: 470,
  },
  marginTop: {
    marginTop: 90,
  },
  loginButtonStyle: {
    width: 470,
  },
  forgotButtonStyle: {
    width: 220,
    marginRight: 10,
  },
  signupButtonStyle: {
    width: 220,
    marginLeft: 10,
  },
  backButtonStyle: {
    width: 470,
    marginTop: 20,
  },
  dividerStyle: {
    marginTop: 30,
    marginBottom: 30,
  },
  title: {
    fontSize: 18,
    textAlign: 'center',
    marginBottom: 15,
  },
}

export default loginStyles;