import { useEffect, useRef, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { Drawer, Typography } from 'antd';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';

import {
  configureTaskHelp,
  selectTaskHelp,
  setTaskHelpVisible,
} from './shared/tasksSlice';
import store from '../../../store';
import axios from 'axios';
import { ColumnWidthOutlined } from '@ant-design/icons';

const { Text, Paragraph } = Typography;

export default function TaskHelp(props) {
  const { model } = props;
  const drawerContentRef = useRef();
  const { open, parameterHeaderId, actionKey, documentation } =
    useSelector(selectTaskHelp);

  const selectedDoc = documentation ? documentation[actionKey] ?? null : null;

  const handleClose = () => {
    store.dispatch(setTaskHelpVisible(false));
  };

  useLayoutEffect(() => {
    if (selectedDoc && drawerContentRef.current) {
      const element = drawerContentRef.current;
      // h2 is for headers in the markdown
      const headers = element.querySelectorAll('h2, h3');
      const header = Array.from(headers).find((ele) => {
        return ele.textContent.trim() === parameterHeaderId.trim();
      });
      if (header) {
        header.scrollIntoView(true);
      }
    }
  }, [parameterHeaderId]);

  useEffect(() => {
    if (model) {
      const { md_files } = model;
      const hasDocumentation = Object.keys(md_files).length > 0;

      if (hasDocumentation) {
        const requests = Object.entries(md_files).map(([key, value]) => {
          return axios.get(value.url).then((resp) => ({ [key]: resp.data }));
        });

        Promise.all(requests)
          .then((results) => {
            const docs = results.reduce((docs, result) => ({
              ...docs,
              ...result,
            }));
            store.dispatch(configureTaskHelp(docs));
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  }, [model]);


  function toggleSize() {
    var element = document.getElementById("toggle_width");
    element.classList.toggle("toggle_width");
  }

  return (
    <Drawer
      placement="right"
      size="large"
      visible={open}
      onClose={handleClose}
      mask={false}
      maskClosable={false}
      destroyOnClose={false}
      style={{ fontWeight: 'normal' }}
      id="toggle_width"
    >
      <div ref={drawerContentRef}>
        <ColumnWidthOutlined className="toggle_width_btn" onClick={toggleSize} />
        {selectedDoc ? (
          <ReactMarkdown children={selectedDoc} />
        ) : (
          <NoDocumentationFound />
        )}
      </div>
    </Drawer>
  );
}

TaskHelp.propTypes = {};

function NoDocumentationFound() {
  return (
    <Paragraph>
      <Text>No documentation found.</Text>
    </Paragraph>
  );
}
