import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { navigate } from 'hookrouter';

import { Card, Button, Radio, Typography, notification } from 'antd';
import { DeleteOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { red } from '@ant-design/colors';
import { makeStyles } from '@material-ui/core/styles';

import store from '../../../store.js';
import {
  selectSequenceEdit,
  updateSequenceEdit,
  deleteSequence,
  resetSequences,
  loadSequence,
  loadSequences,
  loadAllSequences,
} from './sequencesSlice';
import { selectProjectUuid } from '../Project/projectSlice';
import { loadTasks } from '../Tasks/shared/tasksSlice.js';

import { api } from '../../api/api.js';
import logger from '../../util/logger.js';

import Loading from '../../components/Loading.js';
import SequenceForm from './SequenceForm.js';
import toast from '../../util/toasts.js';
import checkIfGraph from './shared/checkIfGraph.js';

export default function EditSequence(props) {
  const project_uuid = useSelector(selectProjectUuid);
  const {
    data: {
      alias,
      schedule,
      triggerSequence,
      triggerType,
      enabled: switchEnabled,
      tasks: sequenceTasks,
      taskGraph: sequenceTaskGraph,
    },
    deleting: deletingSequence,
    loading: sequenceLoading,
    rawSequenceTasks,
  } = useSelector(selectSequenceEdit);

  const isGraph = checkIfGraph(rawSequenceTasks);

  const getTasksError = () => {
    if (!sequenceTasks.length) return 'Must have at least one task in sequence';
    if (sequenceTasks.some((task) => !task))
      return 'Select or remove empty tasks before submitting';
    return null;
  };

  const handleSubmit = async () => {
    const error = getTasksError(sequenceTasks);
    if (error) {
      toast.error(error);
      return Promise.reject(error);
    }

    const sequenceTasksWithOrder = [];
    let count = 1;
    sequenceTasks.forEach((task) => {
      sequenceTasksWithOrder.push({ order_id: count, task_uuid: task });
      count += 1;
    });

    const handleError = ({ response = { data: {} } }) => {
      const { data } = response;
      let message;
      if (data.errors) {
        message = Object.values(data.errors.json)[0];
      } else {
        message =
          data.error ||
          'An error occured on our servers. We are working to fix it.';
      }
      if (message) {
        toast.error(message);
      }
    };

    let action;

    if (triggerType == 'cron') {
      if (isGraph) {
        action = api.sequences.putCronGraph(
          props.sequenceID,
          alias,
          switchEnabled,
          schedule,
          project_uuid,
          sequenceTaskGraph,
        );
      } else {
        action = api.sequences.putCron(
          props.sequenceID,
          alias,
          switchEnabled,
          schedule,
          project_uuid,
          sequenceTasksWithOrder,
        );
      }
    } else if (triggerType == 'sequence') {
      if (isGraph) {
        action = api.sequences.putSequenceGraph(
          props.sequenceID,
          alias,
          switchEnabled,
          triggerSequence,
          project_uuid,
          sequenceTaskGraph,
        );
      } else {
        action = api.sequences.putSequence(
          props.sequenceID,
          alias,
          switchEnabled,
          triggerSequence,
          project_uuid,
          sequenceTasksWithOrder,
        );
      }
    }

    try {
      await action;
      store.dispatch(resetSequences());
      navigate('/' + project_uuid + '/sequences');
    } catch (error) {
      return handleError(error);
    }
  };

  const handleDeleteSequence = async () => {
    const { error } = await store.dispatch(deleteSequence(props.sequenceID));
    if (error) {
      toast.error('Failed to delete sequence.');
      logger.error('Failed to delete sequence: ' + error.message);
    } else {
      // navigate to the list
      store.dispatch(resetSequences());
      navigate('/' + project_uuid + '/sequences');
    }
  };

  useEffect(() => {
    store.dispatch(
      loadSequence({
        projectUUID: project_uuid,
        sequenceUUID: props.sequenceID,
      }),
    );
    store.dispatch(loadSequences(project_uuid));
    store.dispatch(loadAllSequences());
    store.dispatch(loadTasks(project_uuid));
  }, [project_uuid]);

  if (sequenceLoading) return <Loading />;

  const selectSequenceData = (state) => selectSequenceEdit(state).data;

  return (
    <Card
      extra={
        <Button
          danger
          type="primary"
          icon={<DeleteOutlined twoToneColor={red.primary} />}
          onClick={handleDeleteSequence}
          loading={deletingSequence}
        >
          Delete
        </Button>
      }
    >
      <SequenceForm
        sequenceID={props.sequenceID}
        selectSequenceData={selectSequenceData}
        updateSequenceData={updateSequenceEdit}
        isGraph={isGraph}
        onSubmit={handleSubmit}
      />
    </Card>
  );
}
