import store from '../../store.js'

/*
    Returns true if permission is granted, false otherwise.
    Possible actions and resources can be checked in the corresponding permissions tab

    How actions and resources map onto UI elements is still TBD
*/

/*
    resources: task, policy, project, connection, sequence, sequence_execution, sequence_task, task_execution, system_logs, config, dashboard, group, shared_object, user
    actions: get, list, update, patch, delete, create, execute
*/

export function permissionValidator(action, resource) {
    let policies = store.getState().project.permissionRoles

    policies.forEach(policy => {
        if (policy.project_uuid == store.getState().project.projectUuid) {
            let actions = policy.actions
            let resources = policy.resources
            if (resources.indexOf('*') > -1 || resources.indexOf(resource) > -1) {
                if (actions.indexOf('*') > -1 || actions.indexOf(action) > -1) {
                    return true
                }
            }
        }
    })
    return false
}