import { useSelector } from 'react-redux'
import { navigate } from 'hookrouter'
import { Card, Select } from 'antd'

import { makeStyles } from '@material-ui/core/styles';

import store from '../../../store.js'

import { loadSharedConfigTypes } from './sharedConfigSlice'
import { selectProjectUuid } from '../Project/projectSlice';

const useStyles = makeStyles((theme) => ({
  select: {
    width: "100%",
    maxWidth: 400,
  },
}));

export default function AddSharedConfig() {
  const classes = useStyles();
  const projectUuid = useSelector(selectProjectUuid);

  const sharedConfigTypes = useSelector((state) => state.sharedConfig.sharedConfigTypes)
  if (sharedConfigTypes.length == 0) {
    store.dispatch(loadSharedConfigTypes())
  };

  const handleTypeSelection = (value) => {
    navigate('/'+projectUuid+'/sharedconfig/create/' + value);
  };

  return (
    <Card title="Create a shared config">
      <div className="w-full">
        <Select onChange={handleTypeSelection} className={classes.select} placeholder="Select a type">
          {sharedConfigTypes.map(({ uuid, alias }) => (
            <Select.Option key={uuid} value={uuid}>
              {alias}
            </Select.Option>
          ))}
        </Select>
      </div>
    </Card>
  );
}