import { node } from "prop-types";
import { CUSTOM_EMPTY_TYPE, EMPTY_EDGE_TYPE } from "./taskGraphConfig";

/**
 * @typedef {Object} Node
 * @property {string} id the uuid for the node
 * @property {string} type the graphic type of the node
 * @property {string} title the task title the node represents
 * @property {string} taskID the task uuid the node represents
 * @property {number} x x position
 * @property {number} y y position
 */

/**
 * @typedef {Object} Edge
 * @property {string} source the uuid for the source node
 * @property {string} target the uuid for the target node
 * @property {string} type the graphic type of the edge
 */

/**
 * @typedef {{ [key: string]: Set<string> }} Graph
 */

/**
 * 
 * @param {string} title the title of the node
 * @param {string} taskID the task uuid this node represents
 * @param {number} x x position
 * @param {number} y y position
 * @returns {Node}
 */
export const createNode = (title, taskID, x, y) => {
  return {
    id: generateNodeUUID(),
    type: CUSTOM_EMPTY_TYPE,
    title,
    taskID,
    x,
    y,
  };
};

/**
 * Computes the 2D centroid of the graph represented by 
 * the node array.
 * @param {Node[]} nodes an array of {@link Node}s.
 * @returns {{ x: number; y: number }}
 */
export const computeGraphCentroid = (nodes) => {
  const sumOfXs = nodes.reduce((sum, node) => sum + node.x, 0);
  const sumOfYs = nodes.reduce((sum, node) => sum + node.y, 0);
  const x = nodes.length ? sumOfXs / nodes.length : 0;
  const y = nodes.length ? sumOfYs / nodes.length : 0;
  return { x, y };
};

/**
 * Creates a graph object from the supplied nodes and edges.
 * @param {Node[]} nodes an array of {@link Node}s
 * @param {Edge[]} edges an array of {@link Edge}s
 * @returns {Graph} an object mapping
 * a node to a set of adjacent nodes.
 */
export const createGraphFromEdges = (nodes, edges) => {
  const graph = {};
  const nonRoots = new Set();

  for (let node of nodes) {
    graph[node.id] = new Set();
  }

  for (let edge of edges) {
    const { source, target } = edge;
    graph[source].add(target);
    nonRoots.add(target);
  }

  return graph;
};

/**
 * Checks if there are cycles in a graph
 * @param {Graph} graph the graph
 * @param {Node[]} nodes an array of the nodes in the graph
 * @returns {boolean} whether or not the graph has cycles
 */
export const checkCyclesInGraph = (graph, nodes) => {
  const visited = {};
  const stack = {};

  for (const node of nodes) {
    if (!visited[node]) {
      if (detectCycle(graph, node, visited, stack)) {
        return true;
      }
    }
  }

  return false;
};

const detectCycle = (graph, node, visited, stack) => {
  visited[node] = true;
  stack[node] = true;

  for (const neighbor of graph[node]) {
    if (!visited[neighbor]) {
      if (detectCycle(graph, neighbor, visited, stack)) {
        return true;
      }
    } else if (stack[neighbor]) {
      return true;
    }
  }

  stack[node] = false;
  return false;
};

/**
 * 
 * @param {string} source the uuid of the source node
 * @param {string} target the uuid of the target node
 * @returns {Edge}
 */
export const createEdge = (source, target) => {
  return {
    source,
    target,
    type: EMPTY_EDGE_TYPE,
  };
}

const generateNodeUUID = () => {
  return String(Date.now());
};