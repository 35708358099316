import { navigate } from 'hookrouter';
import { Tabs } from 'antd';

const { TabPane } = Tabs;

export default function TaskTabs({ projectID, taskID, tab }) {
  const onChange = (key) => {
    switch (key) {
      case 'edit':
        navigate(`/${projectID}/tasks/${taskID}`);
        break;
      case 'dependencies':
        navigate(`/${projectID}/tasks/dependencies/${taskID}`);
        break;
      default:
        break;
    }
  };

  return (
    <Tabs
      defaultActiveKey="edit"
      activeKey={tab}
      size="large"
      tabBarStyle={{
        margin: 0,
      }}
      onChange={onChange}
    >
      <TabPane tab="Edit" key="edit"></TabPane>
      <TabPane tab="Dependencies" key="dependencies"></TabPane>
    </Tabs>
  );
}
