import PropTypes from 'prop-types';
import { useRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import {
  Row,
  Col,
  Button,
  Typography,
  Menu,
  Dropdown,
  notification,
} from 'antd';
import { grey, blue } from '@ant-design/colors';
import { CopyOutlined, DownloadOutlined } from '@ant-design/icons';

import { resetExecutionLogStream, selectExecutionLogs, startExecutionLogStream } from './executionSlice';
import { selectProjectUuid } from '../Project/projectSlice';

import store from '../../../store.js';
import {
  card,
  successColor,
  dangerColor,
} from '../../assets/jss/material-dashboard-react';

const { Text } = Typography;

const useStyles = makeStyles({
  logsSection: {
    ...card,
    display: 'block',
    width: '100%',
    color: '#fff',
    fontWeight: 'normal',
    backgroundColor: '#191F28',
  },
  logsSectionHeader: {
    padding: '1rem 1.6rem',
    backgroundColor: '#1D2426',
    borderTopLeftRadius: card.borderRadius,
    borderTopRightRadius: card.borderRadius,
  },
  logsOuterContainer: {
    padding: '1rem 1rem',
    height: '434px',
    overflow: 'auto',
  },
  logsContainer: {
    padding: '0.4rem 0',
  },
  logEntry: {
    position: 'relative',
    padding: '0.2rem 1rem',
    whiteSpace: 'pre',
    fontWeight: 'normal',
    fontSize: '0.9rem',
    width: 'fit-content',
    minWidth: '100%',
    '&:hover, &[data-selected=true]': {
      backgroundColor: '#4b515a',
    },
    '& > div': {
      display: 'inline-block',
      textAlign: 'right',
      width: '2.5rem',
      padding: '0 0.8rem',
      marginRight: '15px',
      color: grey[2],
    },
    '&:hover > div': {
      color: '#FFFDFA',
    },
  },
  copiedNotification: {
    maxWidth: '100%',
    width: 'fit-content',
    fontWeight: 'normal',
    background: successColor[2],
    '& *': {
      color: '#fff',
    },
  },
});

export default function ExecutionLogs(props) {
  const { executionID } = props;
  const classes = useStyles();
  const allExecutionLogs = useSelector(selectExecutionLogs);
  const projectID = useSelector(selectProjectUuid);
  const [selectedLog, setSelectedLog] = useState(null);

  const executionLogs = allExecutionLogs[executionID] || [];

  const jsonDownloadURL = `/api/v2/projects/${projectID}/logs/execution/${executionID}?formatting=json`;
  const textDownloadURL = `/api/v2/projects/${projectID}/logs/execution/${executionID}?formatting=human`;


  const downloadMenu = (
    <Menu>
      <Menu.Item key="json">
        <a href={jsonDownloadURL} target="_blank" rel="noopener noreferrer">
          JSON
        </a>
      </Menu.Item>
      <Menu.Item key="text">
        <a href={textDownloadURL} target="_blank" rel="noopener noreferrer">
          Text
        </a>
      </Menu.Item>
    </Menu>
  );

  const copySelectedLog = () => {
    if (selectedLog) {
      const log = executionLogs.find((log) => log._id === selectedLog);
      if (log) {
        navigator.clipboard.writeText(JSON.stringify(log.data, null, 4));
        notification.success({
          message: '1 row copied to the clipboard.',
          className: classes.copiedNotification,
          icon: <CopyOutlined />,
        });
      }
    }
  };

  useEffect(() => {
    if (!allExecutionLogs[executionID]) {
      store.dispatch(startExecutionLogStream(executionID));
    }
  }, [executionID]);

  return (
    <section className={classes.logsSection}>
      <Row
        justify="space-between"
        align="middle"
        className={classes.logsSectionHeader}
      >
        <Col>
          <Text strong style={{ color: '#fff' }}>
            Logs
          </Text>
        </Col>
        <Col>
          <Button.Group>
            <Dropdown overlay={downloadMenu}>
              <Button type="default" ghost icon={<DownloadOutlined />}>
                Download
              </Button>
            </Dropdown>
          </Button.Group>
        </Col>
      </Row>

      <div className={classes.logsOuterContainer}>
        <div className={classes.logsContainer}>
          {executionLogs.map((log, i) => (
            <div
              key={log._id}
              className={classes.logEntry}
              onClick={() => setSelectedLog(log.line)}
              data-selected={log.line === selectedLog}
              onBlur={() => setSelectedLog(null)}
            >
              <div>{i + 1}</div>
              <span
                style={{
                  paddingRight: '0.8rem',
                  color: log.level === 'INFO' ? '#FFFDFA' : dangerColor[2],
                }}
              >
                {log.line}
              </span>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

ExecutionLogs.propTypes = {
  executionID: PropTypes.string.isRequired,
};
