import React from 'react';
import { Divider, Typography } from 'antd';
import { ErrorBoundary } from 'react-error-boundary';

const { Text, Title } = Typography;
const FallbackComponent = (props) => {
  const { componentStack, error } = props;
  return (
    <div>
      <Title type="danger" level={3}>
        Something went wrong.
      </Title>
      <Text strong>Here’s what we know…</Text>
      <Divider />
      <details style={{ whiteSpace: 'pre-wrap', cursor: 'pointer' }}>
        <Text>
          <Text strong>Error: </Text>
          {error.toString()}
        </Text>
        <br />
        <Text>
          <Text strong>Stacktrace: </Text>
          {componentStack}
        </Text>
      </details>
    </div>
  );
};

const handleError = (error, componentStack) => {
  // callApi('/nominode/ui-logs', 'PUT', {
  //   error: error.toString(),
  //   componentStack,
  //   requestUrl: window.location.href,
  // }).catch((err) => console.error(err));
};

export default (props) => {
  const { children } = props;
  return (
    <ErrorBoundary FallbackComponent={FallbackComponent} onError={handleError}>
      {children}
    </ErrorBoundary>
  );
};
