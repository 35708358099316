const styles = {
  upperGrid: {
    width: '100%',
  },
  upperGridInput: {
    width: '100%',
  },
  modelAlias: {
    fontSize: '1rem',
  },
  modelDesc: {
    fontSize: '0.9rem',
    fontWeight: '300',
  },
};

export default styles;