import PropTypes from 'prop-types';
import { Button, Col, Popover, Row, Space } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { useEffect, useState } from 'react';

export default function ButtonConfirmation(props) {
  const { children, confirmationText, onOk, onCancel } = props;

  const [visible, setVisible] = useState(false);

  const handleClose = () => {
    setVisible(false);
  };

  const handleOk = () => {
    handleClose();
    onOk();
  };

  const handleCancel = () => {
    handleClose();
    onCancel && onCancel();
  };

  const handleVisibilityChange = (visible) => {
    setVisible(visible);
  };

  const popoverContent = (
    <Space direction="vertical">
      <div>
        <ExclamationCircleFilled style={{ color: '#faad14' }} />{' '}
        {confirmationText}
      </div>
      <Row justify="end" gutter={[10, 10]}>
        <Col>
          <Button type="default" size="small" onClick={handleCancel}>
            No
          </Button>
        </Col>
        <Col>
          <Button type="primary" size="small" onClick={handleOk}>
            Yes
          </Button>
        </Col>
      </Row>
    </Space>
  );

  return (
    <Popover
      content={popoverContent}
      placement="left"
      trigger={['click']}
      visible={visible}
      onVisibleChange={handleVisibilityChange}
    >
      {children}
    </Popover>
  );
}

ButtonConfirmation.propTypes = {
  confirmationText: PropTypes.string.isRequired,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
};
