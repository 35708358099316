import { useState, useEffect, useMemo, useRef } from 'react';
import { QuestionOutlined } from '@ant-design/icons';
import { Button, Card, Typography, Select, Space, Divider } from 'antd';
import { blue } from '@ant-design/colors';
import { makeStyles } from '@material-ui/core/styles';
import { GraphView } from 'react-digraph';

import store from '../../../../store';
import { selectProjectUuid } from '../../Project/projectSlice';
import {
  default as nodeConfig,
  EMPTY_EDGE_TYPE,
  CUSTOM_EMPTY_TYPE,
  NODE_KEY,
} from './taskGraphConfig';
import TaskGraphInfo from './TaskGraphInfo';
import * as graphUtil from './taskGraphUtil';
import graphStyles from './styles';

const { Option } = Select;
const { Paragraph, Text } = Typography;

const useClasses = makeStyles(graphStyles);

export default function TaskGraph(props) {
  const { taskGraph, tasks, loadingTasks, values, updateSequenceData } = props;
  const classes = useClasses();
  const graphRef = useRef();

  const [graphInfoOpen, setGraphInfoOpen] = useState(false);
  const [newSelectedTask, setNewSelectedTask] = useState(null);
  const [selected, setSelected] = useState(null);
  const [selectedTask, setSelectedTask] = useState({
    node: null,
    newTaskID: null,
  });
  const { nodes: taskNodes, edges: taskEdges } = taskGraph;

  const tasksAliasMap = useMemo(() => {
    const obj = tasks.reduce((obj, task) => {
      obj[task.uuid] = task.alias;
      return obj;
    }, {});
    return obj;
  }, [tasks]);

  const nodes = taskNodes.map((node) => ({
    ...node,
    title: node.title || tasksAliasMap[node.taskID],
    type: node.type || CUSTOM_EMPTY_TYPE,
  }));

  const edges = taskEdges.map((edge) => ({
    ...edge,
    type: edge.type || EMPTY_EDGE_TYPE,
  }));

  const taskOptions = tasks.map(({ uuid, alias }) => (
    <Option key={uuid} value={uuid}>
      {alias}
    </Option>
  ));

  useEffect(() => {
    const filledNodes = nodes.map((node) => ({
      ...node,
      title: node.title || tasksAliasMap[node.taskID],
      type: node.type || CUSTOM_EMPTY_TYPE,
    }));

    const filledEdges = edges.map((edge) => ({
      ...edge,
      type: edge.type || EMPTY_EDGE_TYPE,
    }));

    store.dispatch(
      updateSequenceData({
        taskGraph: {
          nodes: filledNodes,
          edges: filledEdges,
        },
      }),
    );
  }, []);

  useEffect(() => {
    if (selected && selected.nodes) {
      const selectedNodes = Array.from(selected.nodes.values());
      setSelectedTask({
        node: selectedNodes[0],
        newTaskID: selectedNodes[0].taskID,
      });
    } else {
      setSelectedTask({
        node: null,
        newTaskID: null,
      });
    }
  }, [selected]);

  const setSelectedTaskNewTask = (value) => {
    setSelectedTask({
      ...selectedTask,
      newTaskID: value,
    });
  };

  const handleGraphInfoClose = () => {
    setGraphInfoOpen(false);
  };

  const handleInfoButtonClick = () => {
    setGraphInfoOpen(true);
  };

  /**
   * Called when the user click the button to add a task
   * @returns {void}
   */
  const addTask = () => {
    if (!newSelectedTask) return;

    setNewSelectedTask(null);

    const uuid = newSelectedTask;
    const task = tasksAliasMap[uuid];

    let centroid;
    if (nodes.length) {
      centroid = graphUtil.computeGraphCentroid(nodes);
    } else {
      centroid = {
        x: 100,
        y: 100,
      };
    }
    const { x, y } = centroid;
    const newNode = graphUtil.createNode(task.alias, uuid, x, y);

    store.dispatch(
      updateSequenceData({
        tasks: [uuid, ...values],
        taskGraph: {
          nodes: [newNode, ...nodes],
          edges,
        },
      }),
    );
  };

  /**
   * Called when the user selects a task from the dropdown
   * menu.
   * @param {string} taskID the task id
   */
  const handleNewSelectedTaskChange = (taskID) => {
    setNewSelectedTask(taskID);
  };

  const updateSelectedTask = () => {
    const {
      node: { id: nodeID },
      newTaskID: cur,
    } = selectedTask;

    const newTask = tasksAliasMap[cur];

    const idx = nodes.findIndex((node) => node.id === nodeID);
    const newNodes = [...nodes];
    newNodes[idx] = {
      ...newNodes[idx],
      taskID: cur,
      title: newTask.alias,
    };

    store.dispatch(
      updateSequenceData({
        taskGraph: {
          nodes: newNodes,
          edges,
        },
      }),
    );

    setSelectedTask({ node: newNodes[idx], newTaskID: cur });
  };

  const onSelect = (obj) => {
    const { nodes, edges } = obj;

    if (!nodes && !edges) {
      setSelected(null);
    } else {
      setSelected(obj);
    }
  };

  const onCreateNode = () => {};

  const onUpdateNode = (node) => {
    const i = getNodeIndex(node);
    nodes[i] = node;
    store.dispatch(
      updateSequenceData({
        taskGraph: {
          nodes,
          edges,
        },
      }),
    );
  };

  const getNodeIndex = (node) => nodes.findIndex((n) => n.id === node.id);

  const onDeleteSelected = (selection) => {
    const { nodes: _selectedNodes, edges: _selectedEdges } = selection;

    const selectedNodes = _selectedNodes
      ? Array.from(_selectedNodes.values())
      : null;

    const selectedEdges = _selectedEdges
      ? Array.from(_selectedEdges.values())
      : null;

    const newNodes = selectedNodes
      ? nodes.filter(({ id }) => {
          return !selectedNodes.find((node) => node.id === id);
        })
      : nodes;

    let newEdges = selectedEdges
      ? edges.filter(({ source, target }) => {
          return !selectedEdges.find(
            (e) => e.source === source && e.target === target,
          );
        })
      : edges;

    // remove orphaned edges (edges who have lost their sources or
    // targets)
    if (selectedNodes) {
      newEdges = newEdges.filter(({ source, target }) => {
        return !selectedNodes.find(
          (node) => node.id === source || node.id === target,
        );
      });
    }

    store.dispatch(
      updateSequenceData({
        taskGraph: {
          nodes: newNodes,
          edges: newEdges,
        },
      }),
    );
  };

  const onCreateEdge = (sourceNode, targetNode) => {
    const edge = graphUtil.createEdge(sourceNode.id, targetNode.id);

    if (edge.source !== edge.target) {
      const newEdges = [edge, ...edges];
      const graph = graphUtil.createGraphFromEdges(nodes, newEdges);
      const isCyclic = graphUtil.checkCyclesInGraph(
        graph,
        nodes.map((node) => node.id),
      );

      if (!isCyclic) {
        store.dispatch(
          updateSequenceData({
            taskGraph: {
              nodes,
              edges: newEdges,
            },
          }),
        );
      }
    }
  };

  return (
    <Space direction="vertical" className={classes.content}>
      <TaskGraphInfo open={graphInfoOpen} onClose={handleGraphInfoClose} />
      <Card size="small" loading={loadingTasks}>
        <div className={classes.editor}>
          <div style={{ flex: 1 }}>
            <div className={classes.graphContainer}>
              <GraphView
                ref={graphRef}
                showGraphControls={false}
                gridDotSize={1}
                minZoom={0.8}
                maxZoom={2}
                renderNodeText={false}
                nodes={nodes}
                edges={edges}
                nodeKey={NODE_KEY}
                selected={selected}
                nodeTypes={nodeConfig.NodeTypes}
                nodeSubtypes={nodeConfig.NodeSubtypes}
                edgeTypes={nodeConfig.EdgeTypes}
                onSelect={onSelect}
                onCreateNode={onCreateNode}
                onUpdateNode={onUpdateNode}
                onDeleteSelected={onDeleteSelected}
                onCreateEdge={onCreateEdge}
                readOnly={false}
                maxTitleChars={100}
                //renderNode={renderNode}
              />
              <Button
                icon={<QuestionOutlined style={{ color: blue.primary }} />}
                className={classes.graphInfoButton}
                onClick={handleInfoButtonClick}
              ></Button>
            </div>

            <Paragraph className={classes.graphHelp}>
              <Text italic className={classes.smallText}>
                Hold <Text keyboard>Shift</Text> and click on a starting
                node, then drag to another node to make the second task's
                execution depend on the first task. Use scroll wheel or pinch to
                zoom.
              </Text>
            </Paragraph>
          </div>
          <div className={classes.sidebarContainer}>
            <Space direction="vertical" style={{ width: '100%' }}>
              <Paragraph>
                <h6 className={classes.sidebarInputLabel}>
                  Add Node With Task
                </h6>
                <Space direction="vertical" style={{ width: '100%' }}>
                  <Select
                    showSearch
                    value={newSelectedTask}
                    loading={loadingTasks}
                    onChange={(val) => handleNewSelectedTaskChange(val)}
                    optionFilterProp="children"
                  >
                    {taskOptions}
                  </Select>
                  <Button
                    type="primary"
                    className={classes.newTaskButton}
                    onClick={addTask}
                    disabled={!newSelectedTask}
                  >
                    Add
                  </Button>
                </Space>
              </Paragraph>

              <Divider />

              <Paragraph>
                <h6 className={classes.sidebarInputLabel}>
                  Update Node To Task
                </h6>
                {!selectedTask.node ? (
                  <Text italic className={classes.smallText}>
                    Select task node to edit
                  </Text>
                ) : (
                  <Space direction="vertical" style={{ width: '100%' }}>
                    <Select
                      showSearch
                      value={selectedTask.newTaskID}
                      loading={loadingTasks}
                      onChange={(val) => setSelectedTaskNewTask(val)}
                      optionFilterProp="children"
                    >
                      {taskOptions}
                    </Select>
                    <Button
                      type="primary"
                      className={classes.newTaskButton}
                      onClick={updateSelectedTask}
                    >
                      Update
                    </Button>
                  </Space>
                )}
              </Paragraph>
            </Space>
          </div>
        </div>
      </Card>
    </Space>
  );
}
