import { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { navigate } from 'hookrouter';
import { Card, Row, Col, Input, Form, Select, Space, Button } from 'antd';
import { green, red } from '@ant-design/colors';
import {
  DeleteTwoTone,
  ForkOutlined,
  SaveOutlined,
  LockTwoTone,
  UnlockTwoTone,
} from '@ant-design/icons';

import { makeStyles } from '@material-ui/core/styles';

import store from '../../../store.js';

import { api } from '../../api/api';

import FieldFactory from './FieldFactory.js';
import { selectProjectUuid } from '../Project/projectSlice';

import {
  changeAlias,
  changeParameters,
  changeOptions,
  changeCategories,
  setChangeField,
  resetEdit,
  deleteSharedField,
  selectSharedFieldEdit,
  resetSharedFields,
} from './sharedFieldSlice';
import Loading from '../../components/Loading.js';
import logger from '../../util/logger';
import ButtonConfirmation from '../../components/ButtonConfirmation.js';
import toast from '../../util/toasts.js';

const { Group: BtnGroup } = Button;

export default function SharedFieldEdit(props) {
  const sharedFieldID = props.sharedFieldID;
  const projectUuid = useSelector(selectProjectUuid);

  const useStyles = makeStyles((theme) => ({
    margin: {
      margin: 8,
      minWdith: 500,
    },
  }));
  const classes = useStyles();
  const sharedFields = useSelector((state) => state.sharedField.sharedFields);
  const changeField = useSelector((state) => state.sharedField.changeField);
  const changeFieldSet = useSelector(
    (state) => state.sharedField.changeFieldSet,
  );
  const { deleting: deletingSharedField, error: deleteError } = useSelector(
    selectSharedFieldEdit,
  );

  const [fieldCategories, setFieldCategories] = useState([]);
  const [loadingCategories, setLoadingCategories] = useState(true);
  const [saving, setSaving] = useState(false);

  const [locked, setLocked] = useState(true);

  const onChangeAlias = (e) => {
    const value = e.target.value;
    store.dispatch(changeAlias(value));
  };

  const onChangeCategories = (value) => {
    store.dispatch(changeCategories(value));
  };

  const onChangeParameters = (value) => {
    store.dispatch(changeParameters(value));
  };

  const onChangeOptions = (value) => {
    store.dispatch(changeOptions(value));
  };

  const save = () => {
    setSaving(true);
    // TODO: turn into proper actionreducer
    api.sharedFields.put(changeField, changeField.uuid).then((res) => {
      store.dispatch(resetSharedFields());
      navigate('/' + projectUuid + '/sharedfield');
    })
    .catch(() => {
      toast.error('Failed to update the shared field.');
    })
    .finally(() => {
      setSaving(false);
    });
  };

  const handleDeleteSharedField = async () => {
    const { error } = await store.dispatch(deleteSharedField(sharedFieldID));
    if (error) {
      toast.error('Failed to delete shared field.');
      logger.error('Failed to delete shared field: ' + error.message);
    } else {
      // navigate to the list
      store.dispatch(resetSharedFields());
      navigate('/' + projectUuid + '/sharedfield');
    }
  };

  const toggleSharedFieldLock = () => {
    setLocked(!locked);
  };

  useEffect(() => {
    return () => {
      store.dispatch(resetEdit());
    };
  }, []);

  useEffect(() => {
    api.sharedFields
      .categories()
      .then(({ results }) => setFieldCategories(results.map((res) => res.name)))
      .finally(() => setLoadingCategories(false));
  }, []);

  useEffect(() => {
    const sharedFieldID = props.sharedFieldID;
    let field = sharedFields.find((field) => {
      return field.uuid === sharedFieldID;
    });
    if (field && !changeFieldSet) {
      store.dispatch(setChangeField(field));
    }
  }, [sharedFields, changeFieldSet]);

  if (!changeField || !changeField['field_type']) return <Loading />;

  const {
    alias,
    parameters,
    categories,
    options,
    field_type: type,
  } = changeField;

  const fieldElement = FieldFactory.create(type, {
    value: parameters,
    onChange: onChangeParameters,
    onOptionsChange: onChangeOptions,
    options,
    disabled: locked,
  });

  const typeCapitalized = type.charAt(0).toUpperCase() + type.substr(1);
  const cardTitle = typeCapitalized + ' Type';

  const validationMessages = {
    required: '${label} is a required field',
  };

  const isFullWidth = ['sql', 'json', 'text', 'code'].includes(type);

  return (
    <div>
      <Form
        layout="horizontal"
        validateMessages={validationMessages}
        onFinish={save}
      >
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          <Card
            title={cardTitle}
            extra={
              <BtnGroup>
                <Button
                  icon={
                    locked ? (
                      <UnlockTwoTone twoToneColor={green.primary} />
                    ) : (
                      <LockTwoTone twoToneColor={green.primary} />
                    )
                  }
                  onClick={toggleSharedFieldLock}
                >
                  {locked ? 'Unlock' : 'Lock'}
                </Button>
                {/* <Button icon={<ForkOutlined />} disabled={deletingSharedField}>
                  Clone
                </Button> */}
                <ButtonConfirmation
                  confirmationText="Are you sure you would like to delete this shared field?"
                  onOk={handleDeleteSharedField}
                >
                  <Button
                    icon={<DeleteTwoTone twoToneColor={red.primary} />}
                    loading={deletingSharedField}
                    disabled={saving}
                  >
                    Delete
                  </Button>
                </ButtonConfirmation>
                <Button
                  style={{
                    backgroundColor: locked ? undefined : green[6],
                    color: locked ? undefined : '#fff',
                  }}
                  disabled={locked || deletingSharedField}
                  htmlType="submit"
                  loading={saving}
                  icon={<SaveOutlined />}
                >
                  Save
                </Button>
              </BtnGroup>
            }
          >
            <Row gutter={20}>
              <Col xs={24} lg={12}>
                <Form.Item
                  name="alias"
                  label={<strong>Alias</strong>}
                  rules={[{ required: true }]}
                  initialValue={alias}
                  required
                >
                  <Input
                    value={alias}
                    onChange={onChangeAlias}
                    disabled={locked}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Card>

          <Card>
            <Form.Item
              name="field"
              label={<strong>{typeCapitalized}</strong>}
              style={{ width: '100%' }}
              required
            >
              <Row>
                <Col xs={24} lg={isFullWidth ? 24 : 12}>
                  {fieldElement}
                </Col>
              </Row>
            </Form.Item>
          </Card>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              icon={<SaveOutlined />}
              loading={saving}
              disabled={locked || deletingSharedField}
            >
              Save
            </Button>
          </Form.Item>
        </Space>
      </Form>
    </div>
  );
}
