import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { A } from 'hookrouter';
import ReactDragListView from 'react-drag-listview';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Tooltip, List, Form, Input, Select, Space } from 'antd';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import store from '../../../store';
import { selectProjectUuid } from '../Project/projectSlice';

const { Option } = Select;

const useClasses = makeStyles({
  contentCard: {
    background: '#eee',
  },
  content: {
    width: '100%',
  },
  newTaskButton: {
    width: '100%',
  },
  listItem: {
    cursor: 'grab',
    userSelect: 'none',
    backgroundColor: '#fff',
    marginBottom: 10,
  },
  formItem: {
    marginBottom: 0,
    width: '70%',
  },
});

export default function TaskArray(props) {
  const { tasks, loadingTasks, values, updateSequenceData } = props;
  const classes = useClasses();
  const projectUuid = useSelector(selectProjectUuid);
  const [blurred, setBlurred] = useState([]);

  useEffect(() => {
    setBlurred(values.map(() => false));
  }, []);

  const addTask = () => {
    const items = [...values, ''];
    store.dispatch(updateSequenceData({ tasks: items }));
    setBlurred([...blurred, false]);
  };

  const handleDragEnd = (fromIndex, toIndex) => {
    if (toIndex < 0 || toIndex >= tasks.length) return; // Ignores if outside designated area

    const items = [...values];
    const item = items.splice(fromIndex, 1)[0];
    items.splice(toIndex, 0, item);
    store.dispatch(updateSequenceData({ tasks: items }));
  };

  const handleChange = (task, index) => {
    const items = [...values];
    items[index] = task;
    store.dispatch(updateSequenceData({ tasks: items }));
  };

  const handleRemove = (index) => {
    const items = values.filter((_, i) => i !== index);
    store.dispatch(updateSequenceData({ tasks: items }));
    setBlurred(blurred.filter((_, i) => i !== index));
  };

  const handleBlur = (index) => {
    const items = [...blurred];
    items[index] = true;
    setBlurred(items);
  };

  const taskOptions = tasks.map(({ uuid, alias }) => (
    <Option key={uuid} value={uuid}>
      {alias}
    </Option>
  ));

  const renderTaskRow = (item, index) => {
    const help =
      !item && blurred[index] ? 'Select or remove task before submitting' : '';
    const status = !item && blurred[index] ? 'error' : 'validating';

    return (
      <List.Item
        className={classNames(classes.listItem, 'draggable')}
        actions={[
          !!item ? (
            <A
              href={`${projectUuid}/tasks/${item}`}
              rel="noopener noreferrer"
              target="_blank"
            >
              Details
            </A>
          ) : (
            'Details'
          ),
          <Button
            danger
            type="primary"
            icon={<CloseOutlined />}
            onClick={() => handleRemove(index)}
          ></Button>,
        ]}
      >
        <Form.Item
          required
          colon={false}
          className={classes.formItem}
          wrapperCol={{ span: 24 }}
          validateStatus={status}
          {...(help && { help })}
        >
          <Select
            showSearch
            value={item}
            loading={loadingTasks}
            optionFilterProp="children"
            onChange={(val) => handleChange(val, index)}
            onBlur={() => handleBlur(index)}
          >
            {taskOptions}
          </Select>
        </Form.Item>
      </List.Item>
    );
  };

  return (
    <Card size="small" className={classes.contentCard}>
      <Space direction="vertical" className={classes.content}>
        <Tooltip title="Click to add task">
          <Button
            type="primary"
            className={classes.newTaskButton}
            icon={<PlusOutlined />}
            onClick={addTask}
          ></Button>
        </Tooltip>

        <ReactDragListView
          nodeSelector=".ant-list-item.draggable"
          onDragEnd={handleDragEnd}
        >
          <List
            size="small"
            locale={{ emptyText: 'No Tasks' }}
            loading={loadingTasks}
            dataSource={values}
            renderItem={renderTaskRow}
          />
        </ReactDragListView>
      </Space>
    </Card>
  );
}
