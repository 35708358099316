// @material-ui/core
import { makeStyles } from "@material-ui/core/styles"

import styles from "../../assets/jss/material-dashboard-react/views/dashboardStyle.js";

import DescriptionItem from '../Description/DescriptionItem'
import EnginesContainer from './EnginesContainer'

const useStyles = makeStyles(styles);

import { Row, Col, Space, Card } from 'antd';

export default function EnginesPage() {
  const classes = useStyles();
  return (
    <div>
      <Space direction='vertical' size={'large'}>
        <Card>
      <Row align='middle'>
        <Col span={20}>
          <DescriptionItem classes={classes} title={'Apps'} description='Available Apps on your Nominode.' />
        </Col>
      </Row>
      </Card>
        <EnginesContainer classes={classes} />
      </Space>
    </div>
  );
}
