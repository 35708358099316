import {
  createSlice,
  createAsyncThunk,
  createDraftSafeSelector,
} from '@reduxjs/toolkit'
  
import { api } from '../../api/api'
import { selectProjectUuid } from '../Project/projectSlice'
  
export const loadSequences = createAsyncThunk('sequences/loadSequences', async (projectUuid) => {
  const response = await api.sequences(projectUuid)
  return response
})

export const loadAllSequences = createAsyncThunk('sequences/loadAllSequences', async () => {
    const response = await api.sequences.getAll()
    return response
  })

export const loadSequence = createAsyncThunk('sequences/loadSequence', async (props) => {
    const response = await api.sequences.getOne(props.projectUUID, props.sequenceUUID)
    return response
})

export const runSequence = createAsyncThunk('sequences/runSequence', async (sequenceID, thunkApi) => {
    const projectID = selectProjectUuid(thunkApi.getState())
    try {
        const sequence = await api.sequences.run(projectID, sequenceID);
        return sequence;
    } catch (error) {
        return thunkApi.rejectWithValue({ error, sequenceID });
    }
});

export const patchSequence = createAsyncThunk('sequences/runSequence', async ({ sequenceID, patch }, thunkApi) => {
    const projectID = selectProjectUuid(thunkApi.getState())
    try {
        const sequence = await api.sequences.patch(projectID, sequenceID, patch);
        return sequence;
    } catch (error) {
        return thunkApi.rejectWithValue({ error, sequenceID });
    }
});

export const fetchSequenceExecutions = createAsyncThunk('sequences/fetchSequenceExecutions', async ({ sequenceID, filters }, thunkApi) => {
    const projectID = selectProjectUuid(thunkApi.getState())
    try {
        const executions = await api.sequences.executions(projectID, sequenceID, filters);
        return executions;
    } catch (error) {
        return thunkApi.rejectWithValue({ error, sequenceID });
    }
});

export const killSequence = createAsyncThunk('sequences/killSequence', async (executionID, thunkApi) => {
    const projectID = selectProjectUuid(thunkApi.getState())
    try {
        const killed = await api.sequences.kill(projectID, executionID);
        return killed;
    } catch (error) {
        return thunkApi.rejectWithValue({ error, executionID });
    }
});

export const fetchSequenceTaskExecutions = createAsyncThunk('sequences/fetchSequenceTaskExecutions', async ({ sequenceExecutionID, filters }, thunkApi) => {
    const projectID = selectProjectUuid(thunkApi.getState())
    try {
        const tasks = await api.sequences.executions.tasks(projectID, sequenceExecutionID, filters);
        return { tasks, sequenceExecutionID };
    } catch (error) {
        return thunkApi.rejectWithValue({ error, sequenceExecutionID });
    }
});

export const deleteSequence = createAsyncThunk('sharedField/deleteSequence', async (sequenceID, thunkApi) => {
    const projectID = selectProjectUuid(thunkApi.getState())
    await api.sequences.delete(projectID, sequenceID);
});

const selectSelf = (state) => state.sequences;
export const selectSequences = createDraftSafeSelector(
  selectSelf, 
  (sequences) => sequences.sequences
);

export const selectAllSequences = createDraftSafeSelector(
    selectSelf, 
    (sequences) => sequences.allsequences
  );

export const selectNewSequence = createDraftSafeSelector(
    selectSelf,
    (sequences => sequences.newSequence)
);

export const selectSequenceList = createDraftSafeSelector(
    selectSelf,
    (sequences) => sequences.sequenceList,
);

export const selectSequenceExecutions = createDraftSafeSelector(
    selectSelf,
    (sequences) => sequences.sequenceExecutions,
);

export const selectSequenceTaskExecutions = createDraftSafeSelector(
    selectSelf,
    (sequences) => sequences.sequenceTaskExecutions,
);

export const selectSequenceEdit = createDraftSafeSelector(
    selectSelf,
    (sequences) => sequences.sequenceEdit,
);

const newSequenceObject = {
    uuid: '',
    alias: '', 
    enabled: false, 
    triggerType: 'cron', 
    schedule: '',
    triggerSequence: '', 
    tasks: [],
    taskGraph: {
        nodes: [],
        edges: [],
    },
};

const sequenceEditDataObject = {
    uuid: '',
    alias: '', 
    enabled: false, 
    triggerType: 'cron', 
    schedule: '',
    triggerSequence: '', 
    tasks: [],
    taskGraph: {
        nodes: [],
        edges: [],
    },
};

const taskGraphFromDAG = (tasks, dag) => {
    const graph = {
        nodes: tasks.map((task) => ({
            id: task.uuid,
            title: null,
            type: null,
            taskID: task.task_uuid,
            x: task.options?.coords ? Number(task.options.coords.x) : 0,
            y: task.options?.coords ? Number(task.options.coords.y) : 0,
        })),
        edges: [],
    };

    if (!dag) {
        for (let i = 0; i < tasks.length - 1; i++) {
            graph.edges.push({
                source: tasks[i].uuid,
                target: tasks[i+1].uuid,
                type: null,
            });
        }
    } else if (dag.links) {
        for (let { source, target } of dag.links) {
            graph.edges.push({
                source,
                target,
                type: null,
            });
        }
    }

    return graph;
};

const sequencesSlice = createSlice({
  name: 'sequences',
  initialState: {
      sequences: [], 
      sequencesLoaded: false, 
      allsequences: [],
      allsequencesLoaded: false,
      editSequenceUUID: '', 
      alias: '', 
      enabled: true, 
      triggerType: 'cron', 
      triggerSequence: '', 
      enabled: 'false', 
      sequence_tasks: [],
      sequenceList: {
        patching: false,
        running: false,
        patchError: null,
        runError: null,
        sequence: null,
      },
      newSequence: Object.assign({}, newSequenceObject),
      sequenceEdit: {
        deleting: false,
        error: null,
        loading: false,
        rawSequenceTasks: null,
        data: Object.assign({}, sequenceEditDataObject),
      },
      sequenceExecutions: {
        loading: false,
        error: null,
        data: [],
        killing: false,
      },
      // sequenceExecutionID -> {}
      sequenceTaskExecutions: {}
    },
  reducers: {
    resetSequences(state, action) {
      state.sequences = []
      state.sequencesLoaded = false;
      state.newSequence = Object.assign({}, newSequenceObject);
      state.sequenceEdit.data = Object.assign({}, sequenceEditDataObject);
    },
    updateNewSequence(state, action) {
        for (let key in action.payload) {
            state.newSequence[key] = action.payload[key];
        }
    },
    updateSequenceEdit(state, action) {
        for (let key in action.payload) {
            state.sequenceEdit.data[key] = action.payload[key];
        }
    },
    initializeEdit(state, action) {
        const sequence = state.sequences.find(seq => seq.uuid === action.payload);
        if (!sequence) return;

        state.sequenceEdit = {
            deleting: false,
            error: null,
            loading: false,
            rawSequenceTasks: sequence.sequence_tasks,
            data: {
                uuid: sequence.uuid,
                alias: sequence.alias,
                enabled: sequence.enabled,
                schedule: sequence.schedule,
                triggerType: sequence.trigger_type,
                triggerSequence: sequence.source_sequence,
                tasks: sequence.sequence_tasks.map((t) => t.task_uuid),
                taskGraph: taskGraphFromDAG(sequence.sequence_tasks, sequence.task_dag),
            }
        };
    }
  },
  extraReducers: {
    [loadSequences.fulfilled]: (state, action) => {
      state.sequences = action.payload
      state.sequencesLoaded = true;
    },
    [loadAllSequences.fulfilled]: (state, action) => {
        state.allsequences = action.payload
        state.allsequencesLoaded = true;
    },
    [loadSequence.pending]: (state) => {
        state.sequenceEdit.loading = true;
    },
    [loadSequence.fulfilled]: (state, action) => {
      // do shit here
      let result = action.payload

      let sequence_tasks = []
      result.sequence_tasks.forEach(task => {
        sequence_tasks.push(task.task_uuid)
      })

      state.sequenceEdit.loading = false;
      state.sequenceEdit.rawSequenceTasks = result.sequence_tasks;
      state.sequenceEdit.data = {
        uuid: result.uuid,
        alias: result.alias,
        enabled: result.enabled,
        schedule: result.schedule,
        triggerType: result.trigger_type,
        triggerSequence: result.source_sequence ? result.source_sequence.uuid : '',
        tasks: sequence_tasks,
        taskGraph: taskGraphFromDAG(result.sequence_tasks, result.task_dag),
      };
    },
    [loadSequence.rejected]: (state) => {
        state.sequenceEdit.loading = false;
    },
    [runSequence.pending]: (state) => {
        state.sequenceList.running = true;
        state.sequenceList.runError = null;
    },
    [runSequence.fulfilled]: (state, action) => {
        state.sequenceList.running = false;
        state.sequenceList.sequence = action.payload;
        state.sequenceList.runError = null;
    },
    [runSequence.rejected]: (state, action) => {
        state.sequenceList.running = false;
        state.sequenceList.sequence = null;
        state.sequenceList.runError = action.payload.error;
    },
    [patchSequence.pending]: (state) => {
        state.sequenceList.patching = true;
        state.sequenceList.patchError = null;
    },
    [patchSequence.fulfilled]: (state, _action) => {
        state.sequenceList.patching = false;
        // state.sequenceList.sequence = payload;
        state.sequenceList.patchError = null;
    },
    [patchSequence.rejected]: (state, action) => {
        state.sequenceList.patching = false;
        state.sequenceList.sequence = null;
        state.sequenceList.patchError = action.payload.error;
    },
    [fetchSequenceExecutions.pending]: (state) => {
        state.sequenceExecutions.loading = true;
        state.sequenceExecutions.data = [];
        state.sequenceExecutions.error = null;
    },
    [fetchSequenceExecutions.fulfilled]: (state, action) => {
        state.sequenceExecutions.loading = false;
        state.sequenceExecutions.data = action.payload;
        state.sequenceExecutions.error = null;
    },
    [fetchSequenceExecutions.rejected]: (state, action) => {
        state.sequenceExecutions.loading = false;
        state.sequenceExecutions.data = [];
        state.sequenceExecutions.error = action.payload.error;
    },
    [killSequence.pending]: (state) => {
        state.sequenceExecutions.killing = true;
    },
    [killSequence.fulfilled]: (state, _action) => {
        state.sequenceExecutions.killing = false;
    },
    [killSequence.rejected]: (state, _action) => {
        state.sequenceExecutions.killing = false;
    },
    [fetchSequenceTaskExecutions.pending]: (state, action) => {
        const { sequenceExecutionID } = action.meta.arg;
        state.sequenceTaskExecutions[sequenceExecutionID] = {
            loading: true,
            data: null,
            error: null,
        };
    },
    [fetchSequenceTaskExecutions.fulfilled]: (state, action) => {
        const { sequenceExecutionID, tasks } = action.payload;
        console.log(action.payload.tasks)
        state.sequenceTaskExecutions[sequenceExecutionID] = {
            loading: false,
            data: tasks,
            error: null,
        };
    },
    [fetchSequenceTaskExecutions.rejected]: (state, action) => {
        const { sequenceExecutionID, error } = action.payload;
        state.sequenceTaskExecutions[sequenceExecutionID] = {
            loading: false,
            data: null,
            error,
        };
    },
    [deleteSequence.pending]: (state) => {
        state.sequenceEdit.deleting = true;
        state.sequenceEdit.error = null;
    },
    [deleteSequence.fulfilled]: (state) => {
        state.sequenceEdit.deleting = false;
        state.sequenceEdit.error = null;
    },
    [deleteSequence.rejected]: (state, action) => {
        state.sequenceEdit.deleting = false;
        state.sequenceEdit.error = action.payload;
    },
  },
})
  
export const { resetSequences, initializeEdit, updateNewSequence, updateSequenceEdit } = sequencesSlice.actions
  
export default sequencesSlice.reducer
  