import { grey } from '@ant-design/colors';

export default function graphStyles(theme) {
  return {
    contentCard: {
      background: '#eee',
    },

    content: {
      width: '100%',
    },

    newTaskButton: {
      width: '100%',
    },

    listItem: {
      cursor: 'grab',
      userSelect: 'none',
      backgroundColor: '#fff',
      marginBottom: 10,
    },

    formItem: {
      marginBottom: 0,
      width: '70%',
    },

    smallText: {
      color: grey.primary,
    },

    editor: {
      display: 'flex',
      gap: '1rem',
      flexDirection: 'row',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },

    graphContainer: {
      height: 450,
      flex: 1,
      position: 'relative',
    },

    graphHelp: {
      marginTop: '1rem',
    },

    graphInfoButton: {
      position: 'absolute',
      bottom: '1rem',
      right: '1rem',
      width: 40,
      height: 40,
    },

    sidebarContainer: { width: 300, background: '#fff' },

    sidebarInputLabel: {
      fontSize: '0.9rem',
    },
  };
}
