import React from 'react';
import loadable from '@loadable/component';
import '@loadable/babel-plugin';
import { Typography } from 'antd';

export const PlotlyPlot = loadable(() =>
  import(/* webpackChunkName: "PlotlyPlot" */ './PlotlyPlot'),
);

const { Text } = Typography;

const Plot = React.forwardRef((props, ref) => {
  const { plotType, plotData } = props;
  try {
    if (plotType === 'plotlyJSON') {
      return <PlotlyPlot ref={ref} plotData={plotData} />;
    }
    if (plotType === 'bokehJSON') {
      const refs = plotData.doc.roots.references
        .filter((d) => d.attributes.selected?.type === 'Selection')
        .sort(
          (a, b) =>
            Number(a.attributes.selected.id) - Number(b.attributes.selected.id),
        );
  
      if (refs.length === 2) {
        const [memory, cpu] = refs;
  
        const memoryY = memory.attributes.data.y.map((n) =>
          (n / 1024 / 1024).toFixed(2),
        );
        const memoryGraph = {
          x: memory.attributes.data.x,
          y: memoryY,
          type: 'scatter',
          mode: 'lines+markers',
          line: {
            color: 'blue',
          },
          marker: {
            color: 'blue',
          },
          text: memoryY.map((n) => `Memory: ${n} MiB`),
          name: 'Memory',
          hoverinfo: 'text'
        };
  
        const cpuY = cpu.attributes.data.y.map((n) => (n * 100).toFixed(0));
        const cpuGraph = {
          x: cpu.attributes.data.x,
          y: cpuY,
          type: 'scatter',
          mode: 'lines+markers',
          line: {
            color: 'red',
          },
          marker: {
            color: 'red',
          },
          text: cpuY.map((n) => `CPU: ${n}%`),
          name: 'CPU',
          hoverinfo: 'text'
        };
  
        const data = [memoryGraph, cpuGraph];
  
        const layout = {
          showlegend: false,
          xaxis: {
            zeroline: true,
            title: 'Execution seconds',
          },
          yaxis: { zeroline: true, title: 'Memory usage' },
        };
  
        return <PlotlyPlot ref={ref} plotData={{ data, layout }} />;
      }
    }
    return <Text type="secondary">Unsupported Plot Type.</Text>;
  } catch (error) {
    console.log(error)
    return <Text type="secondary">Data is still being generated, please come back later.</Text>;
  }
});

export default Plot;
