import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { A, navigate } from 'hookrouter';

// @material-ui/core
import { makeStyles } from '@material-ui/core/styles';

import {
  DatePicker,
  Form,
  Table,
  Input,
  Button,
  Row,
  Col,
  Card,
  Space,
  Progress,
  Tag,
  Dropdown,
  Menu,
  Tooltip,
} from 'antd';
import { FilterOutlined, StopOutlined } from '@ant-design/icons';

import styles from '../../assets/jss/material-dashboard-react/views/dashboardStyle.js';

import ProjectItem from '../Project/ProjectItem';
import DescriptionItem from '../Description/DescriptionItem';

import {
  loadExecutions,
  deleteExecutions,
  selectExecutionsLoading,
  killExecution,
} from './executionSlice';
import { selectProjectUuid } from '../Project/projectSlice';

import {
  selectTasks,
} from '../Tasks/shared/tasksSlice';

import store from '../../../store.js';

const { RangePicker } = DatePicker;

const useStyles = makeStyles(styles);

const preconfiguredDateRanges = {
  'Last Hour': [moment().subtract(1, 'h'), moment()],
  Today: [moment().startOf('day'), moment().endOf('day')],
  'Last 48 Hours': [moment().subtract(48, 'h'), moment().endOf('day')],
  'Last 7 Days': [moment().subtract(7, 'd'), moment().endOf('day')],
  'This Month': [moment().startOf('month'), moment().endOf('month')],
};

const defaultRange = preconfiguredDateRanges['Last 7 Days'];

// Associates the different filter status to codes
const ExecutionState = {
  Failure: { code: '00', status: 'Failed', state: 'Failure' },
  Initializing: { code: '01', status: 'Running', state: 'Initializing' },
  Validating: { code: '02', status: 'Running', state: 'Validating' },
  Routing: { code: '03', status: 'Running', state: 'Routing' },
  Queueing: { code: '04', status: 'Running', state: 'Queueing' },
  Executing: { code: '05', status: 'Running', state: 'Executing' },
  Killed: { code: '10', status: 'Failed', state: 'Killed' },
  Skipped: { code: '98', status: 'Skipped', state: 'Skipped' },
  Completed: { code: '99', status: 'Completed', state: 'Completed' },
};

const ExecutionCodes = Object.keys(ExecutionState)
  .reduce((codes, state) => {
    let { status, code } = ExecutionState[state];
    status = status.toLowerCase();
    if (!codes[status]) codes[status] = [];
    codes[status].push(code);
    return codes;
  }, {});

export default function ExecutionsPage(props) {
  const classes = useStyles();

  const projectUuid = useSelector(selectProjectUuid);
  const loading = useSelector((state) => state.executions.loading);
  const moreAvailable = useSelector((state) => state.executions.moreAvailable);
  
  const tasks = useSelector(selectTasks);
  let taskUUID = props.props.taskID
  let taskName = ''

  try {
    const tasknameArray = taskUUID.split('%20');
    taskUUID = tasknameArray.join(' ');
    tasks.forEach((task) => {
      if (taskUUID == task.uuid) {
        taskName = task.alias
      }
    })
  }
  catch (e) {}

  console.log(taskName)

  const [filters, setFilters] = useState({
    name: taskName,
    status: {
      running: false,
      failed: false,
      skipped: false,
      completed: false,
    },
    range: {
      from: defaultRange[0],
      to: defaultRange[1],
    },
  });

  const updateNameFilter = (event) => {
    const { value: name } = event.target;
    setFilters({ ...filters, name });
  };

  const toggleStatusFilter = (event) => {
    const { name } = event.currentTarget.dataset;
    setFilters({
      ...filters,
      status: {
        ...filters.status,
        [name]: !filters.status[name],
      }
    });
  };

  const buttonType = (applied) => {
    if (applied) return 'primary';
    return 'default';
  };

  const updateRangeFilter = (dates) => {
    if (dates) {
      const [from, to] = dates;
      setFilters({ ...filters, range: { from, to } });
    } else {
      setFilters({ ...filters, range: { from: null, to: null } });
    }
  };

  const applyFilters = async () => {
    // reset store
    await store.dispatch(deleteExecutions(projectUuid));
    loadMore();
  };

  const loadMore = () => {
    const { name, status, range } = filters;
    const status_list = Object.entries(status)
      .filter(([_, v]) => v)
      .map(([k]) => ExecutionCodes[k])
      .flat(1)
      .join(',');
    const payload = {
      alias: name,
      status_list,
      created_start: range.from.valueOf(),
      created_end: range.to.valueOf(),
    };
    store.dispatch(
      loadExecutions(payload),
    );
  };

  // deletes slice State on component unmount
  useEffect(() => {
    return () => {
      store.dispatch(deleteExecutions());
    }
  }, []);

  useEffect(() => {
    store.dispatch(deleteExecutions(projectUuid));
    loadMore();
  }, [projectUuid]);

  return (
    <Space direction="vertical" style={{ width: '100%', fontWeight: 'normal' }}>
      <Card>
        <Row justify="space-between" align="middle">
          <Col>
            <DescriptionItem
              title="Executions"
              description="Search and view status of executions."
            />
          </Col>
          <Col>
          </Col>
        </Row>
        <ProjectItem />
      </Card>

      <Card size="small">
        <Form layout="horizontal">
          <Row gutter={[20, 0]}>
            <Col xs={24} lg={10}>
              <Form.Item label={<strong>Name</strong>}>
                <Input type="text" onChange={updateNameFilter} />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item label={<strong>Status</strong>}>
                <Button.Group>
                  <Button
                    data-name="running"
                    type={buttonType(filters.status.running)}
                    onClick={toggleStatusFilter}
                  >
                    Running
                  </Button>
                  <Button
                    data-name="failed"
                    type={buttonType(filters.status.failed)}
                    onClick={toggleStatusFilter}
                  >
                    Failed
                  </Button>
                  <Button
                    data-name="skipped"
                    type={buttonType(filters.status.skipped)}
                    onClick={toggleStatusFilter}
                  >
                    Skipped
                  </Button>
                  <Button
                    data-name="completed"
                    type={buttonType(filters.status.completed)}
                    onClick={toggleStatusFilter}
                  >
                    Completed
                  </Button>
                </Button.Group>
              </Form.Item>
            </Col>
            <Col xs={24} lg={11}>
              <Form.Item label={<strong>Range</strong>}>
                <RangePicker
                  ranges={preconfiguredDateRanges}
                  defaultValue={defaultRange}
                  showTime
                  allowClear={false}
                  style={{ width: '100%' }}
                  format="YYYY/MM/DD HH:mm:ss"
                  onChange={updateRangeFilter}
                />
              </Form.Item>
            </Col>
            <Col>
              <Button
                type="primary"
                icon={<FilterOutlined />}
                onClick={applyFilters}
              >
                Apply Filters
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
      <Card size="small">
        <ExecutionsTable loadMore={loadMore} />
        <Button
          hidden={loading || !moreAvailable}
          type="primary"
          block
          style={{ marginTop: 12, maxWidth: 200 }}
          onClick={loadMore}
        >
          Load More
        </Button>
      </Card>
    </Space>
  );
}

function ExecutionsTable(props) {
  const executions = useSelector((state) => state.executions.executions);
  const loading = useSelector(selectExecutionsLoading);
  const projectUuid = useSelector(selectProjectUuid);

  const killtask = async (uuid) => {
    console.log('killing execution')
    await store.dispatch(killExecution(uuid))
    await store.dispatch(deleteExecutions(projectUuid));
    props.loadMore();
    console.log('kill done')
  }

  const columns = [
    {
      title: 'Execution',
      dataIndex: ['task', 'alias'],
      render: (alias, { uuid }) => <A href={'/' + projectUuid + '/executions/' + uuid}>{alias}</A>,
    },
    {
      title: 'Progress',
      dataIndex: 'progress',
      render: (progress) => (
        <Tooltip title={progress + '%'}>
          <Progress
            type="circle"
            percent={progress}
            showInfo={false}
            width={30}
            strokeWidth={30}
          />
        </Tooltip>
      ),
    },
    {
      title: 'Status',
      dataIndex: ['status', 'execution_state'],
      render: (status) => {
        let color;
        if (status === 'Completed') color = 'cyan';
        else if (status === 'Failure' || status === 'Killed') color = 'red';
        else if (status === 'Executing') color = 'yellow';
        return <Tag color={color}>{status}</Tag>;
      },
    },
    {
      title: 'Started By',
      dataIndex: 'sequence_alias',
      render: (alias, { user, sequence_uuid }) => (
        sequence_uuid ? <A href={'/' + projectUuid + '/sequences/edit/' + sequence_uuid}>{alias}</A> : <div>{user.user_name}</div>
      ),
    },
    {
      title: 'Started',
      dataIndex: 'created',
      render: (date) =>
        `${new Date(date).toUTCString()} (${moment(date).fromNow()})`,
    },
    {
      title: 'Last Update',
      dataIndex: 'updated',
      render: (date) => moment(date).fromNow(),
    },
    {
      title: 'Kill Task',
      dataIndex: 'kill_task',
      render: (_, execution) => {
        const status = execution.status.execution_state;
        if (status === 'Executing') {
          return (
            <Tooltip title="Click to kill the execution">
              <Button shape="circle" type="primary" danger onClick={() => {killtask(execution.uuid)}}>
                <StopOutlined />
              </Button>
            </Tooltip>
          );
        }
        return null;
      },
    },
    
  ];
  return (
    <Table
      expandable
      rowKey="uuid"
      dataSource={executions}
      columns={columns}
      pagination={false}
      loading={loading}
    />
  );
}
