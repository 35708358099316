
// core components
import { useSelector } from 'react-redux'

import DescriptionItem from '../Description/DescriptionItem'

import { Row, Col, Space } from 'antd'

import { loadProjects } from './permissionSlice.js'
import store from '../../../store.js'

import { Tabs } from 'antd';

const { TabPane } = Tabs;

import Projects from './Projects'
import Users from './Users'
import Roles from './Roles'
import Groups from './Groups'

function callback(key) {
}

export default function PermissionPage() {
    let projectsloaded = useSelector((state) => state.permissions.projectsloaded)
    let projects = useSelector((state) => state.permissions.projects)
    if(!projectsloaded) {
        store.dispatch(loadProjects())
    }

    return (
        <div>
        <Row align='middle'>
            <Col>
            <DescriptionItem title={'Permissions'} description='' />
            </Col>
        </Row>
        {Menu}
        </div>
  );
}

const Menu = (
    <Tabs defaultActiveKey="1" onChange={callback}>
      <TabPane tab="Users" key="Users">
        <Users />
      </TabPane>
      <TabPane tab="Groups" key="Groups">
        <Groups />
      </TabPane>
      <TabPane tab="Roles" key="Roles">
        <Roles />
      </TabPane>
      <TabPane tab="Projects" key="Projects">
        <Projects />
      </TabPane>
    </Tabs>
);