import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { navigate } from 'hookrouter';

import {
  Card,
  Row,
  Col,
  Input,
  Form,
  Select,
  Space,
  Button,
  Divider,
} from 'antd';
import { green } from '@ant-design/colors';
import { LockTwoTone, SaveOutlined, UnlockTwoTone } from '@ant-design/icons';

import { api } from '../../api/api.js';
import store from '../../../store.js';

import {
  setNewConnectionDetail,
  changeNewConnectionDetail,
  loadConnectionTypes,
  resetConnectionDetail,

  // selectors
  selectConnectionTypes,
  selectNewConnectionParameters,
  selectNewConnectionAlias,
} from './connectionsSlice';
import {
  loadSharedConfigs,
  selectSharedConfigs,
} from '../SharedConfig/sharedConfigSlice.js';

import Loading from '../../components/Loading.js';
import FieldFactory from '../SharedField/FieldFactory.js';
import { generateFieldRules } from '../../util/forms.js';

import { selectProjectUuid } from '../Project/projectSlice';
import toast from '../../util/toasts.js';

const { Group: BtnGroup } = Button;

export default function CreateConnection(props) {
  const sharedConfigs = useSelector(selectSharedConfigs);
  const connectionTypes = useSelector(selectConnectionTypes);
  const newParameters = useSelector(selectNewConnectionParameters);

  const newAlias = useSelector(selectNewConnectionAlias);

  const projectUuid = useSelector(selectProjectUuid);

  // controls the locked state of the form
  const [locked, setLocked] = useState(false);

  const [processing, setProcessing] = useState(false);

  const connType = connectionTypes.find((connectionType) => {
    return connectionType.uuid == props.connectionTypeID;
  });

  const toggleConnectionFieldsLock = () => {
    setLocked(!locked);
  };

  // updates the `alias` value of the connection
  const onChangeAlias = (e) => {
    const value = e.target.value;
    store.dispatch(
      changeNewConnectionDetail({
        newConnection: true,
        label: 'alias',
        value,
      }),
    );
  };

  useEffect(() => {
    // setup the state for a new connection
    if (connType) {
      store.dispatch(setNewConnectionDetail(connType));
    }
  }, [connType]);

  useEffect(() => {
    store.dispatch(loadSharedConfigs());
    store.dispatch(loadConnectionTypes());
  }, []);

  useEffect(() => {
    return function cleanup() {
      store.dispatch(resetConnectionDetail());
    };
  }, []);

  const save = () => {
    setProcessing(true);
    api.connectionDetail
      .post(newAlias, props.connectionTypeID, newParameters, projectUuid)
      .then((res) => {
        navigate('/' + projectUuid + '/connections');
      })
      .catch(() => {
        toast.error('Failed to create new connection.');
      })
      .finally(() => setProcessing(false));
  };

  if (!connType) return <Loading />;

  const parameters = connType.parameters;
  const parameterFields = [];

  parameters.forEach((parameter) => {
    if (parameter.type === 'group')
      parameterFields.push(
        <ParameterSection
          key={parameter.name}
          section={parameter}
          state={newParameters}
          sharedConfigs={sharedConfigs}
          locked={locked}
        />,
      );
    else
      parameterFields.push(
        <SingleParameterSection
          key={parameter.name}
          parameter={parameter}
          state={newParameters}
          sharedConfigs={sharedConfigs}
          locked={locked}
        />,
      );
  });

  const validationMessages = {
    required: '${label} is a required field',
  };

  return (
    <div>
      <Form
        layout="vertical"
        onFinish={save}
        validateMessages={validationMessages}
      >
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          <Card
            title={connType.alias}
            extra={
              <BtnGroup>
                <Button
                  icon={
                    locked ? (
                      <UnlockTwoTone twoToneColor={green.primary} />
                    ) : (
                      <LockTwoTone twoToneColor={green.primary} />
                    )
                  }
                  onClick={toggleConnectionFieldsLock}
                >
                  {locked ? 'Unlock' : 'Lock'}
                </Button>
                <Button
                  htmlType="submit"
                  style={{
                    backgroundColor: locked ? undefined : green[6],
                    color: locked ? undefined : '#fff',
                  }}
                  disabled={locked}
                  icon={<SaveOutlined />}
                  loading={processing}
                >
                  Create
                </Button>
              </BtnGroup>
            }
          >
            <Card.Meta description={connType.description} />

            <Divider />

            <Row gutter={20}>
              <Col xs={24} lg={12}>
                <Form.Item
                  name="alias"
                  label={<strong>Alias</strong>}
                  rules={[
                    { required: true, message: 'Alias is a required field' },
                  ]}
                  initialValue={newAlias}
                  required
                >
                  <Input
                    size="large"
                    value={newAlias}
                    onChange={onChangeAlias}
                    disabled={locked}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Card>

          <Card title="Parameters">
            <Space direction="vertical" size="large" style={{ width: '100%' }}>
              {parameterFields}
            </Space>
          </Card>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              icon={<SaveOutlined />}
              disabled={locked}
              loading={processing}
            >
              Save
            </Button>
          </Form.Item>
        </Space>
      </Form>
    </div>
  );
}

function ParameterSection(props) {
  const { section, state: stateParameters, sharedConfigs, locked } = props;
  const { parameters } = section;

  const parameterFields = [];
  parameters.forEach((parameter) => {
    const { name, type, display_name, description, required } = parameter;
    let value = stateParameters[name];

    // event handlers
    const onChangeParameter = (value) => {
      store.dispatch(
        changeNewConnectionDetail({
          newConnection: true,
          label: name,
          value,
        }),
      );
    };

    // THIS INITIALIZES DEFAULT VALUES
    if ('default' in parameter && !(parameter.name in stateParameters)) {
      onChangeParameter(parameter.default)
    }
    console.log(stateParameters)

    const onChangeOption = () => {};

    const rules = generateFieldRules(parameter);

    const initialValue =
      value !== undefined ? value : parameter.default || undefined;
    if (value === undefined) value = initialValue;

    const wrapElement = (element) => (
      <Form.Item
        label={<strong>{display_name}</strong>}
        name={name}
        rules={rules}
        initialValue={initialValue}
        required={required}
        {...(description && { help: description })}
      >
        {element}
      </Form.Item>
    );

    if (type == 'enum' || name == 'load_pattern') {
      const options = parameter.choices.map((choice) => (
        <Select.Option key={choice} value={choice}>
          {choice}
        </Select.Option>
      ));
      parameterFields.push(
        wrapElement(
          <Select
            allowClear
            value={value}
            disabled={locked}
            onChange={onChangeParameter}
          >
            {options}
          </Select>,
        ),
      );
    } else if (type === 'shared-config') {
      const options = sharedConfigs.map((d) => (
        <Select.Option key={d.uuid} value={d.uuid}>
          {d.alias}
        </Select.Option>
      ));

      parameterFields.push(
        wrapElement(
          <Select
            allowClear
            value={value}
            disabled={locked}
            onChange={onChangeParameter}
          >
            {options}
          </Select>,
        ),
      );
    } else {
      parameterFields.push(
        wrapElement(
          FieldFactory.create(type, {
            value,
            options: {},
            onChange: onChangeParameter,
            onOptionsChange: onChangeOption,
            disabled: locked,
          }),
        ),
      );
    }
  });

  return (
    <Card title={section.display_name} type="inner" style={{ width: '100%' }}>
      <Row gutter={[30, 20]}>
        {parameterFields.map((field, idx) => {
          const { type } = parameters ? parameters[idx] : section;
          const md = FieldFactory.hasMetaProperty(type, 'codeElement')
            ? 24
            : 12;
          return (
            <Col key={idx} xs={24} md={md}>
              {field}
            </Col>
          );
        })}
      </Row>
    </Card>
  );
}

function SingleParameterSection(props) {
  const { parameter, state: stateParameters, sharedConfigs, locked } = props;

  const { name, type, display_name, description, required } = parameter;
  const value = stateParameters[name];

  let parameterField;

  // event handlers
  const onChangeParameter = (value) => {
    store.dispatch(
      changeNewConnectionDetail({
        newConnection: true,
        label: name,
        value,
      }),
    );
  };

  // THIS INITIALIZES DEFAULT VALUES
  if ('default' in parameter && !(parameter.name in stateParameters)) {
    onChangeParameter(parameter.default)
  }
  console.log(stateParameters)

  const onChangeOption = () => {};

  const rules = generateFieldRules(parameter);

  const wrapElement = (element) => (
    <Form.Item
      label={<strong>{display_name}</strong>}
      name={name}
      rules={rules}
      initialValue={value || parameter.default || undefined}
      required={required}
      {...(description && { help: description })}
    >
      {element}
    </Form.Item>
  );

  if (type == 'enum' || name == 'load_pattern') {
    const options = parameter.choices.map((choice) => (
      <Select.Option key={choice} value={choice}>
        {choice}
      </Select.Option>
    ));
    parameterField = (
      <Select
        allowClear
        value={value}
        disabled={locked}
        onChange={onChangeParameter}
      >
        {options}
      </Select>
    );
  } else if (type === 'shared-config') {
    const options = sharedConfigs.map((d) => (
      <Select.Option key={d.uuid} value={d.uuid}>
        {d.alias}
      </Select.Option>
    ));

    parameterField = (
      <Select
        allowClear
        value={value}
        disabled={locked}
        onChange={onChangeParameter}
      >
        {options}
      </Select>
    );
  } else {
    parameterField = FieldFactory.create(type, {
      value,
      options: {},
      onChange: onChangeParameter,
      onOptionsChange: onChangeOption,
      disabled: locked,
    });
  }

  const md = FieldFactory.hasMetaProperty(type, 'codeElement') ? 24 : 12;

  return (
    <Row gutter={[30, 20]}>
      <Col xs={24} md={md}>
        {wrapElement(parameterField)}
      </Col>
    </Row>
  );
}
