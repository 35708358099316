import { QuestionOutlined } from '@ant-design/icons';
import { blue } from '@ant-design/colors';

import store from '../../../../../store';
import { setTaskHelpParameterHeaderId } from '../../shared/tasksSlice';

export default function InputLabel({ label, help }) {
  return (
    <div>
      <strong>{label}</strong>
      <QuestionOutlined
        style={{ margin: '0 10px', color: blue.primary }}
        onClick={(evt) => {
          evt.preventDefault();
          store.dispatch(setTaskHelpParameterHeaderId(help));
        }}
      />
    </div>
  );
}
