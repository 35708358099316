import React from 'react';
import { useSelector } from 'react-redux';
import { Space, Card, Row, Col } from 'antd';
// @material-ui/core
import { makeStyles } from '@material-ui/core/styles';

import styles from '../../assets/jss/material-dashboard-react/views/dashboardStyle.js';

import ViewAction from '../ViewAction/ViewAction';

import SharedFieldList from './SharedFieldList';

import ProjectItem from '../Project/ProjectItem';
import DescriptionItem from '../Description/DescriptionItem';

import { loadSharedFields } from './sharedFieldSlice';
import store from '../../../store.js';

import CreateSharedField from './CreateSharedField';
import AddSharedField from './AddSharedField';
import SharedFieldEdit from './SharedFieldEdit';
import { selectProjectUuid } from '../Project/projectSlice';

export const types = [
  'Code',
  'Date',
  'Datetime',
  'Int',
  'Json',
  'Sql',
  'String',
  'Text',
  'Time',
];

const useStyles = makeStyles(styles);

export default function SharedFieldPage(props) {
  const projectUuid = useSelector(selectProjectUuid);

  const sharedFieldsLoaded = useSelector((state) => state.sharedField.loaded);
  if (!sharedFieldsLoaded) {
    store.dispatch(loadSharedFields());
  }

  let Target = <SharedFieldList classes={classes} />;
  if (props.props.create) {
    if (props.props.type) {
      Target = <CreateSharedField type={props.props.type} />;
    } else {
      Target = <AddSharedField />;
    }
  }
  if (props.props.edit) {
    Target = <SharedFieldEdit sharedFieldID={props.props.sharedFieldID} />;
  }

  const classes = useStyles();
  return (
    <Space direction="vertical" style={{ fontWeight: 'normal', width: '100%' }}>
      <Card>
        <Row justify="space-between">
          <Col>
            <DescriptionItem
              classes={classes}
              description="View and manage shared fields"
              title="Shared Fields"
            />
          </Col>
          <Col>
            {props.props.create || props.props.type || props.props.edit ? (
              <div />
            ) : (
              <ViewAction
                classes={classes}
                target={'/' + projectUuid + '/sharedfield/create'}
                description="Create Shared Field"
              />
            )}
          </Col>
        </Row>
      </Card>
      <div>{Target}</div>
    </Space>
  );
}
